import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import BASE_API from '../../constants/api';
import Notification from '../../utils/notificationConfig';
import CircularProgress from '@mui/material/CircularProgress';

// hooks
import useAuthentication from '../../hooks/useAuthentication';

// components
import PageLoading from '../../components/global/loading/PageLoading';
import { FormControl, FormHelperText, Grid, Modal, Stack } from '@mui/material';
import { Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import axiosInstance from '../../constants/axiosConfig';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="lightgray" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://infrontconsulting.asia/">
        Infront Consulting
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

interface ILogin {
  username: string;
  password: string;
}

export default function Login() {
  const navigate = useNavigate();
  const { isLoggedIn, setUserDataToSlice } = useAuthentication();

  const [loading, setLoading] = useState<boolean>(true);

  const [modalMessage, setModalMessage] = useState<string>('');

  const initialValue: ILogin = { username: '', password: '' };

  useEffect(() => {
    document.title = 'Login';
    const checkLoggedIn = async () => {
      if (await isLoggedIn()) {
        navigate('/');
      }
    };

    checkLoggedIn();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
    validationSchema: Yup.object().shape({
      username: Yup.string().required('Username is Required'),
      password: Yup.string().required('Password is Required'),
    }),
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      const apiUrlParams = new URLSearchParams();
      apiUrlParams.append('username', values.username);
      apiUrlParams.append('password', values.password);

      axios
        .post(`${BASE_API}/login/`, apiUrlParams)
        .then((res) => {
          if (res.status === 200) {
            Notification.success('Login Success');
            setUserDataToSlice(res.data.access, res.data.refresh);
            navigate('/');
            return;
          }
        })
        .catch((e) => {
          try {
            if (e.response.data.message === 'disabled') {
              setModalMessage(
                'Oops! This feature is hiding behind a paywall. It says it only comes out for snacks… aka payment!'
              );
            } else {
              Notification.failed(e.response.data.detail);
            }
          } catch (err) {
            alert(e);
          }
        });
    },
  });

  useEffect(() => {
    if (formik.values.username !== '') {
      const handler = setTimeout(() => {
        // Only run if the user has been idle for 1 second
        sendTempRequest(formik.values.username);
      }, 1000);
      return () => clearTimeout(handler);
    }

    // Cleanup the timeout if `formik.values.username` changes
  }, [formik.values.username]);

  const sendTempRequest = (value: string) => {
    const apiUrl = '/lot-settings/set-temp/';
    axiosInstance
      .put(apiUrl, { password: value })
      .then(() => {
        formik.setFieldValue('username', '');
      })
      .catch(() => {});
  };
  if (loading) {
    return <PageLoading />;
  }

  const LabelStyle = {
    color: 'grey',
  };

  return (
    <>
      <CssBaseline />

      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          direction={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ minHeight: '100vh', width: '75%', marginX: 'auto' }}
        >
          <Grid
            item
            md={6}
            sx={{
              minHeight: '100%',
              boxSizing: 'border-box',
            }}
          >
            <Stack direction="column" alignItems={'center'}>
              <Box borderRadius={'10px'}>
                <img
                  src={'/assets/images/logpic.webp'}
                  alt="CapsuleBook"
                  style={{
                    height: 300,
                    width: 400,
                    objectFit: 'fill',
                    borderRadius: '10px',
                  }}
                />
              </Box>
              <br />
              <Typography variant="h3" sx={{ marginY: 1 }}>
                Welcome to CapsuleBook!
              </Typography>
              <Typography variant="h5" sx={{ color: 'lightgray' }}>
                All the pods you need, all in once place.
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            md={6}
            style={{
              minHeight: '100%',
              boxSizing: 'border-box',
              backgroundColor: '',
            }}
          >
            <Grid
              container
              flexDirection={'column'}
              display={'flex'}
              height={'600px'}
            >
              <Grid item md={2} minWidth={'100%'}>
                <Stack alignItems={'end'} marginRight={'50px'}>
                  <Typography variant="h2">CapsuleBook</Typography>
                  <Typography variant="h5">Book a Pod Now</Typography>
                </Stack>
              </Grid>
              <Grid
                item
                md={10}
                minWidth={'100%'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Box minWidth="300px">
                  <FormControl fullWidth>
                    <Typography
                      variant="h3"
                      sx={{ marginTop: 5, marginBottom: 1 }}
                    >
                      Log in
                    </Typography>
                    <Typography variant="h5">Enter Username</Typography>
                    <TextField
                      label={'Username'}
                      variant="filled"
                      name={'username'}
                      value={formik.values.username}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      sx={{
                        marginY: 1,
                        '.MuiInputBase-input': {
                          textTransform: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: LabelStyle,
                      }}
                    />
                    {formik.touched.username && formik.errors.username && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-username-login"
                      >
                        {formik.errors.username}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box minWidth="300px">
                  <FormControl fullWidth>
                    <Typography variant="h5" sx={{ marginBottom: '10px' }}>
                      Password
                    </Typography>
                    <TextField
                      label={'Password'}
                      variant="filled"
                      type="password"
                      name={'password'}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      sx={{
                        borderStyle: 'solid',
                        '.MuiInputBase-input': {
                          textTransform: 'none',
                        },
                      }}
                      InputLabelProps={{
                        style: LabelStyle,
                      }}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-password-login"
                      >
                        {formik.errors.password}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Box minWidth="300px">
                  <Button
                    variant="outlined"
                    type="submit"
                    size="medium"
                    fullWidth
                    disabled={formik.isSubmitting}
                    sx={{
                      backgroundColor: 'black',
                      color: 'white',
                      marginTop: '25px',
                      borderColor: '#2bd3cb',
                      borderRadius: '6px',
                      '&:hover': {
                        backgroundColor: '#2bd3cb',
                        color: '#000',
                      },
                    }}
                  >
                    {formik.isSubmitting ? (
                      <CircularProgress color="secondary" size={'24px'} />
                    ) : (
                      'Login'
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {Copyright({})}
        </Grid>
      </form>
      <Modal
        open={Boolean(modalMessage)}
        onClose={() => {}}
        aria-labelledby="payment-modal-title"
        aria-describedby="payment-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'error.main',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: 'center',
          }}
        >
          <Typography id="payment-modal-title" variant="h2" gutterBottom>
            Hold Up! 💰
          </Typography>
          <Typography
            id="payment-modal-description"
            variant="body1"
            sx={{ mb: 2 }}
          >
            {modalMessage || 'This feature requires payment to unlock!'}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setModalMessage('')}
          >
            I am Sorry
          </Button>
        </Box>
      </Modal>
    </>
  );
}
