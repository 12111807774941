import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

import useDevTools from "../../hooks/useDevTools";

// components
import PageLoading from "./loading/PageLoading";

// Redux
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

// Utils
import Notification from "../../utils/notificationConfig";
import axiosInstance from "../../constants/axiosConfig";
import BASE_API from "../../constants/api";

// Types and interfaces
interface PermissionProtectedProps {
  authorizedPermissions: string[];
}

export default function PermissionPortectedRoutes(
  props: PermissionProtectedProps
) {
  const navigate = useNavigate();

  const { disabledPermissions } = useDevTools();

  const permissions = useSelector((state: RootState) => state.user.permissions);
  const accountId = useSelector((state: RootState) => state.user.accountId);
  const token = useSelector((state: RootState) => state.user.accessToken);

  const [loading, setLoading] = useState<boolean>(true);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [isCheckIn, setIsCheckIn] = useState<boolean>(false);

  useEffect(() => {
    const authPermission = props.authorizedPermissions;
    let authorized = false;

    for (let item of authPermission) {
      if (permissions.includes(item) && !disabledPermissions.includes(item)) {
        authorized = true;
        break;
      }
    }

    if (
      authorized &&
      !props.authorizedPermissions.some((item) => item.includes("DASHBOARD"))
    ) {
      setIsAuthorized(true);
      const apiUrl = `${BASE_API}/accounts/shift/${accountId}/current-shift/`;
      axiosInstance
        .get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsCheckIn(true);
        })
        .catch((res) => {
          Notification.warning("Your shift has ended, please check in again");
          navigate("/");
          setIsCheckIn(false);
        });
    } else if (props.authorizedPermissions.includes("DASHBOARD")) {
      setIsAuthorized(true);
    } else {
      Notification.warning(
        "You dont have permission to the page you trying to access"
      );
      navigate("/");
      setIsAuthorized(false);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.authorizedPermissions]);

  if (loading) {
    return <PageLoading />;
  }

  if (props.authorizedPermissions.includes("DASHBOARD")) {
    return isAuthorized && !loading ? <Outlet /> : null;
  }

  return isAuthorized && isCheckIn && !loading ? <Outlet /> : null;
}
