import { PayloadAction, createSlice } from '@reduxjs/toolkit'


export interface TempSlice {
    tempTwo : string[]
}

const initialState : TempSlice = {
    tempTwo: []
}

export const tempSlice = createSlice({
    name: 'temp',
    initialState,
    reducers: {
        setTempTwo(state, action : PayloadAction<string[]>) {
            state.tempTwo = action.payload;
        }
    }
});


export const { setTempTwo } = tempSlice.actions;
export default tempSlice.reducer;