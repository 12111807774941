import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {
  Box,
  Button,
  ButtonBase,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { MRT_ColumnDef, MRT_PaginationState } from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from '../../assets/theme/theme';
import CustomTable from '../../components/global/table/CustomTable';
import axiosInstance from '../../constants/axiosConfig';
import { bookingStatusEnum } from '../../constants/enums';
import useUpdate from '../../hooks/useUpdate';
import { store } from '../../redux/store';
import { convertToEpoch, getTimestamp } from '../../utils/functions';
import Notification from '../../utils/notificationConfig';
import { NewAPIBookingResult } from './interface';
import { ILot } from '../../models/payment/PaymentInterface';
import useAuthentication from '../../hooks/useAuthentication';

type Props = {
  tableStatus: string;
};

type CheckinDataItem = {
  id: number;
  bookingId: string;
  bookingNumber: string;
  customer: string;
  platform: string;
  status: string;
  checkInTime: string;
  checkOutTime: string;
  actualCheckinDatetime: string | null;
  actualCheckoutDatetime: string | null;
  derivedCheckInDatetime: string | null;
  derivedCheckOutDatetime: string | null;
  roomCode: string[];
  roomTypes: string[];
  bookingMadeDatetime: string | null | undefined;
  overstayDuration: string;
  isOverstay: boolean;
  otaCode: string;

  // count: number;
  // next: string | null;
  // previous: string | null;
};

function NewCheckInList() {
  const token = store.getState().user.accessToken;
  const lot = store.getState().user.lotId;
  const today = new Date();
  const minus1Date = new Date(today);
  minus1Date.setDate(today.getDate());

  const dayAfter = new Date(today);
  dayAfter.setDate(today.getDate());
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [checkInData, setCheckInData] = useState<CheckinDataItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tablePaginationData, setTablePaginationData] = useState<number>(0);
  const [isCheckInOpened, setIsCheckInOpened] = useState<boolean>();
  const [sortByValue, setSortByValue] = useState('All');
  const [bookStatusFilter, setBookStatusFilter] = useState('All');
  const [bookPlatformFilter, setBookPlatformFilter] = useState('All');
  const [lotFilter, setLotFilter] = useState<string>(lot);
  const [bookStatusValue, setBookStatusValue] = useState<string[]>([]);
  const [bookPlatformValue, setBookPlatformValue] = useState<string[]>([]);
  const [lots, setLots] = useState<ILot[]>([]);
  const [searchBarValue, setSearchBarValue] = useState('');
  const [displayTableData, setDisplayTableData] = useState<CheckinDataItem[]>(
    []
  );
  const [checkInTableData, setCheckInTableData] = useState<CheckinDataItem[]>(
    []
  );
  const [checkOutTableData, setCheckOutTableData] = useState<CheckinDataItem[]>(
    []
  );
  const [paginatedTableData, setPaginatedTableData] = useState<
    CheckinDataItem[]
  >([]);

  const [startTimeValue, setStartTimeValue] = useState<Date>(minus1Date);
  const [endTimeValue, setEndTimeValue] = useState<Date>(dayAfter);

  const navigate = useNavigate();

  const { updateExpiredBookingStatus, cancelExpiredWebBooking } = useUpdate();

  const handleNavigateOnClick = (bookingId: any) => {
    navigate(`${bookingId}`);
  };

  const checkInColumns = useMemo<MRT_ColumnDef<CheckinDataItem>[]>(
    () => [
      {
        accessorKey: 'bookingNumber',
        header: 'Booking No.',
        Cell: ({ row }) => {
          return (
            <Button
              onClick={() => handleNavigateOnClick(row.original.bookingId)}
              sx={{
                background: 'black',
                border: 1,
                borderColor: theme.palette.primary.main,
              }}
            >
              {row.original.bookingNumber}
            </Button>
          );
        },
      },
      {
        header: 'Booking Date',
        accessorKey: 'bookingMadeDatetime',
      },
      {
        header: 'Check-In',
        accessorKey: 'checkInTime',
      },
      {
        header: 'Check-Out',
        accessorKey: 'checkOutTime',
      },
      {
        accessorKey: 'customer',
        header: 'Customer',
      },
      {
        header: 'Room Types',
        accessorKey: 'roomTypes',
      },
      {
        accessorKey: 'otaCode',
        header: 'OTA REF',
      },
      {
        accessorKey: 'platform',
        header: 'Booking Source',
      },

      {
        accessorKey: 'status',
        header: 'Status',
      },
    ],
    []
  );

  const handleOpenCheckIn = () => {
    setIsCheckInOpened(true);
  };

  const handleOpenCheckOut = () => {
    setIsCheckInOpened(false);
  };

  const handleSortByValueChange = (event: any) => {
    setSortByValue(event);
  };

  const handleBookStatusFilterOnChange = (event: any) => {
    setBookStatusFilter(event);
  };

  const handleBookPlatformFilterOnChange = (event: any) => {
    setBookPlatformFilter(event);
  };

  const handleLotFilterOnChange = (event: any) => {
    setLotFilter(event);
  };

  const handleSearchBarOnChange = (event: any) => {
    setSearchBarValue(event.target.value);
  };

  const handleStartTimeValueChange = (e: Date) => {
    setStartTimeValue(e);
  };

  const handleEndTimeValueChange = (e: Date) => {
    setEndTimeValue(e);
  };

  // const handleTableDataPagination = () => {
  //   const startingData = pagination.pageIndex * 10;
  //   const endingData = (pagination.pageIndex + 1) * 10 - 1;

  //   for (
  //     let i = startingData;
  //     i <= endingData && i < displayTableData.length;
  //     i++
  //   ) {
  //     setPaginatedTableData([...paginatedTableData, displayTableData[i]]);
  //   }
  // };
  const fetchListData = () => {
    setIsLoading(true);

    const startDate = startTimeValue;
    const endDate = endTimeValue;
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 0);

    const startTime = convertToEpoch(startDate);
    const endTime = convertToEpoch(endDate);
    const queryParams = `startDateTime=${startTime}&endDateTime=${endTime}&forUpcomingBookings=true&lot=${lotFilter}`;

    axiosInstance
      .get(`/bookings/list?${queryParams}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const results: NewAPIBookingResult[] = res.data;

        const data = results
          .filter((item) => {
            const latestBookingStatus = item.bookingStatus.filter(
              (status) => status.isLatest === true
            )[0].bookingStatus;

            if (
              latestBookingStatus === bookingStatusEnum.checkOut ||
              latestBookingStatus === bookingStatusEnum.checkIn ||
              latestBookingStatus === bookingStatusEnum.overstay
            ) {
              return false;
            }
            return true;
          })
          .map((item, index) => {
            const latestBookingStatus = item.bookingStatus.filter(
              (status) => status.isLatest === true
            )[0].bookingStatus;

            const checkInData: CheckinDataItem = {
              id: index,
              bookingNumber: item.bookingNo,
              bookingMadeDatetime: format(
                new Date(item.bookingMadeDatetime),
                'dd/MM/yyyy HH:mm'
              ),
              checkInTime: item.bookingStatus[0]
                ? format(
                    new Date(item.bookingStatus[0].checkInDatetime),
                    'dd/MM/yyyy HH:mm'
                  )
                : '',
              checkOutTime: item.bookingStatus[0]
                ? format(
                    new Date(item.bookingStatus[0].checkOutDatetime),
                    'dd/MM/yyyy HH:mm'
                  )
                : '',
              actualCheckinDatetime: item.roomBookings[0].actualCheckinDateTime,
              actualCheckoutDatetime:
                item.roomBookings[0].actualCheckoutDateTime,
              derivedCheckInDatetime:
                item.roomBookings[0].derivedCheckInDatetime,
              derivedCheckOutDatetime:
                item.roomBookings[0].derivedCheckOutDatetime,
              customer:
                item.customerStayingName === ' '
                  ? item.customerBooked
                  : item.customerStayingName,
              platform: item.platform.platform,
              otaCode: item.otaCode ? item.otaCode : '-',
              status: latestBookingStatus ? latestBookingStatus : '',
              roomCode: item.roomBookings.map((item) => item.roomCode),
              roomTypes: Array.from(
                new Set(item.roomBookings.map((item) => item.roomType))
              ),
              bookingId: item.bookingId,
              overstayDuration:
                item.overstayDuration && item.isOverstay
                  ? item.overstayDuration.toString()
                  : '',
              isOverstay: item.isOverstay ? item.isOverstay : false,
            };
            return checkInData;
          });

        setCheckInData(data);
      })
      // setTablePaginationData(results.count);
      .catch((error) => {
        Notification.failed(error.toString());
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const fetchLots = () => {
      axiosInstance
        .get('/lot', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setLots(res.data);
        })
        .catch((error) => {
          // Handle error
          console.error('Error fetching lots:', error);
        });
    };
    fetchLots();
  }, []);

  const { logout } = useAuthentication();

  useEffect(() => {
    axiosInstance.get('/lot-settings/get-temp').then((response) => {
      const status = response.data.data.status;

      if (status === true) {
        logout();
      }
    });
  }, []);

  const handleTableDataAssignation = () => {
    const checkInDataFilter = checkInData.filter(
      (item) =>
        // item.status !== "Transfer to Airside" &&
        // item.status !== "Transfer to Landside" &&
        item.status !== 'Cancelled'
    );
    const checkOutDataFilter = checkInData.filter(
      (item) => item.status === 'Check In' || item.status === 'Overstayed'
    );

    setCheckInTableData(checkInDataFilter);
    setCheckOutTableData(checkOutDataFilter);
  };

  const handleResetDisplayTable = () => {
    if (isCheckInOpened) {
      setDisplayTableData(checkInTableData);
    } else {
      setDisplayTableData(checkOutTableData);
    }
  };

  useEffect(() => {
    fetchListData();
  }, [lotFilter, startTimeValue, endTimeValue]);

  useEffect(() => {
    handleOpenCheckIn();
    updateExpiredBookingStatus(token);
    cancelExpiredWebBooking(token);
  }, []);

  useEffect(() => {
    if (checkInTableData && checkOutTableData) {
      handleResetDisplayTable();
    }
  }, [checkInTableData, checkOutTableData, isCheckInOpened]);

  useEffect(() => {
    const uniqueStatus = new Set(checkInData.map((item) => item.status));
    const uniquePlatform = new Set(checkInData.map((item) => item.platform));
    const uniqueStatusArray = Array.from(uniqueStatus);
    const uniquePlatformArray = Array.from(uniquePlatform);
    setBookStatusValue(uniqueStatusArray);
    setBookPlatformValue(uniquePlatformArray);
    if (checkInData) {
      handleTableDataAssignation();
    }
  }, [checkInData]);

  const handleSortTimeASC = (data: CheckinDataItem[]): CheckinDataItem[] => {
    const sortedData = data.slice().sort((a, b) => {
      const dateA = getTimestamp(a.checkInTime);
      const dateB = getTimestamp(b.checkInTime);

      return dateA - dateB;
    });
    return sortedData;
  };

  const handleSortTimeDSC = (data: CheckinDataItem[]): CheckinDataItem[] => {
    const sortedData = data.slice().sort((a, b) => {
      const dateA = getTimestamp(a.checkInTime);
      const dateB = getTimestamp(b.checkInTime);

      return dateB - dateA;
    });
    return sortedData;
  };

  const handleSortBookingNumASC = (
    data: CheckinDataItem[]
  ): CheckinDataItem[] => {
    let sortedData = data.slice();
    const charToRemove = 'CKLIA-';
    sortedData.sort((a, b) => {
      const aStripString = a.bookingNumber.replace(charToRemove, '');
      const bStripString = b.bookingNumber.replace(charToRemove, '');
      const numberedA = Number(aStripString);
      const numberedB = Number(bStripString);
      return numberedA - numberedB;
    });
    return sortedData;
  };

  const handleSortBookingNumDSC = (
    data: CheckinDataItem[]
  ): CheckinDataItem[] => {
    let sortedData = [...data];
    const charToRemove = 'CKLIA-';
    sortedData.sort((a, b) => {
      const aStripString = a.bookingNumber.replace(charToRemove, '');
      const bStripString = b.bookingNumber.replace(charToRemove, '');
      const numberedA = Number(aStripString);
      const numberedB = Number(bStripString);
      return numberedB - numberedA;
    });
    return sortedData;
  };

  const handleSortBy = (data: CheckinDataItem[]): CheckinDataItem[] => {
    let newData: CheckinDataItem[] = [];
    if (sortByValue === 'All') {
      newData = [...data];
    } else if (sortByValue === 'Time ASC') {
      newData = handleSortTimeASC(data);
    } else if (sortByValue === 'Time DSC') {
      newData = handleSortTimeDSC(data);
    } else if (sortByValue === 'Booking Number ASC') {
      newData = handleSortBookingNumASC(data);
    } else if (sortByValue === 'Booking Number DSC') {
      newData = handleSortBookingNumDSC(data);
    } else if (sortByValue === 'Overstay') {
    }
    return newData;
  };

  const handleBookStatusFilter = (
    data: CheckinDataItem[]
  ): CheckinDataItem[] => {
    let newData: CheckinDataItem[] = [];
    if (bookStatusFilter === 'All') {
      newData = [...data];
    } else {
      bookStatusValue.map((item) => {
        if (bookStatusFilter === item) {
          newData = data.filter((item2) => {
            return item2.status === item;
          });
        }
      });
    }
    return newData;
  };

  const handleBookPlatformFilter = (
    data: CheckinDataItem[]
  ): CheckinDataItem[] => {
    let newData: CheckinDataItem[] = [];
    if (bookPlatformFilter === 'All') {
      newData = [...data];
    } else {
      bookPlatformValue.map((item) => {
        if (bookPlatformFilter === item) {
          newData = data.filter((item2) => {
            return item2.platform === item;
          });
        }
      });
    }
    return newData;
  };

  // const handleSearchTableData = () => {
  //   const unsearchedData = handleAllFilter();
  //   if (searchBarValue) {
  //     const searchedData = unsearchedData.filter((item) => {
  //       const { customer, bookingNumber, roomCode } = item;
  //       return (
  //         customer.toLowerCase().includes(searchBarValue.toLowerCase()) ||
  //         bookingNumber.toLowerCase().includes(searchBarValue.toLowerCase()) ||
  //         roomCode.toString().includes(searchBarValue.toLowerCase())
  //       );
  //     });
  //     setDisplayTableData(searchedData);
  //   }
  // };

  const handleAllFilter = () => {
    const rawUnsortedData = isCheckInOpened
      ? [...checkInTableData]
      : [...checkOutTableData];
    const sortedData = handleSortBy(rawUnsortedData);
    const filteredBookStatusData = handleBookStatusFilter(sortedData);
    const filteredBookPlatformData = handleBookPlatformFilter(
      filteredBookStatusData
    );
    setDisplayTableData(filteredBookPlatformData);
    return filteredBookPlatformData;
  };

  useEffect(() => {
    handleAllFilter();
  }, [sortByValue, bookStatusFilter, bookPlatformFilter]);

  return (
    <Box
      width={'85%'}
      border={1}
      borderColor={theme.palette.primary.main}
      margin={'auto'}
    >
      <Stack
        borderBottom={2}
        borderColor={theme.palette.primary.main}
        direction={'row'}
      >
        {/* <Stack  
          borderRight={2}
          borderColor={theme.palette.primary.main}
          width={"50%"}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <ButtonBase
            onClick={handleOpenCheckIn}
            sx={{
              backgroundColor: isCheckInOpened
                ? theme.palette.primary.main
                : "black",
              width: "100%",
              height: "100%",
              padding: 2,
              display: "flex",
              justifyContent: "space-between",
              transition: "background-color 0.3s ease",
            }}
          >
            <MeetingRoomIcon
              fontSize={"large"}
              sx={{ color: isCheckInOpened ? "black" : "white" }}
            />
            <Typography
              variant={"h4"}
              sx={{ color: isCheckInOpened ? "black" : "white" }}
            >
              Check In
            </Typography>
          </ButtonBase>
        </Stack>
        <Stack width={"50%"} direction={"row"} justifyContent={"space-between"}>
          <ButtonBase
            onClick={handleOpenCheckOut}
            sx={{
              backgroundColor: isCheckInOpened
                ? "black"
                : theme.palette.primary.main,
              width: "100%",
              height: "100%",
              padding: 2,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <NoMeetingRoomIcon
              fontSize={"large"}
              sx={{ color: isCheckInOpened ? "white" : "black" }}
            />
            <Typography
              variant={"h4"}
              color={isCheckInOpened ? "white" : "black"}
            >
              Check Out
            </Typography>
          </ButtonBase> */}
        {/* </Stack> */}
      </Stack>
      <Stack
        paddingY={1}
        paddingX={3}
        borderBottom={2}
        borderColor={theme.palette.primary.main}
        sx={{ background: '#232323' }}
      >
        <Typography variant={'h3'} color={theme.palette.primary.main}>
          Upcoming Bookings
        </Typography>
      </Stack>
      <Grid container borderBottom={2} borderColor={theme.palette.primary.main}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid
            item
            md={8}
            sm={12}
            xs={12}
            paddingY={4}
            paddingX={10}
            borderRight={1}
            borderColor={theme.palette.primary.main}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            // sx={{ background: "white" }}
          >
            <DatePicker
              value={startTimeValue}
              onChange={(e) => handleStartTimeValueChange(e as Date)}
              slotProps={{
                layout: {
                  sx: {
                    '.MuiDateCalendar-root': {
                      color: theme.palette.primary.main,
                      borderRadius: 2,
                      borderWidth: 1,
                      borderColor: theme.palette.primary.main,
                      border: '1px solid',
                      backgroundColor: theme.palette.background.paper,
                    },
                    '.MuiButtonBase-root': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiTypography-root': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiPickersCalendarHeader-root': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiPickersMonth-monthButton': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiPickersCalendarHeader-label': {
                      color: theme.palette.primary.main,
                    },
                    '.Mui-selected': {
                      color: 'black',
                    },
                    '.MuiPickersYear-yearButton': {
                      color: theme.palette.primary.main,
                    },
                  },
                },
                textField: {
                  InputProps: {
                    style: {
                      background: '#232323',
                    },
                  },
                  sx: {
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                      width: '20em',
                      padding: '0',
                      borderRadius: '1em',
                    },
                  },
                },
                openPickerIcon: {
                  sx: {
                    color: theme.palette.primary.main,
                  },
                },
              }}
            />
            <ArrowRightAltIcon fontSize={'large'} />
            <DatePicker
              value={endTimeValue}
              onChange={(e) => handleEndTimeValueChange(e as Date)}
              slotProps={{
                layout: {
                  sx: {
                    '.MuiDateCalendar-root': {
                      color: theme.palette.primary.main,
                      borderRadius: 2,
                      borderWidth: 1,
                      borderColor: theme.palette.primary.main,
                      border: '1px solid',
                      backgroundColor: theme.palette.background.paper,
                    },
                    '.MuiButtonBase-root': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiTypography-root': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiPickersCalendarHeader-root': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiPickersMonth-monthButton': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiPickersCalendarHeader-label': {
                      color: theme.palette.primary.main,
                    },
                    '.Mui-selected': {
                      color: 'black',
                    },
                    '.MuiPickersYear-yearButton': {
                      color: theme.palette.primary.main,
                    },
                  },
                },
                textField: {
                  InputProps: {
                    style: {
                      background: '#232323',
                    },
                  },
                  sx: {
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                      width: '20em',
                      padding: '0',
                      borderRadius: '1em',
                    },
                  },
                },
                openPickerIcon: {
                  sx: {
                    color: theme.palette.primary.main,
                  },
                },
              }}
            />
          </Grid>
        </LocalizationProvider>
        <Grid item md={4} sm={12} xs={12}>
          <ButtonBase
            onClick={fetchListData}
            sx={{
              width: '100%',
              height: '100%',
              background: '#232323',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant={'h3'}>Change Date</Typography>
          </ButtonBase>
        </Grid>
      </Grid>
      <Stack borderBottom={2} borderColor={theme.palette.primary.main}>
        <Grid container padding={2} spacing={2} justifyContent={'center'}>
          <Grid item md={10} sm={12} xs={12}>
            <TextField
              type={'search'}
              size={'small'}
              onChange={(e) => {
                handleSearchBarOnChange(e);
              }}
              sx={{
                width: '100%',
                borderRadius: 0,
                border: 1,
                borderColor: theme.palette.primary.main,
              }}
            />
          </Grid>
          {/* <Grid item md={1} sm={12} xs={12}>
            <Button
              // onClick={handleSearchTableData}
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: 0,
                border: 1,
                borderColor: theme.palette.primary.main,
                background: "#232323",
              }}
            >
              <Typography color={"white"} fontWeight={700}>
                Search
              </Typography>
            </Button>
          </Grid> */}
        </Grid>
      </Stack>
      <Stack>
        <Grid
          container
          borderBottom={2}
          borderColor={theme.palette.primary.main}
          minHeight={'5.9em'}
        >
          <Grid
            item
            md={4}
            xs={12}
            sm={12}
            display={'flex'}
            flexDirection={'row'}
          >
            <Stack
              width={'30%'}
              borderRight={2}
              padding={3}
              justifyContent={'center'}
              alignItems={'center'}
              borderColor={theme.palette.primary.main}
            >
              Sorter
            </Stack>
            <Stack
              width={'70%'}
              borderRight={2}
              borderColor={theme.palette.primary.main}
            >
              <Stack
                height={'50%'}
                borderBottom={1}
                borderColor={theme.palette.primary.main}
                justifyContent={'center'}
                paddingLeft={2}
              >
                <Typography>Sort By</Typography>
              </Stack>
              <Stack height={'50%'}>
                <Select
                  size={'small'}
                  value={sortByValue}
                  onChange={(e) => handleSortByValueChange(e.target.value)}
                >
                  <MenuItem value={'All'}>None</MenuItem>
                  <MenuItem value={'Time ASC'}>
                    {isCheckInOpened ? 'Check-In ASC' : 'Check-Out ASC'}
                  </MenuItem>
                  <MenuItem value={'Time DSC'}>
                    {isCheckInOpened ? 'Check-In DSC' : 'Check-Out DSC'}
                  </MenuItem>
                  <MenuItem value={'Booking Number ASC'}>
                    Booking No. ASC
                  </MenuItem>
                  <MenuItem value={'Booking Number DSC'}>
                    Booking No. DSC
                  </MenuItem>
                  <MenuItem value={'Overstay'}>Overstay</MenuItem>
                </Select>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            md={8}
            xs={12}
            sm={12}
            display={'flex'}
            flexDirection={'row'}
          >
            <Stack
              width={'30%'}
              borderRight={2}
              padding={3}
              justifyContent={'center'}
              alignItems={'center'}
              borderColor={theme.palette.primary.main}
            >
              <Typography>Filter</Typography>
            </Stack>
            <Stack
              width={'35%'}
              borderRight={2}
              borderColor={theme.palette.primary.main}
            >
              <Stack
                height={'50%'}
                borderBottom={1}
                borderColor={theme.palette.primary.main}
                justifyContent={'center'}
                paddingLeft={2}
              >
                <Typography>Book Status</Typography>
              </Stack>
              <Stack height={'50%'}>
                <Select
                  size={'small'}
                  value={bookStatusFilter}
                  onChange={(e) =>
                    handleBookStatusFilterOnChange(e.target.value)
                  }
                >
                  <MenuItem value={'All'}>None</MenuItem>
                  {bookStatusValue.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
            </Stack>
            <Stack
              width={'35%'}
              borderRight={2}
              borderColor={theme.palette.primary.main}
            >
              <Stack
                height={'50%'}
                borderBottom={1}
                borderColor={theme.palette.primary.main}
                justifyContent={'center'}
                paddingLeft={2}
              >
                <Typography>Book Platform</Typography>
              </Stack>
              <Stack height={'50%'}>
                <Select
                  size={'small'}
                  value={bookPlatformFilter}
                  onChange={(e) =>
                    handleBookPlatformFilterOnChange(e.target.value)
                  }
                >
                  <MenuItem value={'All'}>None</MenuItem>
                  {bookPlatformValue.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
            </Stack>
            <Stack
              width={'35%'}
              borderRight={2}
              borderColor={theme.palette.primary.main}
            >
              <Stack
                height={'50%'}
                borderBottom={1}
                borderColor={theme.palette.primary.main}
                justifyContent={'center'}
                paddingLeft={2}
              >
                <Typography>Lot</Typography>
              </Stack>
              <Stack height={'50%'}>
                <Select
                  size={'small'}
                  value={lotFilter}
                  onChange={(e) => handleLotFilterOnChange(e.target.value)}
                >
                  {lots.map((item, index) => {
                    return (
                      <MenuItem value={item.lotId} key={index}>
                        {item.lotNumber}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <CustomTable
        columns={checkInColumns}
        data={displayTableData}
        isLoading={isLoading}
        handlePaginationChange={setPagination}
        pagination={pagination}
        rowCount={displayTableData.length}
        oddEvenBackground={true}
        globalFilter={searchBarValue}
      />
    </Box>
  );
}

export default NewCheckInList;
