import { Box, Switch, Typography } from "@mui/material";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import useDevTools from "../../../hooks/useDevTools";



export default function DevTools() {

    const {
        devToolOpen,
        loading,
        permissions,
        collapse,
        disabledPermissions,
        handleCollapse,
        handleTooggleChange,
        handleClose,
    } = useDevTools(true);

    return (
        <>
            {devToolOpen ? (
                <Box
                    width={'17rem'}
                    maxHeight={'24rem'}
                    overflow={'auto'}
                    position="fixed"
                    bgcolor={"rgba(0, 0, 0, 0.5)"}
                    top={0}
                    right={0}
                    zIndex={2000}
                >
                    <Box
                        width={'100%'}
                        position={'sticky'}
                        top={0}
                    >
                        <Box
                            width={'100%'}
                            paddingY={'7px'}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            borderBottom={'1px solid lightgray'}
                            sx={{
                                cursor: 'pointer'
                            }}
                            onClick={handleClose}
                        >
                            <Typography>Close</Typography>
                        </Box>
                        <Box
                            width={'100%'}
                            paddingY={'7px'}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            borderBottom={'1px solid lightgray'}
                            position={'sticky'}
                            top={0}
                            sx={{
                                cursor: 'pointer'
                            }}
                            onClick={handleCollapse}
                        >
                            {collapse ? <ArrowDropDownIcon /> : <ArrowDropUpIcon/>}
                        </Box>
                    </Box>

                    {!collapse ? (
                        <>
                            {!loading ? (
                                <Box paddingX={'15px'}>
                                    {permissions.map((item, i) => {

                                        const active : boolean = !disabledPermissions.includes(item.codeName);

                                        return (
                                            <Box
                                                key={i}
                                                width={'100%'}
                                                paddingY={'10px'}
                                                display={'flex'}
                                                justifyContent={'space-between'}
                                                alignItems={'center'}
                                            >
                                                <Typography>{item.permissionName}</Typography>
                                                <Switch checked={active} name={item.codeName} onChange={handleTooggleChange} />
                                            </Box>
                                        )
                                    })}
                                </Box>
                            ) : (
                                <Box
                                    flex={1}
                                    height={'15rem'}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >Loading...</Box>
                            )}
                        </> 
                    ) : (
                        <></>
                    )}
                </Box>
            ) : (
                <></>
            )}
        </>
    )
}