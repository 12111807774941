import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  IBooking,
  IBookingDraft,
  IGeneratedRoomResponse,
  IPlatform,
  IRoomExpressBookingRequest,
  IStepper,
} from '../../models/bookings/Booking';

import * as Yup from 'yup';

//Components
import axios from 'axios';
import { Formik } from 'formik';
import { Location, useLocation } from 'react-router-dom';
import PaymentForm from '../../components/payment/PaymentForm';
import BASE_API from '../../constants/api';
import axiosInstance from '../../constants/axiosConfig';
import {
  IPaymentGuests,
  IPaymentItem,
} from '../../models/payment/PaymentInterface';
import { RootState, store } from '../../redux/store';

//utils
import { useDispatch, useSelector } from 'react-redux';
import ExpressBookingForm from '../../components/booking/ExpressBookingForm';
import ExpressPICAssignation from '../../components/booking/ExpressPICAssignation';
import PageTitle from '../../components/global/PageTitle';
import { transactionCategoryEnum } from '../../constants/enums';
import {
  EmptyExpressBookingRedux,
  SetExpressBooking,
} from '../../redux/booking/expressbooking';
import { calculateTimeDifferenceInHours } from '../../utils/functions';
import Notification from '../../utils/notificationConfig';
import useAuthentication from '../../hooks/useAuthentication';

export default function ExpressBookingPage() {
  const token = store.getState().user.accessToken;
  const [allPlatforms, setAllPlatforms] = useState<IPlatform[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const bookingReduxState = useSelector(
    (state: RootState) => state.expressbooking
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Read data from location state
  const location: Location = useLocation();

  const [bookingId, setBookingId] = useState<string>('');
  const [paymentItems, setPaymentItems] = useState<IPaymentItem[]>([]);

  const duration = calculateTimeDifferenceInHours(
    bookingReduxState.bookingInfo.checkInDate,
    bookingReduxState.bookingInfo.checkOutDate
  );

  useEffect(() => {
    const paymentItems = bookingReduxState.roomBookings.map((booking) => ({
      itemId: booking.room_id,
      itemName: booking.room_code,
      itemType: booking.room_type_name,
      category: transactionCategoryEnum.roomSales,
      quantity: 1,
      price: 0,
      duration: duration,
    }));
    setPaymentItems(paymentItems);

    return () => {};
  }, [bookingReduxState]);

  const [expressBookingReq, setExpressBookingReq] = useState<
    IRoomExpressBookingRequest[]
  >([]);

  const { logout } = useAuthentication();

  useEffect(() => {
    axiosInstance.get('/lot-settings/get-temp').then((response) => {
      const status = response.data.data.status;

      if (status === true) {
        logout();
      }
    });
  }, []);

  const fetchRoomBookingsDataFromBE = async () => {
    setIsLoading(true);
    await axios
      .post(
        `${BASE_API}/bookings/v1/express/generate-rooms/`,
        {
          rooms: expressBookingReq,
          checkInDatetime: `${new Date(
            bookingReduxState.bookingInfo.checkInDate
          )
            .toISOString()
            .slice(0, -5)}Z`,
          duration: duration,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const results = res.data as IGeneratedRoomResponse[];
        const bookingDrafts = results.map((item) => {
          const bookingDraftItem: IBookingDraft = {
            room_id: item.roomId,
            room_code: item.roomCode,
            room_zone: item.roomType.roomzone.zoneName,
            room_type_name: item.roomType.typeName,
            check_in: bookingReduxState.bookingInfo.checkInDate,
            check_out: bookingReduxState.bookingInfo.checkOutDate,
            duration: 3,
            color_code: item.roomType.colorTags,
            room_type_details: item.roomType.RoomTypeDetails,
            person_in_charge_ic: '',
            person_in_charge_id: '',
            person_in_charge_name: '',
            person_in_charge_country: '',
            member_id: '',
            member_tier: '',
            member_condition: '',
            max_pax: item.roomType.maxPax,
            rentention_count: 0,
          };
          return bookingDraftItem;
        });
        dispatch(
          SetExpressBooking({
            bookingInfo: bookingReduxState.bookingInfo,
            roomBookings: bookingDrafts,
          })
        );
      })
      .catch((res) => {
        if (res.response.status === 409) {
          Notification.failed(res.response.data);
        }
        handleChangeStep({ step: 1, text: 'PIC' });
        dispatch(EmptyExpressBookingRedux());
        return;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const parsePlatformData = (fetchedPlatform: any[]): IPlatform[] => {
    if (fetchedPlatform) {
      return fetchedPlatform.map((item: any) => ({
        platformId: item.platform_id.toString(),
        platform: item.platform,
      }));
    } else {
      return [];
    }
  };

  const fetchAllAvailablePlatform = () => {
    axiosInstance
      .get('/bookings/platforms/get-all-platforms', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          setAllPlatforms(parsePlatformData(res.data.datas));
        }
      })
      .catch((e) => {
        Notification.failed('something wrong, please try again');
      });
  };

  useEffect(() => {
    fetchAllAvailablePlatform();
  }, []);

  const stepperValue: IStepper[] = [
    { step: 1, text: 'Booking Info' },
    { step: 2, text: 'Add Guest' },
    { step: 3, text: 'Payment' },
  ];
  const [stepper, setStepper] = useState<number>(1);

  const theme = useTheme();

  const handleChangeStep = (data: IStepper) => {
    setStepper(data.step);
  };

  const [picError, setPicError] = useState<boolean>(false);
  const handleSetPicError = (data: boolean) => {
    setPicError(data);
  };

  useEffect(() => {
    if (stepper === 2) {
      fetchRoomBookingsDataFromBE();
    }
    return () => {};
  }, [stepper]);

  const handleOnSubmit = async (values: IBooking) => {
    if (!values.roomBookings[0].person_in_charge_id) {
      Notification.failed('Please assign a Guest');
      return;
    }
    setLoading(true);
    await axios
      .post(
        `${BASE_API}/bookings/register/`,
        {
          checkInDatetime: `${new Date(values.bookingInfo.checkInDate)
            .toISOString()
            .slice(0, -5)}Z`,
          checkOutDatetime: `${new Date(values.bookingInfo.checkOutDate)
            .toISOString()
            .slice(0, -5)}Z`,
          adult: values.bookingInfo.adultPax,
          child: values.bookingInfo.childrenPax,
          platformId: values.bookingInfo.platformId,
          otaCode: values.bookingInfo.otaRefNo,
          details: '',
          rooms: values.roomBookings.map((item) => ({
            picId: item.person_in_charge_id,
            roomId: item.room_id,
            details: '',
          })),
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(({ data }) => {
        const bookingInfo = { ...values.bookingInfo };
        bookingInfo.id = data.data.bookingId;
        setGuestList({
          customerStayingId: values.bookingInfo.customerStayingId,
          customerStayingName: values.bookingInfo.customerStayingName,
          guests: values.roomBookings.map((data) => ({
            guestId: data.person_in_charge_id,
            guestName: data.person_in_charge_name,
            isMember: data.member_id !== 'None' ? true : false,
          })),
        });
        dispatch(
          SetExpressBooking({
            bookingInfo: bookingInfo,
            roomBookings: values.roomBookings,
          })
        );
        setBookingId(data.data.bookingId);
        handleChangeStep({ step: 3, text: 'Payment' });
      })
      .catch((res) => {
        Notification.failed(res.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const initialGuestsList: IPaymentGuests = {
    customerStayingId: bookingReduxState.bookingInfo.customerStayingId,
    customerStayingName: bookingReduxState.bookingInfo.customerStayingName,
    guests: bookingReduxState.roomBookings.map((data) => ({
      guestId: data.person_in_charge_id,
      guestName: data.person_in_charge_name,
      isMember: data.member_id !== 'None' ? true : false,
    })),
  };

  const [guestList, setGuestList] = useState(initialGuestsList);

  return (
    <>
      <PageTitle title="Express Booking" />
      <Stack direction={'row'} justifyContent={'center'} spacing={2}>
        {stepperValue.map((item, index) => (
          <Box
            key={index}
            display={'flex'}
            width={'300px'}
            height={'50px'}
            alignItems={'center'}
            border={1}
            borderColor={theme.palette.primary.main}
            sx={{
              backgroundColor:
                stepper === item.step ? theme.palette.primary.main : 'black',
            }}
          >
            <Box
              display={'flex'}
              width={'100px'}
              height={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
              borderRight={1}
              borderColor={theme.palette.primary.main}
              sx={{ backgroundColor: 'black' }}
            >
              <Typography variant="h3">{item.step}</Typography>
            </Box>
            <Box width={'70%'}>
              <Typography
                variant="h3"
                color={stepper === item.step ? 'black' : 'white'}
                textAlign={'center'}
              >
                {item.text}
              </Typography>
            </Box>
          </Box>
        ))}
      </Stack>
      <Formik
        enableReinitialize={true}
        initialValues={bookingReduxState}
        validationSchema={Yup.object().shape({})}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          handleOnSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          values,
          touched,
          setFieldValue,
        }) => (
          <>
            {stepper === 1 && (
              <ExpressBookingForm
                initialValues={bookingReduxState.bookingInfo}
                setFieldValue={setFieldValue}
                handleChangeStep={handleChangeStep}
                allPlatforms={allPlatforms}
                expressBookingReq={expressBookingReq}
                setRoomTypeCountCallback={(
                  roomTypeName: string,
                  count: number
                ) => {
                  setExpressBookingReq((prev) => {
                    const item = prev.find(
                      (item) => item.roomTypeName === roomTypeName
                    );
                    if (item !== undefined) {
                      item.count = count;
                      return [...prev];
                    }

                    const req: IRoomExpressBookingRequest = {
                      roomTypeName: roomTypeName,
                      count: count,
                    };
                    prev.push(req);

                    const arr = prev.filter((item) => item.count !== 0);
                    return [...arr];
                  });
                }}
              />
            )}

            {stepper === 2 && (
              <ExpressPICAssignation
                setFieldValue={setFieldValue}
                value={values.bookingInfo}
                handleChangeStep={handleChangeStep}
                roomBookingArray={bookingReduxState.roomBookings}
                initialValues={values.bookingInfo}
                picError={picError}
                triggerSubmit={() => {
                  handleOnSubmit(bookingReduxState);
                }}
                isSubmitting={isLoading}
                bookingState={bookingReduxState}
                loading={loading}
              />
            )}
          </>
        )}
      </Formik>
      {stepper === 3 && (
        <PaymentForm
          paymentItem={paymentItems}
          bookingId={bookingId}
          bookingGuest={guestList}
          getPastTransactions={false}
          isExpressBooking={true}
          latestBookingStatus="Booked"
        />
      )}
    </>
  );
}
