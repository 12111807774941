import {
  AccountBalance,
  ArrowBack,
  ArrowForward,
  CardGiftcard,
  CreditCard,
  Done,
  HighlightOff,
  KeyboardReturn,
  LocalAtm,
  MoreHoriz,
  QrCode,
  QuestionMark,
} from '@mui/icons-material';
import {
  Backdrop,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { FormikErrors, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../constants/axiosConfig';
import { extraCharges, transactionCategoryEnum } from '../../constants/enums';
import {
  IPaymentItem,
  IPaymentMethod,
  IPayments,
} from '../../models/payment/PaymentInterface';
import { RoomTypeInterfaceV2 } from '../../models/rooms/roomsInterface';
import { RootState } from '../../redux/store';
import { handleCheckIsBooking } from '../../utils/functions';
import Notification from '../../utils/notificationConfig';
import { convertTo2Decimals } from '../../utils/paymentFunction';
import ManagementModalHeader from '../management/ManagementModalHeader';
import PaymentTerminalModal from './PaymentTerminalModal';

interface PaymentTypeFormProps {
  handlePaymentModalClose: VoidFunction;
  payment: IPayments;
  bookingId: string;
  paymentMethods: IPaymentMethod[];
  isExpressBooking: boolean;
  isPOSGuestPayment: boolean;
  handleCompletePOSPayment?: VoidFunction;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.default',
  border: 1,
  borderColor: 'primary.main',
  boxShadow: 24,
};

const PaymentTypeForm = ({
  handlePaymentModalClose,
  payment,
  paymentMethods,
  bookingId,
  isExpressBooking,
  isPOSGuestPayment,
  handleCompletePOSPayment,
}: PaymentTypeFormProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const initialValue = payment;
  const paymentMethodInitial: IPaymentMethod = {
    paymentMethod: '',
    paymentMethodDesc: '',
    paymentMethodId: 0,
    paymentTypes: [],
  };

  const paymentMethodsWithoutPayLater = paymentMethods.filter(
    (method) => method.paymentMethod !== 'Paylater'
  );

  const [paymentState, setPaymentState] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] =
    useState<IPaymentMethod>(paymentMethodInitial);

  const [openPaymentExtraInfoModal, setOpenPaymentExtraInfoModal] =
    useState<boolean>(false);

  const [openPaymentTerminalModal, setOpenPaymentTerminalModal] =
    useState<boolean>(false);
  const [paymentTerminalTransactionId, setPaymentTerminalTransactionId] =
    useState<string>('');

  const [isLoadingRoomType, setIsLoadingRoomType] = useState<boolean>(false);
  const [roomTypes, setRoomTypes] = useState<string[]>([]);

  const token = useSelector((state: RootState) => state.user.accessToken);
  const accountId = useSelector((state: RootState) => state.user.accountId);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleOpenPaymentExtraInfoModal = () => {
    setOpenPaymentExtraInfoModal(true);
  };

  const handleClosePaymentExtraInfoModal = () => {
    setOpenPaymentExtraInfoModal(false);
  };

  const handleOpenPaymentTerminalModal = (transactionId: string) => {
    setPaymentTerminalTransactionId(transactionId);
    setOpenPaymentTerminalModal(true);
  };

  const handleClosePaymentTerminalModal = (
    keepPaymentTypeModalOpen?: boolean
  ) => {
    setOpenPaymentTerminalModal(false);
    console.log(keepPaymentTypeModalOpen);
    if (keepPaymentTypeModalOpen) {
    } else {
      handlePaymentModalClose();
    }
  };

  useEffect(() => {
    const apiUrl = '/rooms/v1/room-type/list';
    setIsLoadingRoomType(true);

    axiosInstance
      .get(apiUrl, config)
      .then((res) =>
        setRoomTypes(res.data.map((data: RoomTypeInterfaceV2) => data.typeName))
      )
      .catch((res) => console.log(res.response.data))
      .finally(() => setIsLoadingRoomType(false));
  }, []);

  const formik = useFormik({
    initialValues: initialValue,

    onSubmit: (values, { setSubmitting, setFieldValue }) => {
      const apiUrl = '/transaction/';

      const taxes = values.items.filter(
        (item) => item.itemType === extraCharges.tax
      );

      const totalTaxes = taxes.reduce(
        (current, item) => current + item.price,
        0
      );

      const serviceCharge = values.items.filter(
        (item) => item.itemType === extraCharges.serviceCharge
      );

      const totalServiceCharge = serviceCharge.reduce(
        (current, item) => current + item.price,
        0
      );
      const addHourItem = values.items.filter(
        (item) =>
          item.itemType.includes('hrs Extend') || item.itemType === 'Add 1 hour'
      );

      let totalSum = values.sum;
      let totalDebit = values.debitAmount;
      let totalCredit = 0;
      let totalItems = values.items;
      let rounding = 0;

      rounding = totalItems
        .filter((item) => item.category === transactionCategoryEnum.rounding)
        .reduce((curr, acc) => (curr = curr + acc.price), 0);

      if (values.transactionId) totalDebit = values.creditAmount;

      if (values.paymentTypeName === 'Complimentary') {
        totalItems.push({
          itemId: '',
          itemName: 'Complimentary',
          itemType: 'Promotion',
          category: transactionCategoryEnum.promotion,
          price: convertTo2Decimals(totalDebit * -1),
        });
        totalDebit = 0;
      }

      if (values.paymentTypeName === 'Recovery Services') {
        totalItems.push({
          itemId: '',
          itemName: 'Recovery Services',
          itemType: 'Promotion',
          category: transactionCategoryEnum.promotion,
          price: convertTo2Decimals(totalDebit * -1),
        });
        totalDebit = 0;
        totalCredit = 0;
      }

      const uniqueItemTypes = new Set<string>();
      const adjustmentItems = totalItems
        .filter(
          ({ category }) => category === transactionCategoryEnum.adjustmentSales
        )
        .filter((item) => {
          if (
            !uniqueItemTypes.has(item.itemType) ||
            ['Updated Price', 'Adjustment'].includes(item.itemName)
          ) {
            uniqueItemTypes.add(item.itemType);
            return true;
          }
          return false;
        });

      const { extendDuration, nonExtendItems } = adjustmentItems.reduce(
        (acc, item) => {
          if (
            item.itemType.includes('hrs Extend') ||
            item.itemType.includes('Add 1 hour')
          ) {
            acc.extendDuration += item.duration || 0;
          } else {
            acc.nonExtendItems.push(item.itemName); // Assuming you need itemName here
          }
          return acc;
        },
        { extendDuration: 0, nonExtendItems: [] as string[] }
      );

      const adjustmentExtendTotalString =
        extendDuration > 0 ? `Add ${extendDuration} hours` : '';
      const adjustmentNonExtendString = nonExtendItems.join(', ');
      const adjustmentString =
        adjustmentNonExtendString && adjustmentExtendTotalString
          ? `${adjustmentNonExtendString}, ${adjustmentExtendTotalString}`
          : adjustmentNonExtendString
          ? adjustmentNonExtendString
          : adjustmentExtendTotalString;

      const commonFormData = {
        accountId: accountId,
        paymentDetails: JSON.stringify(totalItems),
        sum: totalSum,
        debitAmount: totalDebit, //To change to integration with POS
        creditAmount: totalCredit,
        paymentTypeId: values.paymentType,
        paymentType: values.paymentType,
        transactionStatus: 'Paid',
        currency: '1',
        taxAmount: convertTo2Decimals(totalTaxes),
        serviceChargeAmount: convertTo2Decimals(totalServiceCharge),
        items: totalItems,
        isRoomBooking: handleCheckIsBooking(totalItems, roomTypes),
        adjustmentsAmount: totalItems
          .filter((item) => item.itemName === 'Adjustment')
          .reduce((acc, curr) => {
            if (curr.quantity) {
              return parseFloat((acc + curr.price * curr.quantity).toFixed(2));
            } else {
              return parseFloat((acc + curr.price).toFixed(2));
            }
          }, 0),
        adjustementsAmount: totalItems
          .filter((item) => item.itemName === 'Adjustment')
          .reduce((acc, curr) => {
            if (curr.quantity) {
              return parseFloat((acc + curr.price * curr.quantity).toFixed(2));
            } else {
              return parseFloat((acc + curr.price).toFixed(2));
            }
          }, 0),
        adjustments: adjustmentString,
        settingsName: addHourItem.length > 0 ? 'Add 1 hour' : '',
        addedHour: checkAdjustments(addHourItem),
        promotionAmount:
          totalItems
            .filter((item) => item.itemType === 'Promotion')
            .reduce((acc, curr) => acc + curr.price, 0) * -1,
        rounding: rounding,
        guestGiven: values.guestGiven ? values.guestGiven : 0,
        guestChange: values.guestChange ? values.guestChange : 0,
        pan: values.pan ? values.pan : '',
        paymentReference: values.paymentReference
          ? values.paymentReference
          : '',
        paymentRemarks: values.paymentRemarks ? values.paymentRemarks : '',
      };

      if (
        (values.paymentTypeName &&
          checkPaymentMethod(values.paymentTypeName) !== 'E-Wallet' &&
          checkPaymentMethod(values.paymentTypeName) !== 'Card') ||
        openPaymentExtraInfoModal
      ) {
        if (values.transactionId) {
          const apiUrl = `/transaction/pay-pending-transaction/`;
          const formData = {
            transactionId: values.transactionId,
            ...commonFormData,
          };

          axiosInstance
            .patch(apiUrl, formData, config)
            .then((response) => {
              handleClosePaymentExtraInfoModal();
              setPaymentState(true);
              Notification.success('Payment Successfully Registered');
            })
            .catch((res) => {
              Notification.failed(res.response.data.message);
              setSubmitting(false);
            });
        } else {
          const formData = isPOSGuestPayment
            ? {
                ...commonFormData,
                inHouseGuest: false,
              }
            : {
                ...commonFormData,
                booking: bookingId, //To Add Booking ID here
                customer: values.customer,
              };

          axiosInstance
            .post(apiUrl, formData, config)
            .then((response) => {
              handleClosePaymentExtraInfoModal();
              setPaymentState(true);
              Notification.success('Payment Successfully Registered');
            })
            .catch((res) => {
              Notification.failed(res.response.data.message);
              setSubmitting(false);
            });
        }
      } else {
        if (values.transactionId) {
          handleOpenPaymentTerminalModal(values.transactionId);
        } else {
          const pendingFormData = {
            ...commonFormData,
            transactionStatus: 'Pending Payment',
            debitAmount: 0,
            creditAmount: totalDebit,
          };

          const formData = isPOSGuestPayment
            ? {
                ...pendingFormData,
                inHouseGuest: false,
              }
            : {
                ...pendingFormData,
                booking: bookingId, //To Add Booking ID here
                customer: values.customer,
              };

          axiosInstance
            .post(apiUrl, formData, config)
            .then((response) => {
              // Notification.success("Payment Successfully Registered");
              setFieldValue('transactionId', response.data.transactionId);
              handleOpenPaymentTerminalModal(response.data.transactionId);
            })
            .catch((res) => {
              Notification.failed(res.response.data.message);
              setSubmitting(false);
            });
        }
      }

      if (openPaymentExtraInfoModal) handleClosePaymentExtraInfoModal();
      if (openPaymentTerminalModal) handleClosePaymentTerminalModal(true);
    },
  });

  const handlePaymentProcess = (data: IPaymentMethod) => {
    if (data.paymentMethod === data.paymentTypes[0].paymentType) {
      formik.setFieldValue('paymentType', data.paymentTypes[0].paymentTypeId);
      formik.setFieldValue('paymentTypeName', data.paymentTypes[0].paymentType);
      if (data.paymentTypes[0].paymentType === 'Cash') {
        handleOpenPaymentExtraInfoModal();
      } else {
        formik.handleSubmit();
      }
    } else {
      setPaymentMethod(data);
    }
  };

  const handleModalClose = () => {
    handlePaymentModalClose();
    if (isExpressBooking && paymentState) {
      navigate(`/upcoming-booking/${bookingId}`, {
        state: { isDirected: true },
      });
    }

    if (handleCompletePOSPayment) handleCompletePOSPayment();
  };

  const checkAdjustments = (addHourItem: IPaymentItem[]) => {
    if (addHourItem.length <= 0) return 0;

    let amountOfHoursAdded = 0;
    const roomNormal = addHourItem.filter((item) =>
      item.itemType.includes('hrs Extend')
    );

    const adjustmentPrice = addHourItem.filter(
      (item) => item.itemType === 'Add 1 hour'
    );
    if (roomNormal.length > 0 && adjustmentPrice.length > 0) {
      amountOfHoursAdded =
        roomNormal[0].duration! + adjustmentPrice[0].duration!;
    } else if (roomNormal.length > 0 && roomNormal[0].duration) {
      amountOfHoursAdded = roomNormal[0].duration;
    } else if (adjustmentPrice.length > 0 && adjustmentPrice[0].duration) {
      amountOfHoursAdded = adjustmentPrice[0].duration;
    }
    return amountOfHoursAdded;
  };

  const checkPaymentMethod = (targetPaymentType: string) => {
    for (const paymentMethod of paymentMethods) {
      if (
        paymentMethod.paymentTypes.some(
          (paymentType) => paymentType.paymentType === targetPaymentType
        )
      ) {
        return paymentMethod.paymentMethod;
      }
    }
    return null;
  };

  return (
    <Modal
      open={true}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          },
        },
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box sx={style}>
          <Box
            display={'flex'}
            height={'40px'}
            alignItems={'center'}
            paddingX={1}
            borderBottom={1}
            borderColor={theme.palette.primary.main}
          >
            <Stack
              width={'100%'}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography variant="h4" color={'primary'}>
                Payment
              </Typography>
              <IconButton color="primary" onClick={handleModalClose}>
                <HighlightOff />
              </IconButton>
            </Stack>
          </Box>

          {!paymentState ? (
            <>
              <Box
                display={'flex'}
                alignItems={'center'}
                height={'150px'}
                paddingX={'30px'}
                sx={{ backgroundColor: 'black' }}
              >
                {formik.isSubmitting ? (
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography variant="h4">
                    Select any payment method to continue
                  </Typography>
                )}
              </Box>
              <Grid container width={'100%'} height={'50%'}>
                {!paymentMethod.paymentMethod ? (
                  paymentMethodsWithoutPayLater.map((methods, index) => (
                    <Grid item xs={4} key={index}>
                      <ButtonBase
                        onClick={() => handlePaymentProcess(methods)}
                        disabled={
                          formik.isSubmitting ||
                          isLoadingRoomType ||
                          roomTypes.length === 0
                        }
                        sx={{ width: '100%', height: '80px' }}
                      >
                        <SelectPaymentTypeBox method={methods.paymentMethod} />
                      </ButtonBase>
                    </Grid>
                  ))
                ) : (
                  <>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                      <ButtonBase
                        key={'Back'}
                        disabled={isLoadingRoomType || roomTypes.length === 0}
                        onClick={() => {
                          setPaymentMethod(paymentMethodInitial);
                        }}
                        sx={{ width: '100%', height: '80px' }}
                      >
                        <SelectPaymentTypeBox method={'Back'} />
                      </ButtonBase>
                    </Grid>
                    {paymentMethod.paymentTypes.map((types, index) => (
                      <Grid item xs={4} sm={4} md={4} lg={4} xl={4} key={index}>
                        <ButtonBase
                          onClick={() => {
                            formik.setFieldValue(
                              'paymentType',
                              types.paymentTypeId
                            );
                            formik.setFieldValue(
                              'paymentTypeName',
                              types.paymentType
                            );
                            if (types.paymentType === 'Cash') {
                              handleOpenPaymentExtraInfoModal();
                            } else {
                              formik.handleSubmit();
                            }
                          }}
                          disabled={formik.isSubmitting}
                          sx={{ width: '100%', height: '80px' }}
                        >
                          <SelectPaymentTypeBox
                            method={paymentMethod.paymentMethod}
                            type={types.paymentType}
                          />
                        </ButtonBase>
                      </Grid>
                    ))}
                  </>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                height={'230px'}
                paddingX={'30px'}
                sx={{ backgroundColor: 'black' }}
              >
                <Done
                  color="primary"
                  sx={{ fontSize: '50px', marginRight: '20px' }}
                />
                <Typography variant="h3">Payment Successful</Typography>
              </Box>
              <Stack direction={'row'} width={'100%'} height={'110px'}>
                {handleCompletePOSPayment ? (
                  <>
                    <ButtonBase
                      onClick={handleCompletePOSPayment}
                      sx={{
                        width: '50%',
                        height: '100%',
                      }}
                    >
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        width={'100%'}
                        height={'100%'}
                        borderTop={1}
                        borderRight={1}
                        borderColor={theme.palette.primary.main}
                        paddingX={1}
                      >
                        <ArrowBack sx={{ fontSize: '50px' }} />
                        <Typography variant="h4">Go to POS</Typography>
                      </Box>
                    </ButtonBase>
                    <ButtonBase
                      onClick={() => {
                        navigate(`/pos/pos-bookings/${bookingId}`, {
                          state: { isDirected: true },
                        });
                      }}
                      sx={{
                        width: '50%',
                        height: '100%',
                      }}
                    >
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        width={'100%'}
                        height={'100%'}
                        borderTop={1}
                        borderColor={theme.palette.primary.main}
                        paddingX={1}
                      >
                        <ArrowForward sx={{ fontSize: '50px' }} />
                        <Typography variant="h4">Go to POS Booking</Typography>
                      </Box>
                    </ButtonBase>
                  </>
                ) : (
                  <>
                    <ButtonBase
                      href={`/upcoming-booking`}
                      sx={{
                        width: '50%',
                        height: '100%',
                      }}
                    >
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        width={'100%'}
                        height={'100%'}
                        borderTop={1}
                        borderRight={1}
                        borderColor={theme.palette.primary.main}
                        paddingX={1}
                      >
                        <ArrowBack sx={{ fontSize: '50px' }} />
                        <Typography variant="h4">
                          Go to Upcoming Booking
                        </Typography>
                      </Box>
                    </ButtonBase>
                    <ButtonBase
                      onClick={() => {
                        navigate(`/upcoming-booking/${bookingId}`, {
                          state: { isDirected: true },
                        });
                      }}
                      sx={{
                        width: '50%',
                        height: '100%',
                      }}
                    >
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        width={'100%'}
                        height={'100%'}
                        borderTop={1}
                        borderColor={theme.palette.primary.main}
                        paddingX={1}
                      >
                        <ArrowForward sx={{ fontSize: '50px' }} />
                        <Typography variant="h4">
                          Go to Guest Profile
                        </Typography>
                      </Box>
                    </ButtonBase>
                  </>
                )}
              </Stack>
            </>
          )}
        </Box>
        {formik.values.paymentTypeName && (
          <PaymentExtraInfoModal
            handleCloseModal={handleClosePaymentExtraInfoModal}
            open={openPaymentExtraInfoModal}
            paymentTypeName={formik.values.paymentTypeName}
            creditAmount={formik.values.creditAmount}
            submitPayment={formik.handleSubmit}
            setFieldValue={formik.setFieldValue}
          />
        )}
        <PaymentTerminalModal
          open={openPaymentTerminalModal}
          handleClose={handleClosePaymentTerminalModal}
          openManual={handleOpenPaymentExtraInfoModal}
          transactionId={paymentTerminalTransactionId}
        />
      </form>
    </Modal>
  );
};

const SelectPaymentTypeBox = ({
  method,
  type,
}: {
  method: string;
  type?: string;
}) => {
  const theme = useTheme();
  const text = !type ? method : type;
  const handlePaymentMethodIcon = (method: string) => {
    switch (method) {
      case 'Cash':
        return <LocalAtm sx={{ fontSize: '50px' }} />;
      case 'Card':
        return <CreditCard sx={{ fontSize: '50px' }} />;
      case 'E-Wallet':
        return <QrCode sx={{ fontSize: '50px' }} />;
      case 'Voucher':
        return <CardGiftcard sx={{ fontSize: '50px' }} />;
      case 'Bank Transfer':
        return <AccountBalance sx={{ fontSize: '50px' }} />;
      case 'Others':
        return <MoreHoriz sx={{ fontSize: '50px' }} />;
      // case "Others":
      //   return <AutoAwesome sx={{ fontSize: "50px" }} />;
      case 'Back':
        return <KeyboardReturn sx={{ fontSize: '50px' }} />;
      default:
        return <QuestionMark sx={{ fontSize: '50px' }} />;
    }
  };
  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      width={'100%'}
      height={'100%'}
      border={1}
      borderColor={theme.palette.primary.main}
    >
      <Stack direction={'row'} width={'80%'} alignItems={'center'} spacing={2}>
        {handlePaymentMethodIcon(method)}{' '}
        <Typography variant="h4">{text}</Typography>
      </Stack>
    </Box>
  );
};

const PaymentExtraInfoModal = (props: {
  open: boolean;
  handleCloseModal: VoidFunction;
  paymentTypeName: string;
  creditAmount: number;
  submitPayment: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<IPayments>>;
}) => {
  const theme = useTheme();

  const [guestGivenField, setGuestGivenField] = useState<string>('');
  const [guestChangeField, setGuestChangeField] = useState<string>('');

  const [panField, setPanField] = useState<string>('');
  const [paymentRemarksField, setPaymentRemarksField] = useState<string>('');
  const [paymentReferenceField, setPaymentReferenceField] =
    useState<string>('');

  useEffect(() => {
    const change =
      parseFloat(guestGivenField) - parseFloat(props.creditAmount.toFixed(2));

    setGuestChangeField(change.toFixed(2));
  }, [guestGivenField, props.creditAmount]);

  const handleSubmit = () => {
    if (props.paymentTypeName === 'Cash') {
      if (guestChangeField === '' || parseFloat(guestChangeField) < 0) {
        Notification.warning('Given is Less than price');
        return;
      }
      props.setFieldValue('guestGiven', parseFloat(guestGivenField).toFixed(2));
      props.setFieldValue('guestChange', guestChangeField);
    } else {
      props.setFieldValue('pan', panField);
      props.setFieldValue('paymentRemarks', paymentRemarksField);
      props.setFieldValue('paymentReference', paymentReferenceField);
    }

    props.submitPayment();
  };

  return (
    <Modal open={props.open} onClose={props.handleCloseModal}>
      <Box
        width={'700px'}
        border={`3px solid ${theme.palette.primary.main}`}
        alignItems={'center'}
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'black',
          borderRadius: 1,
        }}
      >
        <ManagementModalHeader
          title={'Payment Remarks'}
          handleModalClose={props.handleCloseModal}
        />
        {props.paymentTypeName === 'Cash' ? (
          <Stack
            width={'100%'}
            direction={'column'}
            paddingX={3}
            paddingY={5}
            spacing={2}
          >
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Typography variant="h4" width={'30%'}>
                To Collect Amount
              </Typography>
              <TextField
                type="number"
                variant="standard"
                value={props.creditAmount.toFixed(2)}
                sx={{
                  width: '70%',
                  '& input': { backgroundColor: 'transparent' },
                  '& fieldset': { borderColor: 'transparent !important' },
                }}
              />
            </Stack>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Typography variant="h4" width={'30%'}>
                Guest Given Amount
              </Typography>
              <TextField
                type="number"
                variant="standard"
                value={guestGivenField}
                onChange={(event) => setGuestGivenField(event.target.value)}
                sx={{ width: '70%' }}
              />
            </Stack>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Typography variant="h4" width={'30%'}>
                Guest Change Amount
              </Typography>
              <TextField
                variant="standard"
                value={guestChangeField !== '' ? guestChangeField : '0'}
                sx={{ width: '70%' }}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack
            width={'100%'}
            direction={'column'}
            paddingX={3}
            paddingY={5}
            spacing={2}
          >
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Typography variant="h4" width={'30%'}>
                Last 4 Digits
              </Typography>
              <TextField
                variant="standard"
                value={panField}
                onChange={(event) => setPanField(event.target.value)}
                sx={{ width: '70%' }}
              />
            </Stack>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Typography variant="h4" width={'30%'}>
                Approval Code
              </Typography>
              <TextField
                variant="standard"
                value={paymentReferenceField}
                onChange={(event) =>
                  setPaymentReferenceField(event.target.value)
                }
                sx={{ width: '70%' }}
              />
            </Stack>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Typography variant="h4" width={'30%'}>
                Payment Remarks
              </Typography>
              <TextField
                variant="standard"
                value={paymentRemarksField}
                onChange={(event) => setPaymentRemarksField(event.target.value)}
                sx={{ width: '70%' }}
              />
            </Stack>
          </Stack>
        )}
        <Box
          display={'flex'}
          height={'50px'}
          bgcolor={theme.palette.background.paper}
          justifyContent={'end'}
          padding={1}
          borderTop={2}
          borderColor={theme.palette.primary.main}
        >
          <Button variant={'outlined'} onClick={handleSubmit}>
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PaymentTypeForm;
