export enum statusEnum {
  occupiedDirty = 'occupied, dirty',
  occupiedClean = 'occupied, cleaned',
  available = 'vacant, clean',
  overstayed = 'overstay',
  dirty = 'vacant, dirty',
  maintenance = 'maintenance',
}

export enum roomTypeEnum {
  suite = 'Suite',
  male = 'Male Single',
  female = 'Female Single',
  mixed = 'Mixed Single',
  queen = 'Queen',
  interstellar = 'Interstellar Pod',
}

export enum transactionStatusEnum {
  pending = 'Pending Payment',
  paid = 'Paid',
  refund = 'Refund',
  void = 'Void',
  paidLandside = 'Paid at Landside',
  paidAirside = 'Paid at Airside',
  pendingLandside = 'Pay at Landside',
  pendingAirside = 'Pay at Airside',
}

export enum bookingStatusEnum {
  checkIn = 'Check In',
  checkOut = 'Check Out',
  cancelled = 'Cancelled',
  booked = 'Booked',
  confirmBooking = 'Confirm Booking',
  noShow = 'No Show',
  transferFromAirSide = 'Transfer From Airside',
  transferFromLandSide = 'Transfer From Landside',
  transferToLandSide = 'Transfer to Landside',
  transferToAirSide = 'Transfer to Airside',
  reservation = 'Reservation',
  overstay = 'Overstayed',
  transferTo = 'Transfer to',
  transferFrom = 'Transfer From',
}
export enum extraCharges {
  serviceCharge = 'Service Charge',
  tax = 'Tax',
}

export enum transactionCategoryEnum {
  roomSales = 'ROOM_SALES',
  merchSales = 'MERCH_SALES',
  lockerSales = 'LOCKER_SALES',
  showerSales = 'SHOWER_SALES',
  adjustmentSales = 'ADJUSTMENT_SALES',
  tax = 'TAX',
  serviceCharge = 'SERVICE_CHARGE',
  roomUpgrade = 'ROOM_UPGRADE',
  promotion = 'PROMOTION',
  rounding = 'ROUNDING',
  overstay = 'OVERSTAY',
}

export enum lockerStatusEnum {
  available = 'Available',
  occupied = 'Occupied',
  maintenance = 'Maintenance',
}

export const handleRoomTypeCategory = (roomType: string) => {
  return roomType === roomTypeEnum.female
    ? 'F'
    : roomType === roomTypeEnum.male
    ? 'M'
    : roomType === roomTypeEnum.mixed
    ? 'Mx'
    : roomType === roomTypeEnum.queen
    ? 'Qn'
    : roomType === roomTypeEnum.suite
    ? 'Su'
    : roomType === roomTypeEnum.interstellar && 'In';
};

export enum zReportVersionEnum {
  detailed = 'detailed',
  summary = 'summary',
  mahb = 'mahb',
}
