import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { store } from '../../redux/store';

import axios, { AxiosResponse } from 'axios';
import GanttChart from '../../components/ganttchart/GanttChart';
import {
  IBookingRowInfo,
  IAxiosBookingRowsResponseData,
  IAxiosPlatformsResponseData,
  IAxiosRoomTypesResponseData,
  IAxiosRoomZonesResponseData,
  IBookingDraft,
} from '../../types/ganttChart';
import { Box } from '@mui/system';
import Notification from '../../utils/notificationConfig';
import BASE_API from '../../constants/api';
import GanttChartFilterBar from '../../components/ganttchart/GanttChartFilterBar';
import { ButtonBase, Stack, Typography } from '@mui/material';
import FilterBar from '../../components/ganttchart/FilterBar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AddedBooking from '../../components/ganttchart/AddedBooking';
import theme from '../../assets/theme/theme';
import EastIcon from '@mui/icons-material/East';
import { IOTACompletedRooms } from '../../models/ota/OTAInterfaces';
import useAuthentication from '../../hooks/useAuthentication';
import axiosInstance from '../../constants/axiosConfig';

/**
 * *-------------------------------- *Later* -------------------------------
 * TODO: integrate the available filter by Hamza
 * TODO: redo duration filter to show duration availability of room
 * TODO: add backend validation to the filter bar and gantt-chart filter bar
 * TODO: add frontend validation to the filter bar and gantt-chart filter bar
 * TODO: unit test api using pytest
 * TODO: use component for items, booking rows and room items
 * TODO: add bed (all, bunker, bottom) filter in FilterBar.tsx
 * TODO: add pagination to the gantt chart
 * TODO: add a loading spinner when fetching data
 * TODO: add filters to url query params to persist data when user refreshes the page
 * TODO: add modal after clicking on items
 */

interface GanttChartProps {
  date?: Date;
  otaDirected: boolean;
  nextFunction?: (roomTypeResponse?: IOTACompletedRooms, date?: Date) => void;
}

const GanttChartSection = (props: GanttChartProps) => {
  const [searchParams] = useSearchParams();

  const paramsDate = searchParams.get('date');
  const paramsBedType = searchParams.get('bedType');
  const paramsDuration = searchParams.get('duration');
  const paramsQuietZone = searchParams.get('quietZone');
  const paramsBookingId = searchParams.get('bookingId');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [displayDate, setDisplayDate] = useState<Date>(
    paramsDate ? new Date(paramsDate) : props.date ? props.date : new Date()
  );
  const handleDisplayDateOnChange = (date: Date): void => {
    setDisplayDate(date);
  };

  const [displayHour, setDisplayHour] = useState<number>(0);
  const handleDisplayHourOnChange = (hour: number): void => {
    setDisplayHour(hour);
  };

  const [isAscend, setIsAscend] = useState<boolean>(true);
  const handleIsAscendOnChange = (isChecked: boolean): void => {
    setIsAscend(isChecked);
  };

  const [sortByValue, setSortByValue] = useState<'room_code' | 'availabiltiy'>(
    'room_code'
  );
  const handleSortByValueOnChange = (
    sortBy: 'room_code' | 'availabiltiy'
  ): void => {
    setSortByValue(sortBy);
  };

  const [selectedDate, setSelectedDate] = useState<Date>(
    paramsDate ? new Date(paramsDate) : props.date ? props.date : new Date()
  );
  const handleDateOnChange = (date: Date): void => {
    setSelectedDate(date);
  };

  const [duration, setDuration] = useState<number>(
    paramsDuration ? parseInt(paramsDuration) : 3
  );
  const handleDurationOnChange = (duration: number): void => {
    setDuration(duration);
  };

  const [bedType, setBedType] = useState<string>(
    paramsBedType ? paramsBedType : 'all'
  );
  const handleBedTypeOnChange = (bedType: string): void => {
    setBedType(bedType);
  };

  const [quietZone, setQuietZone] = useState<string>(
    paramsQuietZone ? paramsQuietZone : 'all'
  );

  const handleQuietZoneChange = (quietZone: string): void => {
    setQuietZone(quietZone);
  };

  const [bookingDraft, setBookingDraft] = useState<IBookingDraft[]>([]);
  const handleBookingDraft = (data: IBookingDraft): void => {
    setBookingDraft([...bookingDraft, data]);
  };

  // useEffect(() => {
  //   console.log(bookingDraft)
  // }, [bookingDraft])

  /* ------------------------------- Room Status ------------------------------ */

  const [roomStatusFilters, setRoomStatusFilters] = useState<string[]>([
    'vacant, cleaned',
    'vacant, dirty',
    'occupied, cleaned',
    'occupied, dirty',
    'maintenance',
  ]);

  const { logout } = useAuthentication();

  useEffect(() => {
    axiosInstance.get('/lot-settings/get-temp').then((response) => {
      const status = response.data.data.status;

      if (status === true) {
        logout();
      }
    });
  }, []);

  const handleRoomStatusFiltersOnChange = useCallback(
    (roomStatusToAdd: string, isChecked: boolean): void => {
      if (roomStatusToAdd === 'all') {
        isChecked &&
          setRoomStatusFilters([
            'vacant, cleaned',
            'vacant, dirty',
            'occupied, cleaned',
            'occupied, dirty',
            'maintenance',
          ]);
        !isChecked && setRoomStatusFilters([]);
        return;
      }
      if (!isChecked) {
        const dummyArr = roomStatusFilters.slice(0);
        const index = roomStatusFilters.indexOf(roomStatusToAdd);
        index > -1 && dummyArr.splice(index, 1);
        setRoomStatusFilters(dummyArr);
        return;
      }
      if (roomStatusFilters.includes(roomStatusToAdd)) return;
      if (isChecked)
        setRoomStatusFilters([...roomStatusFilters, roomStatusToAdd]);
    },
    [roomStatusFilters]
  );
  /* -------------------------------------------------------------------------- */

  /* -------------------------------- Platforms ------------------------------- */

  const [platformFilters, setPlatformFilter] = useState<string[]>([]);

  const handlePlatformFiltersOnChange = useCallback(
    (
      platformFilterToAdd: string | string[],
      isChecked: boolean,
      isAll: boolean
    ): void => {
      if (isAll && Array.isArray(platformFilterToAdd)) {
        isChecked && setPlatformFilter(platformFilterToAdd);
        !isChecked && setPlatformFilter([]);
        return;
      }
      if (!isChecked && !isAll && typeof platformFilterToAdd === 'string') {
        const dummyArr = platformFilters.slice(0);
        const index = platformFilters.indexOf(platformFilterToAdd);
        index > -1 && dummyArr.splice(index, 1);
        setPlatformFilter(dummyArr);
        return;
      }
      if (
        typeof platformFilterToAdd === 'string' &&
        platformFilters.includes(platformFilterToAdd)
      )
        return;
      if (typeof platformFilterToAdd === 'string' && isChecked)
        setPlatformFilter([...platformFilters, platformFilterToAdd]);
    },
    [platformFilters]
  );

  const [hasFetchPlatformMenuItems, setHasFetchPlatformMenuItems] =
    useState<boolean>(false);

  const [platformMenuItems, setPlatformMenuItems] = useState<
    { platformId: string; platformName: string }[]
  >([]);

  const fetchPlatformMenuItems = useCallback((): void => {
    const token = store.getState().user.accessToken;

    axios
      .get(`${BASE_API}/bookings/platforms/get-all-platforms`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse<IAxiosPlatformsResponseData>) => {
        const formattedPlatformsDatas = data.datas.map(
          ({ platform_id, platform }) => ({
            platformId: platform_id,
            platformName: platform,
          })
        );
        setPlatformMenuItems(formattedPlatformsDatas);
        const platformNames = formattedPlatformsDatas.map(
          ({ platformName }: { platformName: string }) => platformName
        );
        setPlatformFilter(platformNames);
      })
      .catch((e: Error) => {
        Notification.failed(e.message);
      })
      .finally(() => {
        setHasFetchPlatformMenuItems(true);
      });
  }, []);

  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Room Types ------------------------------- */

  const [roomTypeFilters, setRoomTypeFilters] = useState<string[]>([]);

  const handleRoomTypeFiltersOnChange = useCallback(
    (
      roomTypeToAdd: string | string[],
      isChecked: boolean,
      isAll: boolean
    ): void => {
      if (isAll && Array.isArray(roomTypeToAdd)) {
        isChecked && setRoomTypeFilters(roomTypeToAdd);
        !isChecked && setRoomTypeFilters([]);
        return;
      }
      if (!isChecked && !isAll && typeof roomTypeToAdd === 'string') {
        const dummyArr = roomTypeFilters.slice(0);
        const index = roomTypeFilters.indexOf(roomTypeToAdd);
        index > -1 && dummyArr.splice(index, 1);
        setRoomTypeFilters(dummyArr);
        return;
      }
      if (
        typeof roomTypeToAdd === 'string' &&
        roomTypeFilters.includes(roomTypeToAdd)
      )
        return;
      if (typeof roomTypeToAdd === 'string' && isChecked)
        setRoomTypeFilters([...roomTypeFilters, roomTypeToAdd]);
    },
    [roomTypeFilters]
  );

  const roomTypeParams = useMemo(
    () => searchParams.getAll('roomTypes'),
    [searchParams]
  );

  const [hasFetchRoomTypeMenuItems, setHasRoomTypeMenuItems] =
    useState<boolean>(false);

  const [roomTypeMenuItems, setRoomTypeMenuItems] = useState<
    { roomTypeId: string; roomType: string }[]
  >([]);

  const fetchRoomTypeMenuItems = useCallback((): void => {
    const token = store.getState().user.accessToken;
    axios
      .get(`${BASE_API}/rooms/type/get-all-type`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse<IAxiosRoomTypesResponseData>) => {
        const formattedRoomTypesDatas = data.data.map(
          ({ typeId, typeName }) => ({
            roomTypeId: typeId,
            roomType: typeName,
          })
        );
        setRoomTypeMenuItems(formattedRoomTypesDatas);
        const roomTypes =
          roomTypeParams.length === 0
            ? formattedRoomTypesDatas.map(
                ({ roomType }: { roomType: string }) => roomType
              )
            : roomTypeParams;
        setRoomTypeFilters(roomTypes);
      })
      .catch((e: Error) => {
        Notification.failed(e.message);
      })
      .finally(() => {
        setHasRoomTypeMenuItems(true);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* -------------------------------------------------------------------------- */

  /* ------------------------------- Room Zones ------------------------------- */

  const [roomZoneFilters, setRoomZoneFilters] = useState<string[]>([]);

  const handleRoomZoneFiltersOnChange = useCallback(
    (
      roomZoneToAdd: string | string[],
      isChecked: boolean,
      isAll: boolean
    ): void => {
      if (isAll && Array.isArray(roomZoneToAdd)) {
        isChecked && setRoomZoneFilters(roomZoneToAdd);
        !isChecked && setRoomZoneFilters([]);
        return;
      }
      if (!isChecked && !isAll && typeof roomZoneToAdd === 'string') {
        const dummyArr = roomZoneFilters.slice(0);
        const index = roomZoneFilters.indexOf(roomZoneToAdd);
        index > -1 && dummyArr.splice(index, 1);
        setRoomZoneFilters(dummyArr);
        return;
      }
      if (
        typeof roomZoneToAdd === 'string' &&
        roomZoneFilters.includes(roomZoneToAdd)
      )
        return;
      if (typeof roomZoneToAdd === 'string' && isChecked)
        setRoomZoneFilters([...roomZoneFilters, roomZoneToAdd]);
    },
    [roomZoneFilters]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const roomZonesParams = useMemo(() => searchParams.getAll('zones'), []);

  const [hasFetchRoomZoneMenuItems, setHasFetchRoomZoneMenuItems] =
    useState<boolean>(false);

  const [roomZoneMenuItems, setRoomZoneMenuItems] = useState<
    { roomZoneId: string; roomZone: string }[]
  >([]);

  const fetchRoomZoneMenuItems = useCallback((): void => {
    const token = store.getState().user.accessToken;
    axios
      .get(`${BASE_API}/rooms/zone/get-all-zone`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse<IAxiosRoomZonesResponseData>) => {
        const formattedRoomZonesDatas = data.data.map(
          ({ zoneId, zoneName }) => ({
            roomZoneId: zoneId,
            roomZone: zoneName,
          })
        );
        setRoomZoneMenuItems(formattedRoomZonesDatas);
        const roomZones =
          roomZonesParams.length === 0
            ? formattedRoomZonesDatas.map(
                ({ roomZone }: { roomZone: string }) => roomZone
              )
            : roomZonesParams;
        setRoomZoneFilters(roomZones);
      })
      .catch((e: Error) => {
        Notification.failed(e.message);
      })
      .finally(() => {
        setHasFetchRoomZoneMenuItems(true);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* -------------------------------------------------------------------------- */

  /* ---------------------------- Booking Status ------------------------------ */

  const [bookingStatusFilter, setBookingStatusFilter] = useState<string>('All');

  const handleBookingStatusFilterChange = (bookingStatus: string) => {
    setBookingStatusFilter(bookingStatus);
  };
  /* -------------------------------------------------------------------------- */

  /* ------------------------------ Booking Rows ------------------------------ */

  const [bookingRows, setBookingRows] = useState<IBookingRowInfo[]>([]);

  const fetchBookingRows = useCallback((): void => {
    const token = store.getState().user.accessToken;
    setIsLoading(true);
    axios
      .get(`${BASE_API}/bookings/get-all-upcoming-bookings`, {
        params: {
          platform_filters: platformFilters,
          room_status_filters: roomStatusFilters,
          room_type_filters: roomTypeFilters,
          room_zone_filters: roomZoneFilters,
          year: parseInt(selectedDate.toISOString().split(/[-T]/)[0]),
          month: parseInt(selectedDate.toISOString().split(/[-T]/)[1]),
          day: parseInt(selectedDate.toISOString().split(/[-T]/)[2]),
          hour: parseInt(selectedDate.toISOString().split(/[T:]/)[1]),
          minute: parseInt(selectedDate.toISOString().split(/[T:]/)[2]),
          duration,
          is_ascend: isAscend,
          sort_by: sortByValue,
          bed_type: bedType,
          quiet_zone: quietZone,
          ota_directed: props.otaDirected ? true : false,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse<IAxiosBookingRowsResponseData>) => {
        const formattedRoomBookingsDatas = data.datas.room_bookings_list.map(
          ({
            booking_periods,
            remarks,
            room_code,
            room_id,
            roomzone,
            room_type,
            status,
            color_code,
            room_type_name,
            room_type_details,
            max_pax,
          }) => ({
            bookingPeriods: booking_periods
              .filter((period) => {
                if (bookingStatusFilter === 'All') {
                  return period;
                }
                return period.current_booking_status === bookingStatusFilter;
              })
              .map(
                ({
                  room_booking_id,
                  check_in_datetime,
                  check_out_datetime,
                  current_booking_status,
                  customer_firstname,
                  customer_lastname,
                  booking_no,
                  booking_platform,
                  booking_id,
                  max_check_out_datetime,
                }) => ({
                  roomBookingId: room_booking_id,
                  checkInDatetime: check_in_datetime,
                  checkOutDatetime: check_out_datetime,
                  currentBookingStatus: current_booking_status,
                  customerFirstname: customer_firstname,
                  customerLastname: customer_lastname,
                  bookingNo: booking_no,
                  bookingPlatform: booking_platform,
                  bookingId: booking_id,
                  maxCheckOutDatetime: max_check_out_datetime,
                })
              ),
            remarks,
            roomCode: room_code,
            roomId: room_id,
            roomZone: roomzone,
            roomType: room_type,
            roomStatus: status,
            colorCode: color_code,
            roomTypeName: room_type_name,
            roomTypeDetails: room_type_details,
            maxPax: max_pax,
          })
        );
        setBookingRows(formattedRoomBookingsDatas);
      })
      .catch((e: Error) => {
        Notification.failed(e.message);
      })
      .finally(() => setIsLoading(false));
  }, [
    platformFilters,
    roomStatusFilters,
    roomTypeFilters,
    roomZoneFilters,
    selectedDate,
    duration,
    isAscend,
    sortByValue,
    bedType,
    quietZone,
    bookingStatusFilter,
  ]);

  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    fetchPlatformMenuItems();
    fetchRoomTypeMenuItems();
    fetchRoomZoneMenuItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // makes sure all platforms, room types, room zones and their respoective filter state are set before fetching booking rows
    if (
      hasFetchPlatformMenuItems &&
      hasFetchRoomTypeMenuItems &&
      hasFetchRoomZoneMenuItems
    )
      fetchBookingRows();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasFetchPlatformMenuItems,
    hasFetchRoomTypeMenuItems,
    hasFetchRoomZoneMenuItems,
    bookingStatusFilter,
  ]);

  const handleGanttChartChangeOnClick = (): void => {
    fetchBookingRows();
    // handleDisplayDateOnChange(selectedDate);
    // handleDisplayHourOnChange(selectedDate.getHours());
  };

  const navigate = useNavigate();
  const handleBookingClick = () => {
    if (bookingDraft.length < 1) {
      Notification.failed('No Rooms Selected! ');
      return;
    }
    if (!paramsBookingId) {
      //This is for walk-in booking process
      navigate('/room-list/walk-in', {
        state: { data: bookingDraft },
      });
      return;
    }
    //This is for adding room to booking process
    navigate('/room-list/walk-in', {
      state: { data: bookingDraft, bookingId: paramsBookingId },
    });
  };

  const addedRoomsPanel = () => {};

  const removeBookingByRoomId = (selectedRoomId: string) => {
    const updatedBookings = bookingDraft.filter(
      (booking) => booking.room_id !== selectedRoomId
    );
    setBookingDraft(updatedBookings);
  };

  /**
   * *----------------------------- Debugging -----------------------------
   * *console log for debugging purposes
   */
  // useEffect(() => {
  // console.log("platformFilters", platformFilters);
  // console.log("roomStatusFilters", roomStatusFilters);
  // console.log("roomTypeFilters", roomTypeFilters);
  // console.log("roomZoneFilters", roomZoneFilters);
  // console.log("selectedDate", selectedDate);
  // console.log("duration", duration);
  // console.log("isAscend", isAscend);
  // console.log("sortByValue", sortByValue);
  // }, [platformFilters, roomStatusFilters, roomTypeFilters, roomZoneFilters, selectedDate, duration, isAscend, sortByValue])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        position: 'relative',
        overflow: 'hidden',
        border: 2,
        borderColor: 'primary.main',
        width: '100%',
        margin: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
        }}
      >
        <FilterBar
          roomTypes={roomTypeMenuItems}
          roomTypeFilters={roomTypeFilters}
          handleRoomTypeFiltersOnChange={handleRoomTypeFiltersOnChange}
          date={selectedDate}
          handleDateOnChange={handleDateOnChange}
          duration={duration}
          handleDurationOnChange={handleDurationOnChange}
          roomZones={roomZoneMenuItems}
          roomZoneFilters={roomZoneFilters}
          handleRoomZoneFiltersOnChange={handleRoomZoneFiltersOnChange}
          bedType={bedType}
          handleBedTypeOnChange={handleBedTypeOnChange}
          quietZone={quietZone}
          handleQuietZoneOnChange={handleQuietZoneChange}
          handleGanttChartChangeOnClick={handleGanttChartChangeOnClick}
          bookingId={paramsBookingId ? paramsBookingId : ''}
          otaDirected={props.otaDirected}
        />
      </Box>
      <GanttChartFilterBar
        platforms={platformMenuItems}
        platformFilters={platformFilters}
        handlePlatformFiltersOnChange={handlePlatformFiltersOnChange}
        roomStatusFilters={roomStatusFilters}
        handleRoomStatusFiltersOnChange={handleRoomStatusFiltersOnChange}
        isAscend={isAscend}
        handleIsAscendOnChange={handleIsAscendOnChange}
        sortByValue={sortByValue}
        handleSortByValueOnChange={handleSortByValueOnChange}
        handleGanttChartChangeOnClick={handleGanttChartChangeOnClick}
        handleBookingStatusFilterChange={handleBookingStatusFilterChange}
      />
      <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
        <Stack
          justifyContent={'center'}
          // minWidth={"86.5em"}
          width={'90%'}
          overflow={'auto'}
        >
          <GanttChart
            ganttChartInfos={bookingRows}
            displayDate={displayDate}
            displayHour={displayHour}
            duration={duration}
            selectedDate={selectedDate}
            handleBookingDraft={handleBookingDraft}
            bookingDraft={bookingDraft}
            removeBookingByRoomId={removeBookingByRoomId}
            otaDirected={props.otaDirected}
            isLoading={isLoading}
          />
          {!props.otaDirected && <AddedBooking bookingDraft={bookingDraft} />}
        </Stack>
        <Stack sx={{ backgroundColor: '#232323', flex: '1', width: '10%' }}>
          <ButtonBase
            sx={{
              flex: '1',
              width: '100%',
              borderRight: 0,
              border: 1,
              borderColor: theme.palette.primary.main,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant={'h3'} textAlign={'start'}>
              Open
            </Typography>
            <Typography variant={'h3'} textAlign={'start'}>
              Map View
            </Typography>
          </ButtonBase>
          <ButtonBase
            onClick={() =>
              props.nextFunction && props.date
                ? props.nextFunction(undefined, props.date)
                : handleBookingClick()
            }
            sx={{
              flex: '2',
              width: '100%',
              borderTop: 0,
              borderRight: 0,
              border: 1,
              borderColor: theme.palette.primary.main,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <EastIcon fontSize={'large'} />
            <Typography variant={'h3'}>Next</Typography>
          </ButtonBase>
        </Stack>
      </Stack>
    </Box>
  );
};

export default GanttChartSection;
