import {
  AccessTime,
  Add,
  Article,
  Edit,
  Logout,
  Payments,
  Person,
  Sensors,
  Warning,
} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  IconButtonProps,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TypographyProps,
  styled,
  tableCellClasses,
  useTheme,
} from '@mui/material';
import format from 'date-fns/format';
import {
  Fragment,
  MouseEvent,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import {
  Location,
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import CheckRoomDirty from '../../components/booking/CheckRoomDirty';
import SuccessCheckInModal from '../../components/booking/SuccessCheckInModal';
import PageTitle from '../../components/global/PageTitle';
import Status from '../../components/global/status/Status';
import AddExtraGuest from '../../components/guest-folio/AddExtraGuestModal';
import AddLockerModal from '../../components/guest-folio/AddLockerModal';
import AssignGuestModal from '../../components/guest-folio/AssignGuestModal';
import BookingTransferModal from '../../components/guest-folio/BookingTransferModal';
import EditCheckInModal from '../../components/guest-folio/EditActualCheckInModal';
import EditGuestProfile from '../../components/guest-folio/EditGuestProfile';
import GuestTransferModal from '../../components/guest-folio/GuestTransferModal';
import LockerExtendModal from '../../components/guest-folio/LockerExtendModal';
import LockerTransferModal from '../../components/guest-folio/LockerTransferModal';
import RebindLockerModal from '../../components/guest-folio/RebindLockerModal';
import ScanCardModal from '../../components/guest-folio/ScanCardModal';
import DocumentModal from '../../components/guests/DocumentModal';
import axiosInstance from '../../constants/axiosConfig';
import {
  bookingStatusEnum,
  transactionCategoryEnum,
} from '../../constants/enums';
import { IBookingDraft } from '../../models/bookings/Booking';
import { store } from '../../redux/store';
import { getDuration } from '../../utils/functions';
import Notification from '../../utils/notificationConfig';
import {
  BookingResult,
  LockerBooking,
  RoomBooking,
} from '../booking/interface';

interface DTOPaymentOverview {
  paidAmount: number;
  pendingAmount: number;
  status: string;
  overstayTransactionCreated: boolean;
  overstayTransactionPaid: boolean;
  transactionDatetime: string;
  paymentType: string;
}

interface GuestFolioDetailProps extends TypographyProps {
  title: string;
  onClick?: VoidFunction;
  disabledErrors?: { disabled: boolean; error: string }[];
  buttonIcon?: ReactElement;
  buttonText?: string;
  children: ReactNode;
}

interface ISelectedRoom {
  roomBookings: IBookingDraft | null;
  roomTypeId: string;
  roomBookingId: string;
}

interface ISelectedLocker {
  lockerBookings: LockerBooking | null;
  lockerCode: string;
  lockerBookingId: string;
}

/**
 * A function to check if the time difference is more than time difference minutes
 * @param timeString
 * @param delta - time difference in seconds
 * @param timeDifference
 * @returns
 */
function CheckTimeDIfference(timeString: string, delta?: number) {
  const inputTime = new Date(timeString);

  const currentTime = new Date();

  const timeDifference = currentTime.getTime() - inputTime.getTime();
  if (delta) {
    return Math.abs(timeDifference) >= delta * 1000;
  }

  return timeDifference >= 30 * 60 * 1000;
}

interface CustomIconButtonProps extends IconButtonProps {
  disabledErrors?: { disabled: boolean; error: string }[];
  children: ReactNode;
  bookingStatus: string;
}

const CustomIconButton = ({
  disabledErrors = [],
  bookingStatus,
  sx,
  ...props
}: CustomIconButtonProps) => {
  disabledErrors.unshift({
    disabled: bookingStatus.includes('Transfer to'),
    error:
      'Booking has been transferred, please make changes in the new transferred booking instead',
  });
  const error = disabledErrors.find((error) => error.disabled);

  return (
    <IconButton
      onClick={
        error ? (event) => Notification.warning(error.error) : props.onClick
      }
      sx={{
        ...sx,
        ...(error && {
          borderColor: 'grey',
          color: 'grey',
          '& .MuiTypography-root': {
            color: 'grey',
          },
        }),
      }}
      {...props}
    />
  );
};

export default function GuestFolioModified() {
  const token = store.getState().user.accessToken;
  const { bookingId } = useParams();

  const { state, pathname }: Location = useLocation();
  const [isDirected, setIsDirected] = useState<boolean>(
    state ? state.isDirected : false
  );
  window.history.replaceState(null, '', pathname);

  const navigate = useNavigate();

  const theme = useTheme();

  const [guestFolioData, setGuestFolioData] = useState<BookingResult>();
  const [bookingStatus, setBookingStatus] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [adminUsername, setAdminUsername] = useState('');
  const [adminPassword, setAdminPassword] = useState('');

  const [anchorEl, setAnchorEl] = useState<{
    [key: string]: HTMLElement | null;
  }>({});
  const [anchorElLocker, setAnchorElLocker] = useState<{
    [key: string]: HTMLElement | null;
  }>({});
  const [remarksValue, setRemarksValue] = useState<string | undefined>('');
  const [isRemarksDisabled, setIsRemarksDisabled] = useState(true);
  const [isExtendModalOpen, setIsExtendModalOpen] = useState(false);
  const [, setIsRebindModalOpen] = useState(false);
  const [isLockerTransferModalOpen, setIsLockerTransferModalOpen] =
    useState(false);
  const [isAddLockerModalOpen, setIsAddLockerModalOpen] = useState(false);
  const [isScanCardModalOpen, setIsScanCardModalOpen] = useState(false);
  const [, setIsAfterScanCardModalOpen] = useState(false);
  const [isRebindLockerModalOpen, setIsRebindLockerModalOpen] = useState(false);

  const [openCheckInModal, setOpenCheckInModal] = useState<boolean>(false);

  const [selectedRoom, setSelectedRoom] = useState<ISelectedRoom>({
    roomBookings: null,
    roomTypeId: '',
    roomBookingId: '',
  });

  useEffect(() => {
    const status = guestFolioData?.bookingStatus
      .filter((status) => status.isLatest)
      .slice(-1)?.[0].bookingStatus;
    setBookingStatus(status || '');

    setRemarksValue(guestFolioData?.details);

    axiosInstance
      .get(`/bookings/v1/get-payment-info/${bookingId}/`)
      .then(
        ({ status, data }) =>
          status === 200 &&
          setPaymentInformationOverview(data as DTOPaymentOverview)
      );

    if (guestFolioData) {
      const isWithin10Minutes = !CheckTimeDIfference(
        guestFolioData.bookingStatus[0].checkInDatetime,
        10 * 60
      );

      if (
        isWithin10Minutes &&
        isDirected &&
        guestFolioData.roomBookings.length > 1 &&
        guestFolioData.lockerBookings.length < 0
      ) {
        setIsScanCardModalOpen(isWithin10Minutes || isDirected);
        setIsAddLockerModalOpen(isWithin10Minutes || isDirected);
        setIsDirected(false);
      }

      const fromOTA = guestFolioData.platform.platform
        .toLowerCase()
        .includes('ota');
      const missingCustomerStaying = guestFolioData.customerStaying === null;
      fromOTA && missingCustomerStaying && handleOpenAssignGuestModal();

      //Close Add Shower Modal if Shower Cards > 0
      guestFolioData.showerBookings.length > 0 && handleCloseScanCardModal();
    }
  }, [guestFolioData]);

  const [paymentInformation, setPaymentInformationOverview] =
    useState<DTOPaymentOverview>({
      paidAmount: 0,
      pendingAmount: 0,
      status: 'Loading...',
      overstayTransactionCreated: false,
      overstayTransactionPaid: false,
      transactionDatetime: '',
      paymentType: '',
    });

  const [selectedLocker, setSelectedLocker] = useState<ISelectedLocker>({
    lockerBookings: null,
    lockerCode: '',
    lockerBookingId: '',
  });

  const convertToRoombooking = (roomBooking: RoomBooking): IBookingDraft => ({
    check_in: roomBooking.derivedCheckInDatetime!,
    check_out: roomBooking.derivedCheckOutDatetime!,
    color_code: roomBooking.roomTypeColorTags!,
    duration: 1,
    member_condition: '',
    member_id: '',
    member_tier: '',
    person_in_charge_ic: '',
    person_in_charge_id: roomBooking.personInChargeId!,
    person_in_charge_name: roomBooking.personInCharge,
    person_in_charge_country: '',
    room_code: roomBooking.roomCode,
    room_id: roomBooking.room,
    room_type_details: roomBooking.roomTypeDetails!,
    room_type_name: roomBooking.roomType,
    room_zone: roomBooking.roomZone,
    rentention_count: 0,
  });

  const handleOpenCheckinModal = () => setOpenCheckInModal(true);

  const handleCloseCheckinModal = () => {
    setOpenCheckInModal(false);
    window.location.reload();
  };

  const handleSelectedRoomActionOpen = (row: RoomBooking) =>
    setSelectedRoom({
      roomBookingId: row.roomBookingId,
      roomBookings: convertToRoombooking(row),
      roomTypeId: row.roomTypeId!,
    });

  const handleCloseTransferModal = () =>
    setSelectedRoom({
      roomBookings: null,
      roomTypeId: '',
      roomBookingId: '',
    });

  const handleRemarksOnChange = (e: any) => setRemarksValue(e.target.value);

  const handleDoubleClick = () => setIsRemarksDisabled(!isRemarksDisabled);

  const handleOpenExtendModal = (row: LockerBooking) => {
    setIsExtendModalOpen(true);
    setSelectedLocker({
      lockerBookings: row,
      lockerCode: row.locker.code,
      lockerBookingId: row.lockerBookingId,
    });
  };

  const handleCloseExtendModal = () => {
    setIsExtendModalOpen(false);
    setSelectedLocker({
      lockerBookings: null,
      lockerCode: '',
      lockerBookingId: '',
    });
  };

  const handleOpenLockerTransferModal = (row: LockerBooking) => {
    setIsLockerTransferModalOpen(true);
    setSelectedLocker({
      lockerBookings: row,
      lockerCode: row.locker.code,
      lockerBookingId: row.lockerBookingId,
    });
  };

  const handleCloseLockerTransferModal = () => {
    setSelectedLocker({
      lockerBookings: null,
      lockerCode: '',
      lockerBookingId: '',
    });
    setIsLockerTransferModalOpen(false);
  };

  const handleOpenRebindModal = () => setIsRebindModalOpen(true);

  const handleCloseRebindModal = () => setIsRebindModalOpen(false);

  let scanTimeout: any = null;

  const [openCancelBooking, setOpenCancelBooking] = useState<boolean>(false);
  const [openCheckoutBooking, setOpenCheckoutBooking] =
    useState<boolean>(false);
  const [openCheckoutPopup, setOpenCheckoutPopup] = useState<boolean>(false);
  const [openGuestBooking, setOpenGuestBooking] = useState<boolean>(false);
  const [openEditGuestProfile, setOpenEditGuestProfile] =
    useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [isRoomDirty, setIsRoomDirty] = useState<boolean>(false);

  const [openDocumentModal, setOpenDocumentModal] = useState<boolean>(false);

  const [openBookingTransferModal, setOpenBookingTransferModal] =
    useState<boolean>(false);

  const [openEditCheckInModal, setOpenEditCheckInModal] =
    useState<boolean>(false);
  const [openAssignGuestModal, setOpenAssignGuestModal] =
    useState<boolean>(false);

  const handleCancelClick = () => handleCancelBooking(); // setOpenCancelBooking(true);

  const handleCheckoutClick = () => {
    if (!guestFolioData) {
    } else if (
      (guestFolioData.showerBookings.length > 0 ||
        guestFolioData.lockerBookings.length > 0) &&
      guestFolioData.roomBookings.length < 1
    ) {
      handleCheckoutBooking();
    } else if (
      bookingStatus === 'Overstayed' &&
      paymentInformation.overstayTransactionCreated === false &&
      guestFolioData.roomBookings.length > 0
    ) {
      handleCheckoutBookingV2();
    } else if (
      bookingStatus === 'Overstayed' &&
      paymentInformation.overstayTransactionCreated === true &&
      paymentInformation.overstayTransactionPaid === false
    ) {
      setOpenCheckoutPopup(true);
    } else if (paymentInformation.pendingAmount.toString() !== '0.00') {
      setOpenCheckoutPopup(true);
    } else if (
      (bookingStatus === 'Overstayed' || bookingStatus === 'Check In') &&
      (paymentInformation.overstayTransactionPaid === true ||
        paymentInformation.status === 'Paid')
    ) {
      handleCheckoutBooking();
    } else {
      Notification.warning(
        'Some conditions are met for checkout. Please check if there are outstanding fee.'
      );
    }
  };

  const handleGuestClick = () => setOpenGuestBooking(true);

  const handleGuestClose = () => setOpenGuestBooking(false);

  const handleEditGuestProfileClick = () => setOpenEditGuestProfile(true);

  const handleEditGuestProfileClose = () => setOpenEditGuestProfile(false);

  const handleDocumentModalClick = () => setOpenDocumentModal(true);

  const handleDocumentModalClose = () => setOpenDocumentModal(false);

  const handleOpenAddLockerModal = () => setIsAddLockerModalOpen(true);

  const handleCloseAddLockerModal = () => setIsAddLockerModalOpen(false);

  const handleOpenScanCardModal = () => {
    setIsScanCardModalOpen(true);
    setIsAfterScanCardModalOpen(false);
  };

  const handleCloseScanCardModal = () => setIsScanCardModalOpen(false);

  const handleOpenAfterScanCardModal = () => {
    setIsAfterScanCardModalOpen(true);
    setIsScanCardModalOpen(false);
  };

  const handleOpenRebindLockerModal = (row: LockerBooking) => {
    setIsRebindLockerModalOpen(true);
    setSelectedLocker({
      lockerBookings: row,
      lockerCode: row.locker.code,
      lockerBookingId: row.lockerBookingId,
    });
  };

  const handleCancelLockerBooking = (row: LockerBooking) => {
    const apiUrl = `/bookings/v1/locker/cancel-locker-booking/${row.lockerBookingId}/`;

    axiosInstance
      .put(
        apiUrl,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        Notification.success(res.data.message);
      })
      .catch((res) => {
        Notification.failed(res.response.data.message);
      });
  };

  const handleCancelClose = () => setOpenCancelBooking(false);

  const handleCheckoutClose = () => setOpenCheckoutBooking(false);

  const handleCloseRebindLockerModal = () => {
    setIsRebindLockerModalOpen(false);
    setSelectedLocker({
      lockerBookings: null,
      lockerCode: '',
      lockerBookingId: '',
    });
  };

  const handleOpenBookingTransferModal = () =>
    setOpenBookingTransferModal(true);

  const handleCloseBookingTransferModal = () =>
    setOpenBookingTransferModal(false);

  const handleOpenEditCheckInModal = () => setOpenEditCheckInModal(true);

  const handleCloseEditCheckInModal = () => setOpenEditCheckInModal(false);

  const handleOpenAssignGuestModal = () => setOpenAssignGuestModal(true);

  const handleCloseAssignGuestModal = () => setOpenAssignGuestModal(false);

  const handleSaveRemarksButton = () =>
    axiosInstance
      .put(
        `/bookings/update-booking-remarks/`,
        {
          booking_id: bookingId,
          new_remarks: remarksValue,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // Handle the successful response here
        Notification.success('Remarks Succesfully updated');
      })
      .catch((error) => {
        // Handle any errors here
        Notification.failed('Something is wrong, try again later');
      })
      .finally(() => setIsRemarksDisabled(true));

  const handleMenuClose = (roomBookingId: string) =>
    setAnchorEl({
      ...anchorEl,
      [roomBookingId]: null,
    });

  const handleLockerMenuClose = (lockerBookingId: string) =>
    setAnchorElLocker({
      ...anchorElLocker,
      [lockerBookingId]: null,
    });

  const handleRoomMenuOpen = (
    event: MouseEvent<HTMLElement>,
    row: RoomBooking
  ) =>
    setAnchorEl({
      ...anchorEl,
      [row.roomBookingId]: event.currentTarget,
    });

  const handleLockerMenuOpen = (
    event: MouseEvent<HTMLElement>,
    row: LockerBooking
  ) =>
    setAnchorElLocker({
      ...anchorElLocker,
      [row.lockerBookingId]: event.currentTarget,
    });

  const handleFetchGuestFolio = () => {
    const apiUrl = `/bookings/guest-portofolio/${bookingId}`;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    setIsLoading(true);

    axiosInstance
      .get(apiUrl, config)
      .then((response) => {
        const customerBookedData = response.data.customerFirstname
          ? response.data.customerFirstname +
            ' ' +
            response.data.customerLastname
          : response.data.customerBooked;
        setGuestFolioData({
          ...response.data,
          customerBooked: customerBookedData,
        });
      })
      .catch((res) => {
        Notification.failed('Something Wrong Please try again Later');
        navigate(-1);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleFetchGuestFolio();
  }, [
    token,
    bookingId,
    isLockerTransferModalOpen,
    isExtendModalOpen,
    isRebindLockerModalOpen,
    isAddLockerModalOpen,
    openEditGuestProfile,
  ]);

  const handlecheckRoomDirty = () => {
    if (!guestFolioData) {
    } else if (guestFolioData?.roomBookings.length > 0) {
      guestFolioData?.roomBookings.forEach((room: RoomBooking) => {
        const roomStatus = room.roomStatus;
        if (roomStatus === 'vacant, dirty') {
          setIsRoomDirty(true);
        } else {
          handleCheckInBooking();
        }
      });
    } else {
      handleCheckInBooking();
    }
  };

  const handleCheckRoomDirtyClose = () => setIsRoomDirty(false);

  const handleCheckInBooking = () => {
    setIsLoading(true);
    const apiUrl = `/bookings/check-in/${bookingId}/`;
    const config = { headers: { Authorization: `Bearer ${token}` } };
    axiosInstance
      .put(apiUrl, {}, config)
      .then((response) => {
        Notification.success(response.data.message);
        handleOpenCheckinModal();
        setIsRoomDirty(false);
      })
      .catch((error) => {
        Notification.failed(error.response.data.message);
      })
      .finally(() => setIsLoading(false));
  };

  // const derivedCheckInDatetime =
  //   guestFolioData?.roomBookings[0].derivedCheckInDatetime;
  // const derivedCheckOutDatetime =
  //   guestFolioData?.roomBookings[0].derivedCheckOutDatetime;

  const handleCancelBooking = () => {
    const payload = {
      booking_id: guestFolioData?.bookingId,
      actual_checkout_datetime: new Date(),
    };
    const apiUrl = `/bookings/cancel/`;
    const config = { headers: { Authorization: `Bearer ${token}` } };

    setIsLoading(true);

    axiosInstance
      .put(apiUrl, payload, config)
      .then((response) => {
        Notification.success('Booking cancelled successfully');
        handleCancelClose();
        setDisableButton(true);
      })
      .catch((error) => {
        Notification.failed(error.response.data.message);
      })
      .finally(() => setIsLoading(false));
  };

  const handleCheckoutBooking = () => {
    const payload = {
      booking_id: guestFolioData?.bookingId,
      actual_checkout_datetime: new Date(),
    };
    const apiUrl = `/bookings/check-out/`;
    const config = { headers: { Authorization: `Bearer ${token}` } };

    setIsLoading(true);

    axiosInstance
      .put(apiUrl, payload, config)
      .then((response) => {
        Notification.success('Booking checked out successfully');
        handleCheckoutClose();
        setDisableButton(true);
      })
      .catch((error) => {
        console.log(error.response.data);
        Notification.failed(error.response.data.message);
      })
      .finally(() => setIsLoading(false));
  };

  const handleCheckoutBookingV2 = () => {
    setDisableButton(true);
    const payload = {
      bookingId: guestFolioData?.bookingId,
    };
    const apiUrl = `/transaction/overstay-poa/`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    setIsLoading(true);

    axiosInstance
      .post(apiUrl, payload, config)
      .then((response) => {
        Notification.warning(
          'Overstay fee calculated, please pay before check out'
        );
        handleCheckoutClose();
      })
      .catch((error) => {
        Notification.failed(error.response.data.message);
        setDisableButton(false);
      })
      .finally(() => setIsLoading(false));
  };

  const handleCheckAdmin = (operation: string) => {
    const requestData = {
      admin_name: adminUsername,
      admin_password: adminPassword,
    };

    setIsLoading(true);

    axiosInstance
      .post(`/accounts/check-admin-password/`, requestData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.status === 'success') {
          Notification.success('Admin password verified successfully.');
          if (operation === 'Cancel') {
            handleCancelBooking();
          }
          if (operation === 'Check Out') {
            handleCheckoutBooking();
          }
        } else {
          Notification.failed(
            'Error in verifying admin password. Please try again later.'
          );
        }
      })
      .catch((error) => {
        Notification.failed(
          'Error in verifying admin password. Please try again later. lalal'
        );
      })
      .finally(() => setIsLoading(false));
  };

  const handleScanAccessCard = (value?: string) => {
    const formData = {
      item: value
        ? transactionCategoryEnum.roomSales
        : transactionCategoryEnum.showerSales,
      item_to_scan_id: value ? value : bookingId,
      cashier_terminal_name: localStorage.getItem('defaultCashier'),
    };
    setIsLoading(true);
    axiosInstance
      .post('/accesscard/create/', formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        Notification.success('Scan Card Issued');
      })
      .catch((error) => {
        Notification.failed('failed');
      })
      .finally(() => setIsLoading(false));
  };

  /*
  [DEPRECATED] 
  REASON : NEW API FOR PAYMENT OVERVIEW IS CREATED WITH MORE SIMPLIFIED VERSION
  const [transactions, setTransactions] = useState<IPayments[]>([]);

  useEffect(() => {
    const fetchTransactions = () => {
      axiosInstance
        .get(`/transaction/get-by-booking-id/${bookingId}/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.length > 0) {
              const dataTransaction = res.data.map((data: any) => ({
                transactionId: data.transactionId,
                invoiceNo: data.invoiceNo,
                transactionStatus: data.transactionStatus,
                sum: parseFloat(data.sum),
                amountPaid: data.debitAmount,
                status: data.transactionStatus,
                paymentType: data.paymentType,
                customer: data.customer,
                transactionDatetime: data.transactionDatetime,
                creditAmount: data.creditAmount,
                debitAmount: data.debitAmount,
                items: JSON.parse(data.paymentDetails),
              }));
              setTransactions(dataTransaction);
            }
          }
        });
    };
    

    fetchTransactions();
  }, [bookingId]);
  */

  const handleReturnPaymentOverview = () =>
    guestFolioData && (
      <>
        <Typography variant="h4">
          MYR{' '}
          {paymentInformation.status === 'Paid' ? (
            <>
              {paymentInformation.paidAmount.toString()} |{' '}
              {paymentInformation.paymentType}{' '}
              <Typography>
                {}
                {format(
                  new Date(paymentInformation.transactionDatetime),
                  'yyyy-MM-dd HH:mm:ss'
                )}
              </Typography>
            </>
          ) : (
            <>
              {paymentInformation.pendingAmount.toString()}{' '}
              {paymentInformation.paymentType}{' '}
            </>
          )}
        </Typography>
        <Typography variant="h4">{paymentInformation.status}</Typography>
      </>
    );

  interface CustomButtonProps extends ButtonProps {
    disabledErrors?: { disabled: boolean; error: string }[];
    children: ReactNode;
  }

  const CustomButton = ({
    onClick,
    disabledErrors = [],
    sx,
    ...props
  }: CustomButtonProps) => {
    disabledErrors.unshift({
      disabled: bookingStatus.includes('Transfer to'),
      error:
        'Booking has been transferred, please make changes in the new transferred booking instead',
    });
    const error = disabledErrors.find((error) => error.disabled)?.error;

    return (
      <Button
        onClick={error ? () => Notification.warning(error) : onClick}
        sx={{
          ...sx,
          ...(error && {
            borderColor: 'grey',
            color: 'grey',
            '& .MuiTypography-root': {
              color: 'grey',
            },
          }),
        }}
        {...props}
      />
    );
  };

  const GuestFolioDetail = ({
    title,
    onClick,
    disabledErrors = [],
    buttonIcon,
    buttonText,
    children,
  }: GuestFolioDetailProps) => (
    <Stack height="50px" spacing={1}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginRight={3}
      >
        <Typography variant="h4">{title}</Typography>

        {onClick && (
          <CustomButton
            onClick={onClick}
            disabledErrors={disabledErrors}
            sx={{
              mr: 1,
              px: 1,
              py: 0,
              border: 2,
            }}
          >
            {buttonIcon}

            <Typography variant="body1" fontWeight={700}>
              {buttonText}
            </Typography>
          </CustomButton>
        )}
      </Stack>

      {children}
    </Stack>
  );

  return (
    <>
      <PageTitle title="Guest Profile" />
      <Box
        height={'300px'}
        marginX={4}
        border={2}
        borderColor={theme.palette.primary.main}
      >
        <Stack
          direction={'row'}
          width={'100%'}
          height={'100%'}
          justifyContent={'space-between'}
        >
          <Box width={'65%'} height={'100%'}>
            <Grid container width={'100%'} margin={3} rowGap={5}>
              <Grid item xs={4}>
                <GuestFolioDetail
                  title="Booking ID"
                  onClick={handleOpenBookingTransferModal}
                  buttonIcon={
                    <BorderColorIcon
                      sx={{ fontSize: '1.1rem', marginRight: 1 }}
                    />
                  }
                  buttonText="Transfer"
                >
                  <Typography variant="h3">
                    {guestFolioData?.bookingNo}
                  </Typography>
                </GuestFolioDetail>
              </Grid>

              <Grid item xs={4}>
                <GuestFolioDetail
                  title="Guest Name"
                  onClick={handleEditGuestProfileClick}
                  disabledErrors={[
                    {
                      disabled: !guestFolioData?.customerStaying,
                      error: 'Please scan guest IC or passport to proceed',
                    },
                  ]}
                  buttonIcon={
                    <BorderColorIcon
                      sx={{ fontSize: '0.9rem', marginRight: 1 }}
                    />
                  }
                  buttonText="Edit Info"
                >
                  <Stack direction={'row'} spacing={2}>
                    <Person sx={{ fontSize: '30px' }} />
                    <Typography variant="h3">
                      {guestFolioData?.customerBooked}
                    </Typography>
                  </Stack>
                </GuestFolioDetail>
              </Grid>

              <Grid item xs={4}>
                <GuestFolioDetail title="Duration">
                  <Typography variant="h3">
                    {guestFolioData &&
                      guestFolioData.bookingStatus.length > 0 &&
                      getDuration(guestFolioData.bookingStatus) + ' Hrs'}
                  </Typography>
                </GuestFolioDetail>
              </Grid>

              <Grid item xs={4}>
                <GuestFolioDetail title="OTA Ref No.">
                  <Typography variant="h3">
                    {guestFolioData?.otaCode || '-'}
                  </Typography>
                </GuestFolioDetail>
              </Grid>

              <Grid item xs={4}>
                <GuestFolioDetail
                  title="Total Pax."
                  onClick={handleGuestClick}
                  buttonIcon={<AddIcon sx={{ mr: 1, fontSize: '1.1rem' }} />}
                  buttonText="Add Guest"
                >
                  <Typography variant="h3">
                    {guestFolioData &&
                      guestFolioData.adultPax + guestFolioData.childPax}
                  </Typography>
                </GuestFolioDetail>
              </Grid>

              <Grid item xs={4}>
                <GuestFolioDetail
                  title="Actual Check-In"
                  onClick={handleOpenEditCheckInModal}
                  disabledErrors={[
                    {
                      disabled:
                        bookingStatus === bookingStatusEnum.checkOut ||
                        bookingStatus === bookingStatusEnum.checkIn ||
                        bookingStatus === bookingStatusEnum.overstay,
                      error:
                        'Cannot change check in datetime after guest has been checked in',
                    },
                  ]}
                  buttonIcon={
                    <BorderColorIcon sx={{ mr: 1, fontSize: '1.1rem' }} />
                  }
                  buttonText="Edit"
                >
                  <Typography variant="h3">
                    {/* {guestFolioData?.roomBookings[0].derivedCheckInDatetime
                      ? format(
                          new Date(
                            guestFolioData.roomBookings[0].derivedCheckInDatetime
                          ),
                          "dd/MM/yyyy HH:mm"
                        )
                      : "-"} */}
                    {guestFolioData &&
                    guestFolioData.roomBookings.length > 0 &&
                    guestFolioData.roomBookings[0].derivedCheckInDatetime
                      ? format(
                          new Date(
                            guestFolioData?.roomBookings[0].derivedCheckInDatetime
                          ),
                          'dd/MM/yyyy HH:mm'
                        )
                      : guestFolioData &&
                        guestFolioData.bookingStatus.length > 0
                      ? format(
                          new Date(
                            guestFolioData?.bookingStatus.filter(
                              (status) => status.isLatest === true
                            )[0].checkInDatetime
                          ),
                          'dd/MM/yyyy HH:mm'
                        )
                      : '-'}
                  </Typography>
                </GuestFolioDetail>
              </Grid>

              <Grid item xs={4}>
                <GuestFolioDetail title="Booking Source">
                  <Typography variant="h3">
                    {guestFolioData?.platform.platform}
                  </Typography>
                </GuestFolioDetail>
              </Grid>

              <Grid item xs={4}>
                <GuestFolioDetail title="Status">
                  <Status status={guestFolioData && bookingStatus} />
                </GuestFolioDetail>
              </Grid>

              <Grid item xs={4}>
                <GuestFolioDetail title="Actual Check-Out">
                  <Typography variant="h3">
                    {/* {guestFolioData?.roomBookings[0].derivedCheckOutDatetime
                      ? format(
                          new Date(
                            guestFolioData.roomBookings[0].derivedCheckOutDatetime
                          ),
                          "dd/MM/yyyy HH:mm"
                        )
                      : "-"} */}
                    {guestFolioData &&
                    guestFolioData.roomBookings.length > 0 &&
                    guestFolioData.roomBookings[0].derivedCheckOutDatetime
                      ? format(
                          new Date(
                            guestFolioData?.roomBookings[0].derivedCheckOutDatetime
                          ),
                          'dd/MM/yyyy HH:mm'
                        )
                      : guestFolioData &&
                        guestFolioData.bookingStatus.length > 0
                      ? format(
                          new Date(
                            guestFolioData?.bookingStatus.filter(
                              (status) => status.isLatest === true
                            )[0].checkOutDatetime
                          ),
                          'dd/MM/yyyy HH:mm'
                        )
                      : '-'}
                  </Typography>
                </GuestFolioDetail>
              </Grid>
            </Grid>
          </Box>
          <Box
            width={'30%'}
            height={'100%'}
            paddingX={6}
            paddingY={2}
            sx={{ background: '#141414' }}
          >
            <Typography
              variant={'h3'}
              fontWeight={500}
              color={'white'}
              marginBottom={1}
            >
              Remarks
            </Typography>
            <TextField
              multiline
              rows={8}
              fullWidth
              value={remarksValue}
              variant={'standard'}
              onChange={(e) => handleRemarksOnChange(e)}
              InputProps={{
                readOnly: isRemarksDisabled,
                sx: {
                  '&.MuiInputBase-input.Mui-disabled': {
                    opacity: 1,
                    WebkitTextFillColor: 'rgb(220, 220, 220)',
                  },
                },
              }}
            />
            {isRemarksDisabled ? (
              <Stack display="flex" direction="row" justifyContent="end">
                <CustomButton
                  onClick={handleDoubleClick}
                  variant="outlined"
                  sx={{
                    width: '120px',
                    marginRight: 2,
                  }}
                >
                  Edit
                </CustomButton>
              </Stack>
            ) : (
              <Stack
                display={'flex'}
                direction={'row'}
                justifyContent={'space-between'}
              >
                <CustomButton
                  onClick={handleDoubleClick}
                  variant="outlined"
                  sx={{
                    width: '120px',
                    marginRight: 2,
                    border: 0,
                    color: 'white',
                    '&:hover': {
                      border: 0,
                      opacity: 0.5,
                    },
                  }}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  onClick={handleSaveRemarksButton}
                  variant="outlined"
                  sx={{ width: '120px', marginRight: 2 }}
                >
                  Save
                </CustomButton>
              </Stack>
            )}
          </Box>

          {/* <Box
            width={"20%"}
            height={"100%"}
            borderRight={2}
            borderColor={theme.palette.primary.main}
          >
            {bookingStatus === "Check In" ? (
              <Button
                variant="outlined"
                onClick={handleCheckoutClick}
                sx={{ height: "33.3%", width: "100%" }}
                disabled={isLoading || disableButton}
              >
                <Stack
                  direction={"row"}
                  width={"100%"}
                  height={"100%"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  marginX={4}
                >
                  <Logout
                    sx={{
                      fontSize: "50px",
                      color: disableButton ? "gray" : "white",
                    }}
                  />
                  <Typography
                    color={disableButton ? "gray" : "white"}
                    variant="h2"
                  >
                    Check Out
                  </Typography>
                </Stack>
              </Button>
            ) : (
              <Button
                variant="outlined"
                onClick={handlecheckRoomDirty}
                sx={{ height: "33.3%", width: "100%" }}
                disabled={
                  isLoading || bookingStatus !== "Confirm Booking"
                }
              >
                <Stack
                  direction={"row"}
                  width={"100%"}
                  height={"100%"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  marginX={4}
                >
                  <Logout
                    sx={{
                      fontSize: "50px",
                      color:
                        disableButton ||
                        bookingStatus !== "Confirm Booking"
                          ? "gray"
                          : "white",
                    }}
                  />
                  <Typography
                    color={
                      disableButton ||
                      bookingStatus !== "Confirm Booking"
                        ? "gray"
                        : "white"
                    }
                    variant="h2"
                  >
                    Check In
                  </Typography>
                </Stack>
              </Button>
            )}
            <Button
              onClick={handleCancelClick}
              variant="outlined"
              disabled={
                isLoading ||
                disableButton ||
                bookingStatus !== "Booked"
              }
              sx={{ height: "33.3%", width: "100%" }}
            >
              <Stack
                direction={"row"}
                width={"100%"}
                height={"100%"}
                alignItems={"center"}
                justifyContent={"space-between"}
                marginX={4}
              >
                <Warning
                  sx={{
                    fontSize: "50px",
                    color:
                      disableButton || bookingStatus !== "Booked"
                        ? "gray"
                        : "white",
                  }}
                />
                <Typography
                  color={
                    disableButton || bookingStatus !== "Booked"
                      ? "gray"
                      : "white"
                  }
                  variant="h2"
                >
                  Cancel
                </Typography>
              </Stack>
            </Button>
            <Button
              onClick={handleGuestClick}
              variant="outlined"
              disabled={isLoading}
              sx={{ height: "33.3%", width: "100%" }}
            >
              <Stack
                direction={"row"}
                width={"100%"}
                height={"100%"}
                alignItems={"center"}
                justifyContent={"space-between"}
                marginX={4}
              >
                <Person sx={{ fontSize: "50px", color: "white" }} />
                <Typography color={"white"} variant="h2">
                  Guest
                </Typography>
              </Stack>
            </Button>
          </Box> */}
          {/* <Stack direction={"column"} height={"100%"} width={"15%"}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              height={"50%"}
              width={"100%"}
              borderBottom={2}
              borderColor={theme.palette.primary.main}
              justifyContent={"center"}
              paddingX={2}
            >
              <Typography variant="h4" marginBottom={1}>
                CL/POA
              </Typography>
            </Box>
            <Button
              onClick={async () => {
                navigate(`/bookings/${guestFolioData?.bookingId}/payment`, {
                  state: { data: guestFolioData },
                });
              }}
              variant="outlined"
              disabled={isLoading}
              sx={{ height: "50%", width: "100%" }}
            >
              <Stack
                direction={"column"}
                height={"100%"}
                justifyContent={"center"}
              >
                <Payments sx={{ fontSize: "50px", color: "white" }} />
                <Typography variant="h3">Payment</Typography>
              </Stack>
            </Button>
          </Stack> */}
        </Stack>
      </Box>
      <Box
        marginX={4}
        // marginY={2}
        border={2}
        borderColor={theme.palette.primary.main}
      >
        <Stack direction={'row'} width={'100%'}>
          <Box
            width={'40%'}
            borderRight={2}
            borderColor={theme.palette.primary.main}
          >
            <Box
              width={'100%'}
              height={'40px'}
              paddingX={2}
              borderBottom={2}
              borderColor={theme.palette.primary.main}
              sx={{ backgroundColor: theme.palette.background.default }}
            >
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                height={'100%'}
                width={'100%'}
              >
                <Typography variant="h3" color={'primary'}>
                  Rooms ({guestFolioData?.roomBookings.length})
                </Typography>
                <CustomButton
                  variant="text"
                  disabled={isLoading}
                  onClick={() =>
                    !guestFolioData || guestFolioData.bookingStatus.length === 0
                      ? null
                      : CheckTimeDIfference(
                          guestFolioData.bookingStatus.filter(
                            (bookingStatus) => bookingStatus.isLatest
                          )[0].checkInDatetime
                        )
                      ? Notification.warning(
                          'Please create a new booking instead. Interval has exceeded 30 mins'
                        )
                      : navigate({
                          pathname: `/add-room/${bookingId}`,
                          search: createSearchParams({
                            duration: getDuration(
                              guestFolioData.bookingStatus
                            ).toString(),
                            date: guestFolioData.bookingStatus[0]
                              .checkInDatetime,
                          }).toString(),
                        })
                  }
                  sx={{
                    height: '100%',
                    width: '160px',
                    borderLeft: 2,
                  }}
                >
                  <Stack
                    direction={'row'}
                    width={'100%'}
                    height={'100%'}
                    alignItems={'center'}
                    justifyContent={'space-evenly'}
                  >
                    <Add />
                    <Typography variant="h4">Add Room</Typography>
                  </Stack>
                </CustomButton>
              </Stack>
            </Box>

            <Box height={'32em'} width={'100%'} paddingY={1} paddingX={2}>
              <TableContainer
                component={Box}
                sx={{ maxHeight: '100%', overflowY: 'auto' }}
              >
                <Table
                  sx={{
                    width: '100%',
                    borderCollapse: 'collapse',
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Room No.</StyledTableCell>
                      <StyledTableCell>Type</StyledTableCell>
                      <StyledTableCell>Zone</StyledTableCell>
                      <StyledTableCell>Action</StyledTableCell>
                      <StyledTableCell>Scan</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {guestFolioData?.roomBookings.map((row, index) => (
                      <Fragment key={row.roomBookingId}>
                        <TableRow
                          key={row.room}
                          sx={{
                            borderBottom: theme.palette.primary.main,
                          }}
                        >
                          <StyledTableCell>{row.roomCode}</StyledTableCell>
                          <StyledTableCell>{row.roomType}</StyledTableCell>
                          <StyledTableCell>{row.roomZone}</StyledTableCell>
                          <StyledTableCell>
                            <CustomIconButton
                              color="primary"
                              onClick={(event) =>
                                handleRoomMenuOpen(event, row)
                              }
                              bookingStatus={bookingStatus}
                            >
                              <Edit />
                            </CustomIconButton>
                          </StyledTableCell>
                          <StyledTableCell>
                            <CustomIconButton
                              color="primary"
                              onClick={() => {
                                scanTimeout && clearTimeout(scanTimeout);
                                scanTimeout = setTimeout(() => {
                                  handleScanAccessCard(row.roomBookingId);
                                }, 500);
                              }}
                              bookingStatus={bookingStatus}
                            >
                              <Typography>Scan</Typography>
                            </CustomIconButton>
                          </StyledTableCell>
                        </TableRow>
                        <Menu
                          anchorEl={anchorEl[row.roomBookingId]}
                          open={Boolean(anchorEl[row.roomBookingId])}
                          onClose={() => handleMenuClose(row.roomBookingId)}
                          sx={{
                            padding: 0,
                            '& .MuiList-root': {
                              backgroundColor: 'black',
                              paddingY: 0,
                              border: 2,
                              borderColor: theme.palette.primary.main,
                            },
                            '& .MuiButtonBase-root': {
                              color: theme.palette.primary.main,
                              ':hover': {
                                backgroundColor: theme.palette.primary.main,
                                color: 'black',
                              },
                            },
                          }}
                        >
                          <MenuItem
                            onClick={() => handleSelectedRoomActionOpen(row)}
                          >
                            Upgrade & Swap
                          </MenuItem>
                          <MenuItem onClick={handleOpenAssignGuestModal}>
                            Guest Re-assign
                          </MenuItem>
                        </Menu>
                      </Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Box
            width={'40%'}
            borderRight={2}
            borderColor={theme.palette.primary.main}
          >
            <Box
              width={'100%'}
              height={'40px'}
              paddingX={2}
              borderBottom={2}
              borderColor={theme.palette.primary.main}
              sx={{ backgroundColor: theme.palette.background.default }}
            >
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                height={'100%'}
                width={'100%'}
              >
                <Typography variant="h3" color={'primary'}>
                  Locker ({guestFolioData?.lockerBookings.length})
                </Typography>
                <CustomButton
                  onClick={handleOpenAddLockerModal}
                  variant="text"
                  disabled={
                    isLoading ||
                    (guestFolioData && guestFolioData.lot === 2) ||
                    (guestFolioData &&
                      guestFolioData.roomBookings.length < 1 &&
                      guestFolioData.lockerBookings.length > 0)
                  }
                  sx={{
                    height: '100%',
                    width: '160px',
                    borderLeft: 2,
                  }}
                >
                  <Stack
                    direction={'row'}
                    width={'100%'}
                    height={'100%'}
                    alignItems={'center'}
                    justifyContent={'space-evenly'}
                  >
                    <Add
                      sx={{
                        color:
                          guestFolioData && guestFolioData.lot === 2
                            ? 'grey'
                            : 'primary',
                      }}
                    />
                    <Typography variant="h4">Add Locker</Typography>
                  </Stack>
                </CustomButton>
              </Stack>
            </Box>
            <Box height={'32em'} width={'100%'} paddingY={1} paddingX={2}>
              <TableContainer
                component={Box}
                sx={{ maxHeight: '100%', overflow: 'auto' }}
              >
                <Table
                  sx={{
                    width: '100%',
                    borderCollapse: 'collapse',
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Locker ID</StyledTableCell>
                      <StyledTableCell>Zone</StyledTableCell>
                      <StyledTableCell>Binded Room</StyledTableCell>
                      <StyledTableCell>Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {guestFolioData?.lockerBookings.map((row) => (
                      <Fragment key={row.lockerBookingId}>
                        <TableRow
                          key={row.lockerBookingId}
                          sx={{
                            borderBottom: theme.palette.primary.main,
                          }}
                        >
                          <StyledTableCell>
                            <Stack
                              direction={'row'}
                              width={'100%'}
                              height={'100%'}
                            >
                              {row.locker.code}{' '}
                              {row.isExtended && row.extendDurationHour + 'h'}
                              {row.isExtended && (
                                <AccessTime sx={{ fontSize: 18 }} />
                              )}
                              {row.isExtra && '(Extra)'}
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.locker.zone.name}
                          </StyledTableCell>
                          <StyledTableCell>{row.roomCode}</StyledTableCell>
                          <StyledTableCell>
                            <CustomIconButton
                              color="primary"
                              onClick={(event) =>
                                handleLockerMenuOpen(event, row)
                              }
                              bookingStatus={bookingStatus}
                            >
                              <Edit />
                            </CustomIconButton>
                          </StyledTableCell>
                        </TableRow>
                        <Menu
                          anchorEl={anchorElLocker[row.lockerBookingId]}
                          open={Boolean(anchorElLocker[row.lockerBookingId])}
                          onClose={() =>
                            handleLockerMenuClose(row.lockerBookingId)
                          }
                          sx={{
                            padding: 0,
                            '& .MuiList-root': {
                              backgroundColor: 'black',
                              paddingY: 0,
                              border: 2,
                              borderColor: theme.palette.primary.main,
                            },
                            '& .MuiButtonBase-root': {
                              color: theme.palette.primary.main,
                              ':hover': {
                                backgroundColor: theme.palette.primary.main,
                                color: 'black',
                              },
                            },
                          }}
                        >
                          <MenuItem onClick={() => handleOpenExtendModal(row)}>
                            Extend
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleOpenLockerTransferModal(row)}
                          >
                            Swap
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleOpenRebindLockerModal(row)}
                          >
                            Rebind
                          </MenuItem>
                          <MenuItem
                            onClick={() => handleCancelLockerBooking(row)}
                          >
                            Cancel
                          </MenuItem>
                        </Menu>
                      </Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            {/* <Box
              width={"100%"}
              height={"40px"}
              paddingX={2}
              borderTop={2}
              borderBottom={2}
              borderColor={theme.palette.primary.main}
              sx={{ backgroundColor: theme.palette.background.default }}
            >
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                height={"100%"}
                width={"100%"}
              >
                <Typography variant="h3" color={"primary"}>
                  Access Card ({guestFolioData?.showerBookings.length})
                </Typography>
                <Button
                  variant="text"
                  onClick={handleOpenScanCardModal}
                  disabled={isLoading}
                  sx={{
                    height: "100%",
                    width: "160px",
                    borderLeft: 2,
                    borderColor: theme.palette.primary.main,
                  }}
                >
                  <Stack
                    direction={"row"}
                    width={"100%"}
                    height={"100%"}
                    alignItems={"center"}
                    justifyContent={"space-evenly"}
                  >
                    <Sensors />
                    <Typography variant="h4" color={"primary"}>
                      Scan Card
                    </Typography>
                  </Stack>
                </Button>
              </Stack>
            </Box> */}
            {/* <Box height={"170px"} width={"100%"} paddingY={1} paddingX={2}>
              <TableContainer
                component={Box}
                sx={{ maxHeight: "100%", overflow: "auto" }}
              >
                <Table
                  sx={{
                    width: "100%",
                    borderCollapse: "collapse",
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Shower ID</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {guestFolioData?.showerBookings.map((row) => (
                      <TableRow
                        key={row.showerBookingId}
                        sx={{
                          borderBottom: theme.palette.primary.main,
                        }}
                      >
                        <StyledTableCell>{row.shower}</StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box> */}
          </Box>
          <Box width={'20%'} height={'35em'}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              width={'100%'}
              height={'20%'}
              paddingX={2}
              borderBottom={2}
              borderColor={theme.palette.primary.main}
              alignItems={'start'}
              justifyContent={'center'}
            >
              <Typography variant={'h4'} sx={{ color: 'grey' }}>
                Total
              </Typography>
              {handleReturnPaymentOverview()}
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              width={'100%'}
              height={'80%'}
            >
              <CustomButton
                onClick={() =>
                  navigate(`payment`, { state: { data: guestFolioData } })
                }
                variant="outlined"
                disabled={isLoading}
                sx={{ height: '20%', width: '100%' }}
              >
                <Stack
                  direction={'row'}
                  width={'100%'}
                  height={'100%'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  spacing={4}
                >
                  <Payments sx={{ fontSize: '25px', color: 'white' }} />
                  <Typography variant="h4">
                    Payment{' '}
                    {paymentInformation.pendingAmount
                      ? '(MYR ' +
                        paymentInformation.pendingAmount.toString() +
                        ')'
                      : ''}
                  </Typography>
                </Stack>
              </CustomButton>

              <CustomButton
                variant="text"
                onClick={() => handleScanAccessCard()}
                disabled={
                  isLoading ||
                  (guestFolioData &&
                    (guestFolioData.lot === 2 ||
                      guestFolioData.lot === 3 ||
                      guestFolioData.showerBookings.length < 1))
                }
                sx={{
                  height: '20%',
                  width: '100%',
                  background: '#232323',
                  borderBottom: 1,
                  borderColor: theme.palette.primary.main,
                }}
              >
                <Stack
                  direction={'row'}
                  width={'100%'}
                  height={'100%'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  spacing={4}
                >
                  <Sensors
                    sx={{
                      color:
                        guestFolioData &&
                        guestFolioData.showerBookings.length < 1 &&
                        (guestFolioData.lot === 2 || guestFolioData.lot === 3)
                          ? 'grey'
                          : 'white',
                    }}
                  />
                  <Typography
                    variant="h4"
                    color={
                      guestFolioData &&
                      guestFolioData.showerBookings.length < 1 &&
                      (guestFolioData.lot === 2 || guestFolioData.lot === 3)
                        ? 'grey'
                        : 'white'
                    }
                  >
                    Scan Card {`(${guestFolioData?.showerBookings.length})`}
                  </Typography>
                </Stack>
              </CustomButton>
              <CustomButton
                variant="outlined"
                disabled={isLoading}
                sx={{
                  height: '20%',
                  width: '100%',
                }}
                onClick={handleDocumentModalClick}
              >
                <Stack
                  direction={'row'}
                  width={'100%'}
                  height={'100%'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  spacing={4}
                >
                  <Article sx={{ fontSize: '30px', color: 'white' }} />
                  <Typography color={'white'} variant="h4">
                    Document
                  </Typography>
                </Stack>
              </CustomButton>
              {bookingStatus === 'Check In' ||
              bookingStatus === 'Overstayed' ? (
                <CustomButton
                  variant="outlined"
                  onClick={handleCheckoutClick}
                  sx={{
                    height: '20%',
                    width: '100%',
                    boderBottom: 1,
                    borderColor: theme.palette.primary.main,
                  }}
                  disabled={isLoading || disableButton}
                >
                  <Stack
                    direction={'row'}
                    width={'100%'}
                    height={'100%'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    spacing={4}
                  >
                    <Logout
                      sx={{
                        fontSize: '25px',
                        color: disableButton ? 'gray' : 'white',
                      }}
                    />
                    <Typography
                      color={disableButton ? 'gray' : 'white'}
                      variant="h4"
                    >
                      Check Out
                    </Typography>
                  </Stack>
                </CustomButton>
              ) : (
                <CustomButton
                  variant="outlined"
                  onClick={handlecheckRoomDirty}
                  sx={{
                    height: '20%',
                    width: '100%',
                    borderBottom: 1,
                    borderColor: theme.palette.primary.main,
                  }}
                  disabled={
                    isLoading ||
                    (bookingStatus === bookingStatusEnum.booked &&
                      guestFolioData?.platform.canLatePayment === false)
                  }
                >
                  <Stack
                    direction={'row'}
                    width={'100%'}
                    height={'100%'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    spacing={4}
                  >
                    <Logout
                      sx={{
                        fontSize: '25px',
                        color:
                          disableButton ||
                          (bookingStatus === bookingStatusEnum.booked &&
                            guestFolioData?.platform.canLatePayment === false)
                            ? 'gray'
                            : 'white',
                      }}
                    />
                    <Typography
                      color={
                        disableButton ||
                        (bookingStatus === bookingStatusEnum.booked &&
                          guestFolioData?.platform.canLatePayment === false)
                          ? 'gray'
                          : 'white'
                      }
                      variant="h4"
                    >
                      Check In
                    </Typography>
                  </Stack>
                </CustomButton>
              )}
              <CustomButton
                onClick={handleCancelClick}
                variant="outlined"
                disabled={isLoading || disableButton}
                sx={{
                  height: '20%',
                  width: '100%',
                  borderBottom: 1,
                  borderColor: theme.palette.primary.main,
                }}
              >
                <Stack
                  direction={'row'}
                  width={'100%'}
                  height={'100%'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  spacing={4}
                >
                  <Warning
                    sx={{
                      fontSize: '25px',
                      color: disableButton ? 'gray' : 'white',
                    }}
                  />
                  <Typography
                    color={disableButton ? 'gray' : 'white'}
                    variant="h4"
                  >
                    Cancel
                  </Typography>
                </Stack>
              </CustomButton>
            </Box>
          </Box>
          {/* <Box width={"33.3%"}>
            <Box
              display={"flex"}
              width={"100%"}
              height={"40px"}
              paddingX={2}
              borderBottom={2}
              borderColor={theme.palette.primary.main}
              alignItems={"center"}
              sx={{ backgroundColor: theme.palette.background.default }}
            >
              <Typography variant="h3" color={"primary"}>
                Remarks
              </Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              width={"100%"}
              padding={2}
              alignItems={"end"}
            >
              <TextField
                multiline
                rows={13}
                fullWidth
                value={remarksValue}
                onChange={(e) => handleRemarksOnChange(e)}
                onDoubleClick={handleDoubleClick}
                disabled={isRemarksDisabled}
                sx={{
                  border: 0,
                  "& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled": {
                    opacity: 1,
                    WebkitTextFillColor: "rgb(220, 220, 220)",
                  },
                }}
              />
              <Button
                onClick={handleSaveRemarksButton}
                variant="outlined"
                sx={{ width: "120px", marginRight: 2 }}
              >
                Save
              </Button>
            </Box>
          </Box> */}
        </Stack>
      </Box>

      <Dialog
        open={openCheckoutBooking}
        onClose={handleCheckoutClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Box sx={{ backgroundColor: 'black' }}>
          <DialogTitle>
            <Typography variant="h3">Checkout Booking?</Typography>
          </DialogTitle>
          <DialogContent sx={{ marginY: 3 }}>
            <Typography variant="h4" marginBottom={2}>
              Please Key In Administrator Username & Password:
            </Typography>
            <TextField
              autoFocus
              name="adminusername"
              label="Admin Username"
              margin="dense"
              type="text"
              variant="filled"
              fullWidth
              InputLabelProps={{
                style: { color: 'white' },
              }}
              value={adminUsername}
              onChange={(e) => setAdminUsername(e.target.value)}
            />
            <TextField
              autoFocus
              name="adminpassword"
              label="Admin Password"
              margin="dense"
              type="password"
              variant="filled"
              fullWidth
              InputLabelProps={{
                style: { color: 'white' },
              }}
              value={adminPassword}
              onChange={(e) => setAdminPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions sx={{ marginX: 2 }}>
            <Button
              variant="outlined"
              sx={{ color: 'white', borderColor: 'white' }}
              onClick={() => {
                handleCheckAdmin('Check Out');
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog
        open={openCheckoutPopup}
        onClose={() => {
          setOpenCheckoutPopup(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Box sx={{ backgroundColor: 'black' }}>
          <DialogTitle>
            <Typography variant="h3" color={theme.palette.primary.main}>
              Payment pending
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ marginY: 3 }}>
            <Typography variant="h4" marginBottom={2}>
              Please pay the pending amount first
            </Typography>
          </DialogContent>
          <DialogActions sx={{ marginX: 2 }}>
            <Button
              variant="outlined"
              sx={{ color: 'white', borderColor: 'white' }}
              onClick={() => {
                setOpenCheckoutPopup(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog //dialog to cancel booking
        open={openCancelBooking}
        onClose={handleCancelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <Box sx={{ backgroundColor: 'black' }}>
          <DialogTitle>
            <Typography variant="h3">Cancel All Bookings?</Typography>
          </DialogTitle>
          <DialogContent sx={{ marginY: 3 }}>
            <Typography variant="h4" marginBottom={2}>
              Please Key In Administrator Username & Password:
            </Typography>
            <TextField
              autoFocus
              name="adminusername"
              label="Admin Username"
              margin="dense"
              type="text"
              variant="filled"
              fullWidth
              InputLabelProps={{
                style: { color: 'white' },
              }}
              value={adminUsername}
              onChange={(e) => setAdminUsername(e.target.value)}
            />
            <TextField
              autoFocus
              name="adminpassword"
              label="Admin Password"
              margin="dense"
              type="password"
              variant="filled"
              fullWidth
              InputLabelProps={{
                style: { color: 'white' },
              }}
              value={adminPassword}
              onChange={(e) => setAdminPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions sx={{ marginX: 2 }}>
            <Button
              variant="outlined"
              sx={{ color: 'white', borderColor: 'white' }}
              onClick={() => {
                handleCheckAdmin('Cancel');
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      {selectedRoom.roomBookings && (
        <GuestTransferModal
          open={true}
          onClose={handleCloseTransferModal}
          roomBookings={selectedRoom.roomBookings}
          roomTypeId={selectedRoom.roomTypeId}
          roomBookingId={selectedRoom.roomBookingId}
          bookingStatus={bookingStatus}
          bookingLot={guestFolioData?.lot}
        />
      )}
      {isAddLockerModalOpen && guestFolioData && guestFolioData.lot !== 2 && (
        // <AddLockerModal
        //   isAddLockerModalOpen={isAddLockerModalOpen}
        //   handleCloseAddLockerModal={handleCloseAddLockerModal}
        //   roomBookings={guestFolioData.roomBookings}
        //   bookingID={guestFolioData.bookingId}
        //   bookingNO={guestFolioData.bookingNo}
        // />
        <AddLockerModal
          isAddLockerModalOpen={isAddLockerModalOpen}
          handleCloseAddLockerModal={handleCloseAddLockerModal}
          guestFolioData={guestFolioData}
        />
      )}
      {selectedLocker.lockerBookings && isExtendModalOpen && guestFolioData && (
        <LockerExtendModal
          isExtendModalOpen={isExtendModalOpen}
          handleCloseExtendModal={handleCloseExtendModal}
          bookingID={guestFolioData?.bookingId}
          lockerBookings={selectedLocker.lockerBookings}
          roomZone={'C'}
          lockerZone={'1'}
        />
      )}
      {selectedLocker.lockerBookings &&
        isLockerTransferModalOpen &&
        guestFolioData && (
          <LockerTransferModal
            isLockerTransferModalOpen={isLockerTransferModalOpen}
            handleCloseLockerTransferModal={handleCloseLockerTransferModal}
            lockerBookings={selectedLocker.lockerBookings}
            bookingID={guestFolioData.bookingId}
          />
        )}
      {selectedLocker.lockerBookings &&
        isRebindLockerModalOpen &&
        guestFolioData && (
          <RebindLockerModal
            isRebindLockerModalOpen={isRebindLockerModalOpen}
            handleCloseRebindLockerModal={handleCloseRebindLockerModal}
            lockerBooking={selectedLocker.lockerBookings}
            roomBookings={guestFolioData.roomBookings}
          />
        )}
      {isScanCardModalOpen &&
        bookingId &&
        guestFolioData &&
        guestFolioData.lot !== 2 &&
        guestFolioData.lot !== 3 && (
          <ScanCardModal
            handleCloseScanCardModal={handleCloseScanCardModal}
            isScanCardModalOpen={isScanCardModalOpen}
            checkOutDate={
              new Date(guestFolioData.bookingStatus[0].checkOutDatetime)
            }
            bookingId={bookingId}
            setGuestFolioData={setGuestFolioData}
            bookingNo={guestFolioData.bookingNo}
          />
        )}
      {openCheckInModal && (
        <SuccessCheckInModal
          handleCloseModal={handleCloseCheckinModal}
          isOpen={openCheckInModal}
        />
      )}
      {openGuestBooking && handleGuestClose && guestFolioData && (
        <AddExtraGuest
          openGuestBooking={openGuestBooking}
          handleGuestClose={handleGuestClose}
          roomBookings={guestFolioData.roomBookings}
          bookingId={guestFolioData.bookingId}
          handleOpenAssignGuestModal={handleOpenAssignGuestModal}
        />
      )}
      {openEditGuestProfile &&
        handleEditGuestProfileClose &&
        guestFolioData && (
          <EditGuestProfile
            openEditGuestProfile={openEditGuestProfile}
            handleEditGuestProfileClose={handleEditGuestProfileClose}
            guestId={guestFolioData.customerStaying}
          />
        )}

      {openDocumentModal && handleDocumentModalClose && guestFolioData && (
        <DocumentModal
          openDocumentModal={openDocumentModal}
          handleDocumentModalClose={handleDocumentModalClose}
          bookingStatusObj={
            guestFolioData.bookingStatus.filter(
              (bookingStatus) => bookingStatus.isLatest === true
            )[0]
          }
          bookingData={guestFolioData}
        />
      )}

      {isRoomDirty && handleCheckRoomDirtyClose && handleCheckInBooking && (
        <CheckRoomDirty
          isRoomDirty={isRoomDirty}
          handleCheckRoomDirtyClose={handleCheckRoomDirtyClose}
          handleCheckInBooking={handleCheckInBooking}
        />
      )}

      {openBookingTransferModal && bookingId && guestFolioData && (
        <BookingTransferModal
          bookingTransferModalOpen={openBookingTransferModal}
          bookingId={bookingId}
          lot={guestFolioData.lot}
          handleBookingTransferModalClose={handleCloseBookingTransferModal}
          handleFetchGuestFolio={handleFetchGuestFolio}
        />
      )}
      {openEditCheckInModal && guestFolioData && (
        <EditCheckInModal
          open={openEditCheckInModal}
          handleClose={handleCloseEditCheckInModal}
          guestFolioData={guestFolioData}
        />
      )}
      {guestFolioData && openAssignGuestModal && (
        <AssignGuestModal
          open={openAssignGuestModal}
          handleCloseModal={handleCloseAssignGuestModal}
          roomBookings={guestFolioData.roomBookings}
          bookingId={guestFolioData.bookingId}
          customerBooked={
            !guestFolioData.customerStaying
              ? guestFolioData.customerBooked
              : undefined
          }
          setGuestFolioData={setGuestFolioData}
        />
      )}
    </>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
}));
