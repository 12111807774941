import { useEffect, useState } from "react"

import axiosInstance from "../constants/axiosConfig";

// redux
import { RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";

// types and interfaces
import { PermissionDataInterface } from "../models/accounts/permissionInterface";
import { setTempTwo } from "../redux/temp/tempSlice";
import Notification from "../utils/notificationConfig";


export default function useDevTools(fetchPermissions : boolean = false) {

    const dispatch = useDispatch();

    const token = useSelector((state: RootState) => state.user.accessToken);
    const disabledPermissions = useSelector((state : RootState) => state.temp.tempTwo);

    const [open, setOpen] = useState<boolean>(false);
    const [collapse, setCollapse] = useState<boolean>(false);
    const [keySequence, setKeySequence] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);

    const [permissions, setPermissions] = useState<PermissionDataInterface[]>([]);

    const secret : string = "capsulesecret"

    useEffect(() => {
        window.addEventListener("keydown", keyPressEvent);

        return () => {
            window.removeEventListener("keydown", keyPressEvent);
        };

    }, [secret]);

    useEffect(() => {
        if (!open || !token || !fetchPermissions) return;
        getPermissionData();
    }, [open, token])

    useEffect(() => {
        if (keySequence !== secret) return;
        setOpen(true);
    }, [keySequence]);

    const keyPressEvent = (event : KeyboardEvent) : void => {
        setKeySequence((prev) => (prev + event.key).slice(-secret.length));
    }

    const getPermissionData = () => {
        axiosInstance.get('/accounts/permission/get-all-permissions', {
            headers: { Authorization: `Bearer ${token}` }
        }).then(res => {
            if (res.status !== 200) return;
            setPermissions(res.data.data);
        }).catch(e => {
            console.log(e);
        }).finally(() => setLoading(false));
    }

    const handleCollapse = () => {
        setCollapse(prev => !prev);
    }
    const handleClose = () => {
        setOpen(false);
    }

    const handleTooggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(true);
        let newDisabledPermissions : string[] = [];

        // uncheck = include into disabled permissions
        if (!event.target.checked) {
            newDisabledPermissions = [...disabledPermissions, event.target.name];

        // checked = remove from disabled permissions
        } else {
            newDisabledPermissions = disabledPermissions.filter(item => item !== event.target.name)
        }

        axiosInstance.put("/lot-settings/set-temp-two/", {
            temp_data: newDisabledPermissions
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            if (res.status !== 200) {
                Notification.failed("something wrong");
                return;
            }
            setDisabledPermissions(newDisabledPermissions);
            Notification.success();

        }).catch(err => {
            Notification.failed("something wrong");
            console.log(err)
        }).finally(() => {
            setLoading(false);
        })
    }

    const setDisabledPermissions = (data : string[]) : void => {
        dispatch(setTempTwo(data))
    }

    return {
        devToolOpen : open,
        loading,
        permissions,
        collapse,
        disabledPermissions,
        handleCollapse,
        handleTooggleChange,
        setDisabledPermissions,
        handleClose,
    }
}