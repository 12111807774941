import { useEffect, useState } from "react"

import axiosInstance from "../constants/axiosConfig";

// redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../redux/store";
import { setTempTwo } from "../redux/temp/tempSlice";
import useDevTools from "./useDevTools";


export default function useProtectedRoutes() {

    const { setDisabledPermissions } = useDevTools();

    const token = useSelector((state: RootState) => state.user.accessToken);

    const [doFetch, setDoFetch] = useState<boolean>(false);
    const [fetchLoad, setFetchLoad] = useState<boolean>(true);

    useEffect(() => {
        if (!doFetch || !token) return;
        handleGetTemp2Info();
    }, [doFetch, token]);

    const fetchTempData = () => {
        setDoFetch(true);
    }

    const handleGetTemp2Info = () => {
        axiosInstance.get("/lot-settings/get-temp-two", {
            headers: { Authorization: `Bearer ${token}` }
        }).then(res => {
            if (res.status !== 200) return;
            setDisabledPermissions(res.data.data);
        }).catch(e => {
            console.log(e);
        }).finally(() => setFetchLoad(false))
    }

    return {
        fetchLoad,
        fetchTempData
    }
}