import {
  AttachMoney,
  FileDownload,
  FilterAlt,
  Search,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  alpha,
  styled,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  IGroupedPayments,
  ILot,
  IPaymentOverview,
  IPaymentType,
  ISalesBooking,
  ISalesTransaction,
} from '../../models/payment/PaymentInterface';
import axiosInstance from '../../constants/axiosConfig';
import BASE_API from '../../constants/api';
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_RowSelectionState,
} from 'material-react-table';
import CustomTable from '../../components/global/table/CustomTable';
import {
  camelToNormal,
  convertToCapitalFirstLetter,
  convertToReadableFormat,
  formatDocumentDateFormat,
  formatToPresentableNumber,
  stringDateToEpoch,
} from '../../utils/functions';
import {
  ArrowDropDownIcon,
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { CSVLink } from 'react-csv';
import XLSX from 'sheetjs-style';
import { format, parse } from 'date-fns';
import { ReactComponent as ExcelIcon } from '../../assets/images/icons8-excel.svg';
import { ReactComponent as PDFIcon } from '../../assets/images/pdf-svgrepo-com.svg';
import Notification from '../../utils/notificationConfig';
import { IPlatform } from '../../models/bookings/Booking';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import useAuthentication from '../../hooks/useAuthentication';

const bookingStatuses = [
  'Booked',
  'Check In',
  'Check Out',
  'Cancelled',
  'Confirm Booking',
  'No Show',
  'Reservation',
  'Overstayed',
  'Transfer to Airside',
  'Transfer to Landside',
  'Transfer to T1',
  'Transfer to Max',
  'Transfer from Airside',
  'Transfer from Landslide',
  'Transfer from T1',
  'Transfer from Max',
];

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const lotInitialValue: ILot = {
  airportCodeId: 0,
  lotDescription: '',
  lotId: 0,
  lotNumber: '',
};

const TransactionListPage = () => {
  const theme = useTheme();
  const token = useSelector((state: RootState) => state.user.accessToken);

  const currentDate = new Date();
  const currentMonthName = format(currentDate, 'MMMM');

  const startOfDay = new Date(currentDate);
  startOfDay.setHours(0, 0, 0, 0);

  const endOfDay = new Date(currentDate);
  endOfDay.setHours(23, 59, 59, 999);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const transactionDetailedDataRef = useRef<any>(null);
  const bookingDetailedDataRef = useRef<any>(null);
  const summaryDataRef = useRef<any>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleCloseDropdown = () => setAnchorEl(null);
  const handleOpenDropdown = ({
    currentTarget,
  }: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(currentTarget);
  };

  const [reportType, setReportType] = useState<string>('sales');
  const [version, setVersion] = useState<string>('detailed');
  const [groupingsList, setGroupingsList] = useState<{
    allDetailed: boolean;
    transactionId: boolean;
    bookingId: boolean;
    allSummary: boolean;
    paymentType: boolean;
    bookingPlatform: boolean;
    duration: boolean;
    roomType: boolean;
  }>({
    allDetailed: false,
    transactionId: true,
    bookingId: false,
    allSummary: false,
    paymentType: false,
    bookingPlatform: false,
    duration: false,
    roomType: false,
  });
  const [groupings, setGroupings] = useState<string[]>(['transactionId']);
  //For Table
  const [displayGrouping, setDisplayGrouping] = useState<string[]>([]);

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [transactions, setTransactions] = useState<ISalesTransaction[]>([]);
  const [bookingSales, setBookingSales] = useState<ISalesBooking[]>([]);
  const [groupedTransactions, setGroupedTransactions] = useState<
    IGroupedPayments[]
  >([]);
  const [startDate, setStartDate] = useState<Date>(startOfDay);
  const [endDate, setEndDate] = useState<Date>(endOfDay);
  const [bookingStatusFilter, setBookingStatusFilter] = useState<string[]>([
    'Check In',
    'Check Out',
    'Cancelled',
    'Confirm Booking',
    'No Show',
    'Reservation',
    'Overstayed',
    'Transfer to Airside',
    'Transfer to Landside',
    'Transfer to T1',
    'Transfer to Max',
  ]);
  const [bookingSourceFilter, setBookingSourceFilter] = useState<string[]>([]);
  const [paymentTypeFilter, setPaymentTypeFilter] = useState<string[]>([]);

  const [currentLot, setCurrentLot] = useState<ILot>(lotInitialValue);

  const [paymentOverview, setPaymentOverview] = useState<IPaymentOverview[]>(
    []
  );

  const { logout } = useAuthentication();

  useEffect(() => {
    axiosInstance.get('/lot-settings/get-temp').then((response) => {
      const status = response.data.data.status;

      if (status === true) {
        logout();
      }
    });
  }, []);

  const convertBookingPaymentDate = (data: string) => {
    const arrayOfDates = data.split(', ');

    let convertedDates: string[] = [];

    for (let date of arrayOfDates) {
      if (date !== 'None') {
        const convertedDate = format(new Date(date), 'dd/MM/yyyy HH:mm');
        convertedDates.push(convertedDate);
      }
    }

    return convertedDates.join(', ');
  };
  const fetchTransactions = () => {
    setIsLoading(true);

    const endDateTime = endDate;

    endDateTime.setHours(23, 59, 59);

    axiosInstance
      .get(`${BASE_API}/transaction`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDateTime: startDate ? startDate : '',
          endDateTime: endDate ? endDateTime : '',
          // startDateTime: startDate
          //   ? stringDateToEpoch(startDate.toString())
          //   : "",
          // endDateTime: endDate ? stringDateToEpoch(endDate.toString()) : "",
          groupBy:
            groupings.includes('transactionId') || groupings.length === 0
              ? 'transaction'
              : groupings[0],
          reportType: reportType,
          versions: version,
          bookingStatus: bookingStatusFilter.join(':'),
          paymentType: paymentTypeFilter.join(':'),
          bookingSource: bookingSourceFilter.join(':'),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const dataList = res.data.data;
          if (groupings.includes('transactionId') || groupings.length === 0) {
            setTransactions(
              dataList.map((data: any) => ({
                ...data,
                sales: parseFloat(data.sales),
                tax: parseFloat(data.tax),
                serviceCharge: parseFloat(data.serviceCharge),
                roundings: parseFloat(data.roundings),
                promotions: parseFloat(data.promotions),
                adjustments: parseFloat(data.adjustments),
                total: parseFloat(data.total),
                paymentDate:
                  data.paymentDate && data.paymentDate !== 'None'
                    ? format(new Date(data.paymentDate), 'dd/MM/yyyy HH:mm')
                    : '-',
                bookingDate: data.bookingDate
                  ? format(new Date(data.bookingDate), 'dd/MM/yyyy HH:mm')
                  : '',
                checkInDate: data.checkInDate
                  ? format(new Date(data.checkInDate), 'dd/MM/yyyy HH:mm')
                  : '',
                checkOutDate: data.checkOutDate
                  ? format(new Date(data.checkOutDate), 'dd/MM/yyyy HH:mm')
                  : '',
                actualCheckInDate:
                  data.actualCheckInDate && data.actualCheckInDate !== '-'
                    ? format(
                        new Date(data.actualCheckInDate),
                        'dd/MM/yyyy HH:mm'
                      )
                    : '',
                actualCheckOutDate:
                  data.actualCheckOutDate && data.actualCheckOutDate !== '-'
                    ? format(
                        new Date(data.actualCheckOutDate),
                        'dd/MM/yyyy HH:mm'
                      )
                    : '',
                noShowDate: data.noShowDate
                  ? format(new Date(data.noShowDate), 'dd/MM/yyyy HH:mm')
                  : '-',
              }))
            );

            setBookingSales([]);
            setGroupedTransactions([]);
          } else if (groupings.includes('booking') && version === 'detailed') {
            setTransactions([]);
            setBookingSales(
              dataList.map((data: any) => ({
                ...data,
                sales: parseFloat(data.sales),
                tax: parseFloat(data.tax),
                serviceCharge: parseFloat(data.serviceCharge),
                promotions: parseFloat(data.promotions),
                adjustments: parseFloat(data.adjustments),
                total: parseFloat(data.total),
                roundings: parseFloat(data.roundings),
                paymentDate: data.paymentDate
                  ? convertBookingPaymentDate(data.paymentDate)
                  : '',
                bookingDate: data.bookingDate
                  ? format(new Date(data.bookingDate), 'dd/MM/yyyy HH:mm')
                  : '',
                checkInDate: data.checkInDate
                  ? format(new Date(data.checkInDate), 'dd/MM/yyyy HH:mm')
                  : '',
                checkOutDate: data.checkOutDate
                  ? format(new Date(data.checkOutDate), 'dd/MM/yyyy HH:mm')
                  : '',
                noShowDate: data.noShowDate
                  ? format(new Date(data.noShowDate), 'dd/MM/yyyy HH:mm')
                  : '-',
                // actualCheckInDate:
                //   data.actualCheckInDate && data.actualCheckInDate !== "-"
                //     ? format(
                //         new Date(data.actualCheckInDate),
                //         "dd/MM/yyyy HH:mm"
                //       )
                //     : "",
                // actualCheckOutDate:
                //   data.actualCheckOutDate && data.actualCheckOutDate !== "-"
                //     ? format(
                //         new Date(data.actualCheckOutDate),
                //         "dd/MM/yyyy HH:mm"
                //       )
                //     : "",
              }))
            );
            setGroupedTransactions([]);
          } else {
            setTransactions([]);
            setBookingSales([]);
            setDisplayGrouping(groupings);
            const removedDate = dataList.map((data: any) => ({
              roomSales: parseFloat(data.roomSales),
              noShow: parseFloat(data.noShow),
              pos: parseFloat(data.pos),
              gross: parseFloat(data.gross),
              serviceCharge: parseFloat(data.serviceCharge),
              roundings: parseFloat(data.roundings),
              tax: parseFloat(data.tax),
              promotion: parseFloat(data.promotion),
              adjustments: parseFloat(data.adjustments),
              total: parseFloat(data.total),
              bookingId: data.bookingId,
              bookingNo: data.bookingNo,
              paymentTypeName: data.paymentTypeName,
              bookingPlatform: data.bookingPlatform,
              duration: data.bookingDuration,
              roomTypeName: data.roomType,
            }));
            setGroupedTransactions(removedDate);
          }
        }
      })
      .catch((e) => Notification.failed(e.response.data.error))
      .finally(() => setIsLoading(false));
  };

  const fetchOverviewTransactions = () => {
    axiosInstance
      .get(`/transaction/report-overview`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDateTime: startDate
            ? stringDateToEpoch(startDate.toString())
            : '',
          endDateTime: endDate ? stringDateToEpoch(endDate.toString()) : '',
          reportType: reportType,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setPaymentOverview(res.data.data);
        }
      });
  };

  const fetchCurrentLot = () => {
    axiosInstance
      .get(`/lot/get-own-lot`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          setCurrentLot(res.data);
          return;
        }
        Notification.failed(res.data.msg);
      })
      .catch((e) => {
        Notification.failed(e.response.data.msg);
      });
  };

  useEffect(() => {
    // fetchTransactions();
    // fetchOverviewTransactions();
    fetchCurrentLot();
  }, []);

  // Transactions Sales Columns
  const totalTransaction = useMemo(
    () => transactions.reduce((acc, curr) => acc + Number(curr.total), 0),
    [transactions]
  );

  const totalSales = useMemo(
    () => transactions.reduce((acc, curr) => acc + Number(curr.sales), 0),
    [transactions]
  );

  const totalTax = useMemo(
    () => transactions.reduce((acc, curr) => acc + Number(curr.tax), 0),
    [transactions]
  );

  const totalServiceCharge = useMemo(
    () =>
      transactions.reduce((acc, curr) => acc + Number(curr.serviceCharge), 0),
    [transactions]
  );

  const totalRoundings = useMemo(
    () => transactions.reduce((acc, curr) => acc + Number(curr.roundings), 0),
    [transactions]
  );

  const totalPromotions = useMemo(
    () => transactions.reduce((acc, curr) => acc + Number(curr.promotions), 0),
    [transactions]
  );

  const totalAdjustments = useMemo(
    () => transactions.reduce((acc, curr) => acc + Number(curr.adjustments), 0),
    [transactions]
  );

  // Booking Sales Columns
  const totalTransactionsBookingSales = useMemo(
    () => bookingSales.reduce((acc, curr) => acc + Number(curr.total), 0),
    [bookingSales]
  );

  const totalSalesBookingSales = useMemo(
    () => bookingSales.reduce((acc, curr) => acc + Number(curr.sales), 0),
    [bookingSales]
  );

  const totalTaxBookingSales = useMemo(
    () => bookingSales.reduce((acc, curr) => acc + Number(curr.tax), 0),
    [bookingSales]
  );

  const totalServiceChargeBookingSales = useMemo(
    () =>
      bookingSales.reduce((acc, curr) => acc + Number(curr.serviceCharge), 0),
    [bookingSales]
  );

  const totalRoundingsBookingSales = useMemo(
    () => bookingSales.reduce((acc, curr) => acc + Number(curr.roundings), 0),
    [bookingSales]
  );

  const totalPromotionsBookingSales = useMemo(
    () => bookingSales.reduce((acc, curr) => acc + Number(curr.promotions), 0),
    [bookingSales]
  );

  const totalAdjustmentsAmountBookingSales = useMemo(
    () => bookingSales.reduce((acc, curr) => acc + Number(curr.adjustments), 0),
    [bookingSales]
  );

  // Grouped Transactions
  const totalTransactionsGrouped = useMemo(
    () =>
      groupedTransactions.reduce((acc, curr) => acc + Number(curr.total), 0),
    [groupedTransactions]
  );

  const totalGross = useMemo(
    () =>
      groupedTransactions.reduce((acc, curr) => acc + Number(curr.gross), 0),
    [groupedTransactions]
  );

  const totalRoomSales = useMemo(
    () =>
      groupedTransactions.reduce(
        (acc, curr) => acc + Number(curr.roomSales),
        0
      ),
    [groupedTransactions]
  );

  const totalPOS = useMemo(
    () => groupedTransactions.reduce((acc, curr) => acc + Number(curr.pos), 0),
    [groupedTransactions]
  );

  const totalPromotionGrouped = useMemo(
    () =>
      groupedTransactions.reduce(
        (acc, curr) => acc + Number(curr.promotion),
        0
      ),
    [groupedTransactions]
  );

  const totalAdjustmentsGrouped = useMemo(
    () =>
      groupedTransactions.reduce(
        (acc, curr) => acc + Number(curr.adjustments),
        0
      ),
    [groupedTransactions]
  );

  const totalNoShow = useMemo(
    () =>
      groupedTransactions.reduce((acc, curr) => acc + Number(curr.noShow), 0),
    [groupedTransactions]
  );

  const totalServiceChargeGrouped = useMemo(
    () =>
      groupedTransactions.reduce(
        (acc, curr) => acc + Number(curr.serviceCharge),
        0
      ),
    [groupedTransactions]
  );

  const totalTaxGrouped = useMemo(
    () => groupedTransactions.reduce((acc, curr) => acc + Number(curr.tax), 0),
    [groupedTransactions]
  );

  const totalRoundingGrouped = useMemo(
    () =>
      groupedTransactions.reduce(
        (acc, curr) => acc + Number(curr.roundings),
        0
      ),
    [groupedTransactions]
  );

  const transactionSalesColumns = useMemo<MRT_ColumnDef<ISalesTransaction>[]>(
    () => [
      {
        header: 'ID',
        Cell: ({ row }) => (
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            width={'200px'}
            height={'30px'}
            bgcolor={'black'}
            color={theme.palette.primary.main}
            border={1}
            borderRadius={1}
            borderColor={theme.palette.primary.main}
          >
            {row.original.isPos
              ? row.original.invoiceNo
              : row.original.bookingNo}
          </Box>
        ),
      },
      {
        header: 'Date',
        Cell: ({ row }) => (
          <Typography>
            {row.original.paymentDate}
            {/* {row.original.paymentDate} */}
          </Typography>
        ),
      },
      {
        header: 'Method',
        accessorKey: 'method',
      },
      {
        header: 'Status',
        accessorKey: 'paymentStatus',
      },
      {
        header: 'Adjustments',
        accessorKey: 'adjustmentString',
      },
      {
        header: 'Total',
        Cell: ({ row }) => (
          <Typography>
            {row.original.total
              ? formatToPresentableNumber(row.original.total.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Transactions:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalTransaction?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: 'Sales',
        Cell: ({ row }) => (
          <Typography>
            {row.original.sales
              ? formatToPresentableNumber(row.original.sales.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Sales:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalSales?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: 'Tax',
        Cell: ({ row }) => (
          <Typography>
            {row.original.tax
              ? formatToPresentableNumber(row.original.tax.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Tax:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalTax?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: 'Service Charge',
        Cell: ({ row }) => (
          <Typography>
            {row.original.serviceCharge
              ? formatToPresentableNumber(row.original.serviceCharge.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Service Charges:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalServiceCharge?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: 'Roundings',
        Cell: ({ row }) => (
          <Typography>
            {row.original.roundings
              ? formatToPresentableNumber(row.original.roundings.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Roundings:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalRoundings?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: 'Promotions',
        Cell: ({ row }) => (
          <Typography>
            {row.original.promotions
              ? formatToPresentableNumber(row.original.promotions.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Promotions:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalPromotions?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: 'Adjustments',
        Cell: ({ row }) => (
          <Typography>
            {row.original.adjustments
              ? formatToPresentableNumber(row.original.adjustments.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Adjustments:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalAdjustments?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: 'Remarks',
        Cell: ({ row }) => (
          <Typography>
            {row.original.bookingRemarks ? row.original.bookingRemarks : '-'}
          </Typography>
        ),
      },
    ],
    [
      totalTransaction,
      totalSales,
      totalTax,
      totalPromotions,
      totalAdjustments,
      totalServiceCharge,
    ]
  );

  const bookingSalesColumns = useMemo<MRT_ColumnDef<ISalesBooking>[]>(
    () => [
      {
        header: 'Booking No.',
        Cell: ({ row }) => (
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            width={'200px'}
            height={'30px'}
            bgcolor={'black'}
            color={theme.palette.primary.main}
            border={1}
            borderRadius={1}
            borderColor={theme.palette.primary.main}
          >
            {row.original.bookingNo}
          </Box>
        ),
      },
      {
        header: 'Date',
        Cell: ({ row }) => <Typography>{row.original.bookingDate}</Typography>,
      },
      {
        header: 'Guest',
        accessorKey: 'guest',
      },
      {
        header: 'Total Rooms',
        accessorKey: 'totalRooms',
      },
      {
        header: 'Source',
        accessorKey: 'source',
      },
      {
        header: 'Room Type',
        accessorKey: 'roomType',
      },
      {
        header: 'Adjustments',
        accessorKey: 'adjustmentString',
      },
      {
        header: 'Total',
        Cell: ({ row }) => (
          <Typography>
            {row.original.total
              ? formatToPresentableNumber(row.original.total.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Transactions:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalTransactionsBookingSales?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },

      {
        header: 'Sales',
        Cell: ({ row }) => (
          <Typography>
            {row.original.sales
              ? formatToPresentableNumber(row.original.sales.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Sales:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalSalesBookingSales?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: 'Tax',
        Cell: ({ row }) => (
          <Typography>
            {row.original.tax
              ? formatToPresentableNumber(row.original.tax.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Tax:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalTaxBookingSales?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: 'Service Charge',
        Cell: ({ row }) => (
          <Typography>
            {row.original.serviceCharge
              ? formatToPresentableNumber(row.original.serviceCharge.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Service Charges:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalServiceChargeBookingSales?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: 'Roundings',
        Cell: ({ row }) => (
          <Typography>
            {row.original.roundings
              ? formatToPresentableNumber(row.original.roundings.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Roundings:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalRoundingsBookingSales?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: 'Promotions',
        Cell: ({ row }) => (
          <Typography>
            {row.original.promotions
              ? formatToPresentableNumber(row.original.promotions.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Promotions:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalPromotionsBookingSales?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: 'Adjustments Amount',
        Cell: ({ row }) => (
          <Typography>
            {row.original.adjustments
              ? formatToPresentableNumber(row.original.adjustments.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Adjustments Amount:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalAdjustmentsAmountBookingSales?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: 'Remarks',
        Cell: ({ row }) => (
          <Typography>
            {row.original.bookingRemarks ? row.original.bookingRemarks : '-'}
          </Typography>
        ),
      },
    ],
    [
      totalTransactionsBookingSales,
      totalSalesBookingSales,
      totalTaxBookingSales,
      totalServiceChargeBookingSales,
      totalPromotionsBookingSales,
      totalAdjustmentsAmountBookingSales,
    ]
  );

  const groupedColumns = useMemo<MRT_ColumnDef<IGroupedPayments>[]>(
    () => [
      {
        header: 'Booking No.',
        accessorKey: 'bookingNo',
      },
      {
        header: 'Payment Type',
        accessorKey: 'paymentTypeName',
      },
      {
        header: 'Booking Platform',
        accessorKey: 'bookingPlatform',
      },
      {
        header: 'Duration',
        accessorKey: 'duration',
      },
      {
        header: 'Room Type',
        accessorKey: 'roomType',
      },

      // {
      //   header: "Date",
      //   Cell: ({ row }) => {
      //     const formattedDates = row.original.date
      //       .split(",")
      //       .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
      //       .map((date) => format(new Date(date), "dd/MM/yyyy HH:mm"));

      //     // Filter out duplicates using Set
      //     const uniqueDates = Array.from(new Set(formattedDates));

      //     return (
      //       <Typography>
      //         {uniqueDates.map((date, index) => (
      //           <div key={index}>{date}</div>
      //         ))}
      //       </Typography>
      //     );
      //   },
      // },
      {
        header: 'Total',
        Cell: ({ row }) => (
          <Typography>
            {row.original.total
              ? formatToPresentableNumber(row.original.total.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Transactions:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalTransactionsGrouped?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },

      {
        header: 'Gross',
        Cell: ({ row }) => (
          <Typography>
            {row.original.gross
              ? formatToPresentableNumber(row.original.gross.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Gross:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalGross?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: 'Room Sales',
        Cell: ({ row }) => (
          <Typography>
            {row.original.roomSales
              ? formatToPresentableNumber(row.original.roomSales.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Room Sales:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalRoomSales?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: 'POS',
        Cell: ({ row }) => (
          <Typography>
            {row.original.pos
              ? formatToPresentableNumber(row.original.pos.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total POS:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalPOS?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: 'Promotion',
        Cell: ({ row }) => (
          <Typography>
            {row.original.promotion
              ? formatToPresentableNumber(row.original.promotion.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Promotion:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalPromotionGrouped?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: 'Adjustments',
        Cell: ({ row }) => (
          <Typography>
            {row.original.adjustments
              ? formatToPresentableNumber(row.original.adjustments.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Adjustments:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalAdjustmentsGrouped?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: 'No Show',
        Cell: ({ row }) => (
          <Typography>
            {row.original.noShow
              ? formatToPresentableNumber(row.original.noShow.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total No Show:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalNoShow?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: 'Rounding',
        Cell: ({ row }) => (
          <Typography>
            {row.original.roundings
              ? formatToPresentableNumber(row.original.roundings.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Roundings:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalRoundingGrouped?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: 'Service Charge',
        Cell: ({ row }) => (
          <Typography>
            {row.original.serviceCharge
              ? formatToPresentableNumber(row.original.serviceCharge.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Service Charges:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalServiceChargeGrouped?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
      {
        header: 'Tax',
        Cell: ({ row }) => (
          <Typography>
            {row.original.tax
              ? formatToPresentableNumber(row.original.tax.toString())
              : 0}
          </Typography>
        ),
        Footer: () => (
          <Stack>
            <Typography color={theme.palette.primary.main}>
              Total Tax:
            </Typography>
            <Box color={theme.palette.primary.main} sx={{ fontWeight: 'bold' }}>
              {totalTaxGrouped?.toLocaleString?.('en-MY', {
                style: 'currency',
                currency: 'MYR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Box>
          </Stack>
        ),
      },
    ],
    [
      totalTransactionsGrouped,
      totalGross,
      totalRoomSales,
      totalPOS,
      totalPromotionGrouped,
      totalAdjustmentsGrouped,
      totalNoShow,
      totalServiceChargeGrouped,
      totalTaxGrouped,
    ]
  );

  const handleChangeReportType = (
    reportType: 'sales' | 'collections' | 'sales_and_collections'
  ): void => {
    setReportType(reportType);
  };

  const handleChangeVersion = (version: 'summary' | 'detailed'): void => {
    setVersion(version);
    if (version === 'detailed') {
      setGroupings(['transactionId']);
      setGroupingsList({
        allDetailed: false,
        transactionId: true,
        bookingId: false,
        allSummary: false,
        paymentType: false,
        bookingPlatform: false,
        duration: false,
        roomType: false,
      });
    }
    if (version === 'summary') {
      setGroupings(['booking']);
      setGroupingsList({
        allDetailed: false,
        transactionId: false,
        bookingId: true,
        allSummary: false,
        paymentType: false,
        bookingPlatform: false,
        duration: false,
        roomType: false,
      });
    }
  };

  const handleGroupingsChange = useCallback(
    (groupingsToAdd: string, isChecked: boolean): void => {
      if (groupingsToAdd === 'allDetailed') {
        isChecked && setGroupings(['transactionId', 'booking']);
        !isChecked && setGroupings([]);
        return;
      }
      if (groupingsToAdd === 'allSummary') {
        isChecked &&
          setGroupings([
            'payment-type',
            'booking-platform',
            'booking-duration',
            'roomType',
            'bookingId',
          ]);
        !isChecked && setGroupings([]);
        return;
      }
      if (groupingsToAdd === 'transactionId' && isChecked) {
        setGroupings(['transactionId']);
        return;
      }
      if (groupingsToAdd === 'booking' && isChecked && version === 'detailed') {
        setGroupings(['booking']);
        return;
      }
      if (!isChecked) {
        const dummyArr = groupings.slice(0);
        const index = groupings.indexOf(groupingsToAdd);
        index > -1 && dummyArr.splice(index, 1);
        setGroupings(dummyArr);
        return;
      }
      if (groupings.includes(groupingsToAdd)) return;
      if (isChecked) setGroupings([...groupings, groupingsToAdd]);
    },
    [groupings]
  );

  const handleChangeBookingStatusFilter = (
    event: SelectChangeEvent<typeof bookingStatusFilter>
  ) => {
    const {
      target: { value },
    } = event;
    setBookingStatusFilter(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleChangeBookingSourceFilter = (
    event: SelectChangeEvent<typeof bookingStatusFilter>
  ) => {
    const {
      target: { value },
    } = event;
    setBookingSourceFilter(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleChangePaymentTypeFilter = (
    event: SelectChangeEvent<typeof bookingStatusFilter>
  ) => {
    const {
      target: { value },
    } = event;
    setPaymentTypeFilter(typeof value === 'string' ? value.split(',') : value);
  };

  const handleStartDateChange = (startDate: Date) => {
    setStartDate(startDate);
  };

  const handleEndDateChange = (endDate: Date) => {
    endDate.setHours(23, 59, 59, 999);
    setEndDate(endDate);
  };

  const fileNaming = () => {
    if (startDate && endDate && groupings.length > 0) {
      return `${reportType}_${version}_${formatDocumentDateFormat(
        startDate
      )}_to_${formatDocumentDateFormat(endDate)}_By${groupings.toString()}`;
    }

    if (groupings.length > 0 && !groupings.includes('transactionId')) {
      return `${reportType}_${version}_By${groupings.toString()}`;
    }
    /*By default empty groupings would be grouped up by transaction*/
    if (startDate && endDate && version) {
      return `${reportType}_${version}_${formatDocumentDateFormat(
        startDate
      )}_to_${formatDocumentDateFormat(endDate)}_ByTransactions`;
    }
    if ((startDate || endDate) && version) {
      return `${reportType}_${version}_${formatDocumentDateFormat(
        startDate
      )}${formatDocumentDateFormat(endDate)}_ByTransactions`;
    }
    if (startDate && endDate) {
      return `${reportType}_${formatDocumentDateFormat(
        startDate
      )}_to_${formatDocumentDateFormat(endDate)}_ByTransactions`;
    }
    if (startDate || endDate) {
      return `${reportType}_${formatDocumentDateFormat(
        startDate
      )}${formatDocumentDateFormat(endDate)}_ByTransactions`;
    }
    return `${reportType}_${version}_ByTransactions`;
    /*------------------------------------------------------------- */
  };

  //This is a function to remove headers that are empty and only return that is filled
  function cleanObject(obj: any) {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ''
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  const handleXLSXDownload = () => {
    const dataToExport =
      version === 'summary' && groupedTransactions.length > 0
        ? groupedTransactions.map((groupedTransaction) =>
            cleanObject(groupedTransaction)
          )
        : version === 'detailed' && groupings.includes('booking')
        ? bookingSales
        : transactions.map((transaction) => ({
            ...transaction,
            // items: JSON.stringify(transaction.items),
          }));

    const companyNameHeader = `Company Name: ${currentLot.lotDescription}`;
    const reportNameHeader = `Report Name: ${convertToReadableFormat(
      reportType
    )} ${convertToReadableFormat(version)}`;
    const outletHeader = `Outlet: ${currentLot.lotNumber}`;
    const monthHeader = `Month: ${currentMonthName}`;
    const startAndEndDate = `Report Date from: ${format(
      startDate,
      'dd MMMM yyyy'
    )}        to: ${format(endDate, 'dd MMMM yyyy')}`;
    const dateGenerated = `Date Generated: ${format(
      new Date(),
      'dd MMMM yyyy hh:mm'
    )}`;

    let Heading = [
      [companyNameHeader],
      [reportNameHeader],
      [outletHeader],
      [monthHeader],
      [startAndEndDate],
      [dateGenerated],
    ];

    // const ws = XLSX.utils.json_to_sheet(dataToExport);

    const ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, Heading);
    XLSX.utils.sheet_add_json(ws, dataToExport, {
      origin: 'A8',
    });

    const borderStyle = {
      border: {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      },
    };

    ws['!merges'] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: 7 } },
      { s: { r: 2, c: 0 }, e: { r: 2, c: 7 } },
      { s: { r: 3, c: 0 }, e: { r: 3, c: 7 } },
      { s: { r: 4, c: 0 }, e: { r: 4, c: 7 } },
    ];

    Object.keys(ws).forEach((cell) => {
      if (cell !== '!ref') {
        ws[cell].s = borderStyle;
      }
    });

    ws['A1'].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws['A2'].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws['A3'].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws['A4'].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws['A5'].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };
    ws['A6'].s = {
      font: {
        sz: 18,
        bold: true,
      },
    };

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    XLSX.writeFile(wb, `${fileNaming()}.xlsx`);
  };

  const handleDownloadPDF = () => {
    let ref = transactionDetailedDataRef.current;

    if (groupedTransactions.length > 0) {
      ref = summaryDataRef.current;
    } else if (bookingSales.length > 0) {
      ref = bookingDetailedDataRef.current;
    }
    html2canvas(ref)
      .then((canvas) => {
        const contentWidth = canvas.width * 0.8;
        const contentHeight = canvas.height * 0.8;
        const pdf = new jsPDF(contentWidth > contentHeight ? 'l' : 'p', 'mm', [
          contentWidth,
          contentHeight,
        ]);
        pdf.addImage(
          canvas.toDataURL('image/png'),
          'PNG',
          0,
          0,
          contentWidth,
          contentHeight
        ); // Adjust the size as needed
        pdf.save(`${fileNaming()}.pdf`);
      })
      .catch((error) => {
        console.error('Error converting to canvas:', error);
      });
  };

  //File Download Menu Stuffs

  const [fileDownloadAnchorEl, setFileDownloadAnchorEl] =
    useState<null | HTMLElement>(null);
  const openFileDownloadMenu = Boolean(fileDownloadAnchorEl);
  const handleFileDownloadClick = (event: React.MouseEvent<HTMLElement>) => {
    setFileDownloadAnchorEl(event.currentTarget);
  };
  const handleFileDownloadClose = () => {
    setFileDownloadAnchorEl(null);
  };

  return (
    <Box
      display={'flex'}
      bgcolor={theme.palette.background.default}
      maxWidth={'90vw'}
      border={2}
      borderColor={theme.palette.primary.main}
    >
      <Stack direction={'row'} width={'100%'}>
        <Stack
          direction={'column'}
          width={'18%'}
          borderRight={2}
          borderColor={theme.palette.primary.main}
        >
          <Box
            display={'flex'}
            bgcolor={theme.palette.background.default}
            borderBottom={2}
            borderColor={theme.palette.primary.main}
            height={'100px'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography variant="h2">Overview</Typography>
          </Box>

          <Stack height={'100%'} spacing={1}>
            {paymentOverview.map((overview) => (
              <Box display={'flex'} flexDirection={'column'} padding={1}>
                <Box>
                  <Typography fontWeight={600}>
                    {overview.left.name} | {overview.right.name}
                  </Typography>
                  <Box
                    width={'100%'}
                    height={'30px'}
                    border={'2px solid white'}
                    display={'flex'}
                    marginTop={1}
                  >
                    <Box
                      width={'50%'}
                      display={'flex'}
                      alignItems={'center'}
                      sx={{
                        backgroundColor: 'white',
                      }}
                    >
                      <Typography
                        color={'black'}
                        width={'100%'}
                        display={'flex'}
                        justifyContent={'center'}
                        fontWeight={600}
                      >
                        {overview.left.value}
                      </Typography>
                    </Box>
                    <Box
                      width={'50%'}
                      display={'flex'}
                      alignItems={'center'}
                      sx={{
                        backgroundColor: 'black',
                      }}
                    >
                      <Typography
                        color={'white'}
                        width={'100%'}
                        display={'flex'}
                        justifyContent={'center'}
                        fontWeight={600}
                      >
                        {overview.right.value}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Stack>
        </Stack>
        <Stack direction={'column'} width={'82%'}>
          <Accordion>
            <AccordionSummary>
              <Box
                display={'flex'}
                width={'100%'}
                height={'100px'}
                justifyContent={'space-between'}
                paddingX={2}
                alignItems={'center'}
                bgcolor={theme.palette.background.default}
                borderBottom={2}
                borderColor={theme.palette.primary.main}
              >
                <Stack direction={'row'} alignItems={'center'}>
                  <FilterAlt sx={{ fontSize: '32px', color: 'white' }} />
                  <Typography variant="h3">Filter</Typography>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      fetchTransactions();
                      fetchOverviewTransactions();
                    }}
                    sx={{ borderRadius: 1 }}
                  >
                    <Search />
                  </Button>
                  <Button
                    id="file-download-button"
                    aria-controls={
                      openFileDownloadMenu ? 'file-download-menu' : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openFileDownloadMenu ? 'true' : undefined}
                    variant="contained"
                    onClick={handleFileDownloadClick}
                    sx={{ borderRadius: 1 }}
                  >
                    <FileDownload />
                  </Button>
                  <StyledMenu
                    id="file-download-menu"
                    MenuListProps={{
                      'aria-labelledby': 'file-download-button',
                    }}
                    anchorEl={fileDownloadAnchorEl}
                    open={openFileDownloadMenu}
                    onClose={handleFileDownloadClose}
                  >
                    <MenuItem onClick={handleDownloadPDF} disableRipple>
                      <PDFIcon
                        width={'35px'}
                        height={'35px'}
                        style={{ marginRight: '10px' }}
                      />
                      PDF
                    </MenuItem>
                    <MenuItem onClick={handleXLSXDownload} disableRipple>
                      <ExcelIcon
                        width={'35px'}
                        height={'35px'}
                        style={{ marginRight: '10px' }}
                      />
                      Xlsx
                    </MenuItem>
                    <CSVLink
                      data={
                        version === 'summary' && groupedTransactions.length > 0
                          ? groupedTransactions.map((groupedTransaction) =>
                              cleanObject(groupedTransaction)
                            )
                          : version === 'detailed' &&
                            groupings.includes('booking')
                          ? bookingSales
                          : transactions.map((transaction) => ({
                              ...transaction,
                              // items: JSON.stringify(transaction.items),
                            }))
                      }
                      filename={`${fileNaming()}.csv`}
                    >
                      <MenuItem onClick={handleFileDownloadClose} disableRipple>
                        <ExcelIcon
                          width={'35px'}
                          height={'35px'}
                          style={{ marginRight: '10px' }}
                        />
                        CSV
                      </MenuItem>
                    </CSVLink>
                  </StyledMenu>
                </Stack>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box display={'flex'} flexDirection={'column'}>
                <TransactionFilter
                  anchorEl={anchorEl}
                  reportType={reportType}
                  version={version}
                  groupings={groupings}
                  groupingsList={groupingsList}
                  bookingStatusFilter={bookingStatusFilter}
                  bookingSourceFilter={bookingSourceFilter}
                  paymentTypeFilter={paymentTypeFilter}
                  setGroupingsList={setGroupingsList}
                  handleChangeReportType={handleChangeReportType}
                  handleChangeVersion={handleChangeVersion}
                  handleCloseDropdown={handleCloseDropdown}
                  handleGroupingsChange={handleGroupingsChange}
                  handleOpenDropdown={handleOpenDropdown}
                  handleChangeBookingStatusFilter={
                    handleChangeBookingStatusFilter
                  }
                  handleChangeBookingSourceFilter={
                    handleChangeBookingSourceFilter
                  }
                  handleChangePaymentTypeFilter={handleChangePaymentTypeFilter}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Box display={'flex'} alignItems={'center'}>
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={(e) => handleStartDateChange(e as Date)}
                      format="dd MMM yyyy"
                      slots={{
                        openPickerIcon: ArrowDropDownIcon,
                      }}
                      slotProps={{
                        layout: {
                          sx: {
                            '.MuiDateCalendar-root': {
                              color: theme.palette.primary.main,
                              borderRadius: 2,
                              borderWidth: 1,
                              borderColor: theme.palette.primary.main,
                              border: '1px solid',
                              backgroundColor: theme.palette.background.paper,
                            },
                            '.MuiButtonBase-root': {
                              color: theme.palette.primary.main,
                            },
                            '.MuiTypography-root': {
                              color: theme.palette.primary.main,
                            },
                            '.MuiPickersCalendarHeader-root': {
                              color: theme.palette.primary.main,
                            },
                            '.MuiPickersMonth-monthButton': {
                              color: theme.palette.primary.main,
                            },
                            '.MuiPickersCalendarHeader-label': {
                              color: theme.palette.primary.main,
                            },
                            '.Mui-selected': {
                              color: 'black',
                            },
                            '.MuiPickersYear-yearButton': {
                              color: theme.palette.primary.main,
                            },
                          },
                        },
                        textField: {
                          variant: 'filled',
                          inputProps: {
                            style: {
                              color: 'white',
                              borderRadius: 1,
                              border: 2,
                            },
                          },
                          sx: {
                            width: '150px',
                            backgroundColor: '#232323',
                            border: '1px solid #148978',
                            borderRadius: 1,
                          },
                        },
                        openPickerButton: {
                          sx: {
                            color: 'white',
                            marginTop: '1.8px',
                            zIndex: 1,
                          },
                        },
                        openPickerIcon: {
                          sx: {
                            width: '1em',
                            height: '1em',
                            zIndex: 1,
                          },
                        },
                      }}
                    />
                    <Typography variant="h3" marginX={1}>
                      -
                    </Typography>
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      onChange={(e) => handleEndDateChange(e as Date)}
                      format="dd MMM yyyy"
                      slots={{
                        openPickerIcon: ArrowDropDownIcon,
                      }}
                      slotProps={{
                        layout: {
                          sx: {
                            '.MuiDateCalendar-root': {
                              color: theme.palette.primary.main,
                              borderRadius: 2,
                              borderWidth: 1,
                              borderColor: theme.palette.primary.main,
                              border: '1px solid',
                              backgroundColor: theme.palette.background.paper,
                            },
                            '.MuiButtonBase-root': {
                              color: theme.palette.primary.main,
                            },
                            '.MuiTypography-root': {
                              color: theme.palette.primary.main,
                            },
                            '.MuiPickersCalendarHeader-root': {
                              color: theme.palette.primary.main,
                            },
                            '.MuiPickersMonth-monthButton': {
                              color: theme.palette.primary.main,
                            },
                            '.MuiPickersCalendarHeader-label': {
                              color: theme.palette.primary.main,
                            },
                            '.Mui-selected': {
                              color: 'black',
                            },
                            '.MuiPickersYear-yearButton': {
                              color: theme.palette.primary.main,
                            },
                          },
                        },
                        textField: {
                          variant: 'filled',
                          inputProps: {
                            style: {
                              color: 'white',
                              borderRadius: 1,
                              border: 2,
                            },
                          },
                          sx: {
                            width: '150px',
                            backgroundColor: '#232323',
                            border: '1px solid #148978',
                            borderRadius: 1,
                          },
                        },
                        openPickerButton: {
                          sx: {
                            color: 'white',
                            marginTop: '1.8px',
                            zIndex: 1,
                          },
                        },
                        openPickerIcon: {
                          sx: {
                            width: '1em',
                            height: '1em',
                            zIndex: 1,
                          },
                        },
                      }}
                    />
                  </Box>
                </LocalizationProvider>
              </Box>
            </AccordionDetails>
          </Accordion>
          {isLoading ? (
            <Box
              display={'flex'}
              height={'500px'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box height={'100%'} width={'100%'}>
              {groupedTransactions.length > 0 ? (
                <>
                  <CustomTable
                    key={'summaryTables'}
                    isLoading={isLoading}
                    columns={groupedColumns}
                    data={groupedTransactions}
                    handlePaginationChange={setPagination}
                    pagination={pagination}
                    enableTopToolbar={false}
                    columnVisibility={{
                      bookingNo: displayGrouping.includes('booking'),
                      paymentTypeName: displayGrouping.includes('payment-type'),
                      bookingPlatform:
                        displayGrouping.includes('booking-platform'),
                      duration: displayGrouping.includes('booking-duration'),
                      roomType: displayGrouping.includes('room-type'),
                    }}
                    oddEvenBackground={true}
                  />
                  <div
                    ref={summaryDataRef}
                    style={{
                      position: 'absolute',
                      left: '-9999px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box display={'flex'} flexDirection={'column'} padding={2}>
                      <Typography variant="h4" color={'black'}>
                        Company Name: {currentLot.lotDescription}
                      </Typography>
                      <Typography variant="h4" color={'black'}>
                        Report Name: {convertToReadableFormat(reportType)}{' '}
                        {convertToReadableFormat(version)}
                      </Typography>
                      <Typography variant="h4" color={'black'}>
                        Outlet: {currentLot.lotNumber}
                      </Typography>
                      <Typography variant="h4" color={'black'}>
                        Month: {currentMonthName}
                      </Typography>
                      <Typography variant="h4" color={'black'}>
                        Date Generated:{' '}
                        {format(new Date(), 'dd MMMM yyyy hh:mm')}
                      </Typography>
                    </Box>
                    <CustomTable
                      key={'summaryPrintTable'}
                      isLoading={isLoading}
                      data={groupedTransactions}
                      columns={groupedColumns}
                      enableTopToolbar={false}
                      rowCount={groupedTransactions.length}
                      pagination={{
                        pageIndex: 0,
                        pageSize: groupedTransactions.length,
                      }}
                      oddEvenBackground={true}
                      columnVisibility={{
                        bookingNo: displayGrouping.includes('booking'),
                        paymentTypeName:
                          displayGrouping.includes('payment-type'),
                        bookingPlatform:
                          displayGrouping.includes('booking-platform'),
                        duration: displayGrouping.includes('booking-duration'),
                        roomType: displayGrouping.includes('room-type'),
                      }}
                    />
                  </div>
                </>
              ) : bookingSales.length > 0 ? (
                <>
                  <CustomTable
                    key={'bookingSales'}
                    isLoading={isLoading}
                    columns={bookingSalesColumns}
                    data={bookingSales}
                    handlePaginationChange={setPagination}
                    pagination={pagination}
                    enableTopToolbar={false}
                    oddEvenBackground={true}
                  />
                  <div
                    ref={bookingDetailedDataRef}
                    style={{
                      position: 'absolute',
                      left: '-9999px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box display={'flex'} flexDirection={'column'} padding={2}>
                      <Typography variant="h4" color={'black'}>
                        Company Name: {currentLot.lotDescription}
                      </Typography>
                      <Typography variant="h4" color={'black'}>
                        Report Name: {convertToReadableFormat(reportType)}{' '}
                        {convertToReadableFormat(version)}
                      </Typography>
                      <Typography variant="h4" color={'black'}>
                        Outlet: {currentLot.lotNumber}
                      </Typography>
                      <Typography variant="h4" color={'black'}>
                        Month: {currentMonthName}
                      </Typography>
                      <Typography variant="h4" color={'black'}>
                        Date Generated:{' '}
                        {format(new Date(), 'dd MMMM yyyy hh:mm')}
                      </Typography>
                    </Box>
                    <CustomTable
                      key={'bookingPrintTable'}
                      isLoading={isLoading}
                      data={bookingSales}
                      columns={bookingSalesColumns}
                      enableTopToolbar={false}
                      rowCount={bookingSales.length}
                      pagination={{
                        pageIndex: 0,
                        pageSize: bookingSales.length,
                      }}
                      oddEvenBackground={true}
                    />
                  </div>
                </>
              ) : (
                <>
                  <CustomTable
                    key={'transactionSales'}
                    isLoading={isLoading}
                    columns={transactionSalesColumns}
                    data={transactions}
                    handlePaginationChange={setPagination}
                    pagination={pagination}
                    enableTopToolbar={false}
                    oddEvenBackground={true}
                  />
                  <div
                    ref={transactionDetailedDataRef}
                    style={{
                      position: 'absolute',
                      left: '-9999px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box display={'flex'} flexDirection={'column'} padding={2}>
                      <Typography variant="h4" color={'black'}>
                        Company Name: {currentLot.lotDescription}
                      </Typography>
                      <Typography variant="h4" color={'black'}>
                        Report Name: {convertToReadableFormat(reportType)}{' '}
                        {convertToReadableFormat(version)}
                      </Typography>
                      <Typography variant="h4" color={'black'}>
                        Outlet: {currentLot.lotNumber}
                      </Typography>
                      <Typography variant="h4" color={'black'}>
                        Month: {currentMonthName}
                      </Typography>
                      <Typography variant="h4" color={'black'}>
                        Date Generated:{' '}
                        {format(new Date(), 'dd MMMM yyyy hh:mm')}
                      </Typography>
                    </Box>
                    <CustomTable
                      key={'transactionPrintTable'}
                      isLoading={isLoading}
                      data={transactions}
                      columns={transactionSalesColumns}
                      enableTopToolbar={false}
                      rowCount={transactions.length}
                      pagination={{
                        pageIndex: 0,
                        pageSize: transactions.length,
                      }}
                      oddEvenBackground={true}
                    />
                  </div>
                </>
              )}
            </Box>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default TransactionListPage;

const TransactionFilter = (props: {
  handleOpenDropdown: ({
    currentTarget,
  }: React.MouseEvent<HTMLElement>) => void;
  handleCloseDropdown: VoidFunction;
  anchorEl: HTMLElement | null;
  reportType: string;
  handleChangeReportType: (
    reportType: 'sales' | 'collections' | 'sales_and_collections'
  ) => void;
  version: string;
  handleChangeVersion: (version: 'summary' | 'detailed') => void;
  handleGroupingsChange: (groupingsToAdd: string, isChecked: boolean) => void;
  groupingsList: {
    allDetailed: boolean;
    transactionId: boolean;
    bookingId: boolean;
    allSummary: boolean;
    paymentType: boolean;
    bookingPlatform: boolean;
    duration: boolean;
    roomType: boolean;
  };
  groupings: string[];
  setGroupingsList: React.Dispatch<
    React.SetStateAction<{
      allDetailed: boolean;
      transactionId: boolean;
      bookingId: boolean;
      allSummary: boolean;
      paymentType: boolean;
      bookingPlatform: boolean;
      duration: boolean;
      roomType: boolean;
    }>
  >;
  bookingStatusFilter: string[];
  handleChangeBookingStatusFilter: (event: SelectChangeEvent<string[]>) => void;
  bookingSourceFilter: string[];
  handleChangeBookingSourceFilter: (event: SelectChangeEvent<string[]>) => void;
  paymentTypeFilter: string[];
  handleChangePaymentTypeFilter: (event: SelectChangeEvent<string[]>) => void;
}) => {
  const token = useSelector((state: RootState) => state.user.accessToken);

  const [allPlatforms, setAllPlatforms] = useState<IPlatform[]>([]);
  const [paymentTypes, setPaymentTypes] = useState<IPaymentType[]>([]);
  const fetchAllAvailablePlatform = () => {
    axiosInstance
      .get('/bookings/platforms/get-all-platforms', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          setAllPlatforms(parsePlatformData(res.data.datas));
        }
      })
      .catch((e) => {
        Notification.failed('something wrong, please try again');
      });
  };

  const fetchPaymentMethod = () => {
    axiosInstance
      .get('/payment/type', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 200) {
          setPaymentTypes(res.data);
          return;
        }
        Notification.failed(res.data.msg);
      })
      .catch((e) => {
        Notification.failed(e.response.data.msg);
      });
  };

  useEffect(() => {
    fetchAllAvailablePlatform();
    fetchPaymentMethod();
  }, []);
  return (
    <Stack direction={'row'} width={'100%'} spacing={2} marginBottom={2}>
      <FormControl>
        <InputLabel variant="filled">Report Type</InputLabel>
        <Select
          variant="filled"
          value={'Report Type'}
          renderValue={(value) =>
            props.reportType ? camelToNormal(props.reportType) : value
          }
          SelectDisplayProps={{
            onClick: props.handleOpenDropdown,
          }}
          MenuProps={{
            keepMounted: true,
            sx: {
              '.MuiMenu-list': {
                color: 'black',
              },
            },
          }}
          sx={{
            width: '150px',
            '.MuiSelect-select': {
              border: '1px solid #148978',
            },
            '.MuiSelect-icon': {
              fill: 'white',
            },
          }}
        >
          <MenuItem
            value={'Report Type'}
            disabled
            sx={{
              display: 'none',
            }}
          >
            Report Type
          </MenuItem>
          <MenuItem>
            <RadioGroup
              name={'report-type'}
              value={props.reportType}
              onChange={({ target: { value } }) =>
                props.handleChangeReportType(value as 'sales' | 'collections')
              }
            >
              <FormControlLabel
                value="sales"
                control={<Radio />}
                label="Sales"
              />
              <FormControlLabel
                value="collections"
                control={<Radio />}
                label="Collections"
              />
              <FormControlLabel
                value="sales_and_collections"
                control={<Radio />}
                label="Sales and Collection"
              />
            </RadioGroup>
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel variant="filled">Versions</InputLabel>
        <Select
          variant="filled"
          value={'Versions'}
          renderValue={(value) =>
            props.version ? camelToNormal(props.version) : value
          }
          SelectDisplayProps={{
            onClick: props.handleOpenDropdown,
          }}
          MenuProps={{
            keepMounted: true,
            sx: {
              '.MuiMenu-list': {
                color: 'black',
              },
            },
          }}
          sx={{
            width: '150px',
            '.MuiSelect-select': {
              border: '1px solid #148978',
            },
            '.MuiSelect-icon': {
              fill: 'white',
            },
          }}
        >
          <MenuItem
            value={'Versions'}
            disabled
            sx={{
              display: 'none',
            }}
          >
            Versions
          </MenuItem>
          <MenuItem>
            <RadioGroup
              name={'versions'}
              value={props.version}
              onChange={({ target: { value } }) =>
                props.handleChangeVersion(value as 'detailed' | 'summary')
              }
            >
              <FormControlLabel
                value="detailed"
                control={<Radio />}
                label="Detailed"
              />
              <FormControlLabel
                value="summary"
                control={<Radio />}
                label="Summary"
              />
            </RadioGroup>
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel variant="filled">Groupings</InputLabel>
        <Select
          variant="filled"
          id={'status-select-id'}
          value={props.groupings}
          multiple
          renderValue={() => {
            return props.groupings
              .map((grouping) => camelToNormal(grouping))
              .join(',');
          }}
          sx={{
            width: '200px',
            '.MuiSelect-select': {
              border: '1px solid #148978',
            },
            '.MuiSelect-icon': {
              fill: 'white',
            },
          }}
        >
          {props.version === 'detailed' ? (
            <Box>
              <MenuItem value={'transactionId'}>
                <FormControlLabel
                  label="Transaction ID"
                  control={
                    <Checkbox
                      checked={props.groupingsList.transactionId}
                      onChange={({ target: { checked } }) => {
                        props.setGroupingsList({
                          allDetailed: false,
                          transactionId: checked,
                          bookingId: false,
                          allSummary: false,
                          paymentType: false,
                          bookingPlatform: false,
                          duration: false,
                          roomType: false,
                        });
                        props.handleGroupingsChange('transactionId', checked);
                      }}
                    />
                  }
                />
              </MenuItem>
              <MenuItem value={'bookingId'}>
                <FormControlLabel
                  label="Booking ID"
                  control={
                    <Checkbox
                      checked={props.groupingsList.bookingId}
                      onChange={({ target: { checked } }) => {
                        props.setGroupingsList({
                          allDetailed: false,
                          bookingId: checked,
                          transactionId: false,
                          allSummary: false,
                          paymentType: false,
                          bookingPlatform: false,
                          duration: false,
                          roomType: false,
                        });
                        props.handleGroupingsChange('booking', checked);
                      }}
                    />
                  }
                />
              </MenuItem>
            </Box>
          ) : props.version === 'summary' ? (
            <Box>
              <MenuItem value={'allSummary'}>
                <FormControlLabel
                  label="All Summary"
                  control={
                    <Checkbox
                      checked={props.groupingsList.allSummary}
                      onChange={({ target: { checked } }) => {
                        props.setGroupingsList({
                          allDetailed: false,
                          bookingId: checked,
                          transactionId: false,
                          allSummary: checked,
                          paymentType: checked,
                          bookingPlatform: checked,
                          duration: checked,
                          roomType: checked,
                        });
                        props.handleGroupingsChange('allSummary', checked);
                      }}
                    />
                  }
                />
              </MenuItem>
              <MenuItem value={'bookingId'}>
                <FormControlLabel
                  label="Booking ID"
                  control={
                    <Checkbox
                      checked={props.groupingsList.bookingId}
                      onChange={({ target: { checked } }) => {
                        props.setGroupingsList({
                          ...props.groupingsList,
                          allDetailed: false,
                          transactionId: false,
                          allSummary: checked && props.groupingsList.bookingId,
                          bookingId: checked,
                        });
                        props.handleGroupingsChange('booking', checked);
                      }}
                    />
                  }
                />
              </MenuItem>
              <MenuItem value={'paymentType'}>
                <FormControlLabel
                  label="Payment Type"
                  control={
                    <Checkbox
                      checked={props.groupingsList.paymentType}
                      onChange={({ target: { checked } }) => {
                        props.setGroupingsList({
                          ...props.groupingsList,
                          allDetailed: false,
                          transactionId: false,
                          allSummary:
                            checked && props.groupingsList.paymentType,
                          paymentType: checked,
                        });
                        props.handleGroupingsChange('payment-type', checked);
                      }}
                    />
                  }
                />
              </MenuItem>
              <MenuItem value={'bookingPlatform'}>
                <FormControlLabel
                  label="Booking Platform"
                  control={
                    <Checkbox
                      checked={props.groupingsList.bookingPlatform}
                      onChange={({ target: { checked } }) => {
                        props.setGroupingsList({
                          ...props.groupingsList,
                          allDetailed: false,
                          transactionId: false,
                          allSummary:
                            checked && props.groupingsList.bookingPlatform,
                          bookingPlatform: checked,
                        });
                        props.handleGroupingsChange(
                          'booking-platform',
                          checked
                        );
                      }}
                    />
                  }
                />
              </MenuItem>
              <MenuItem value={'duration'}>
                <FormControlLabel
                  label="Duration"
                  control={
                    <Checkbox
                      checked={props.groupingsList.duration}
                      onChange={({ target: { checked } }) => {
                        props.setGroupingsList({
                          ...props.groupingsList,
                          allDetailed: false,
                          transactionId: false,
                          allSummary: checked && props.groupingsList.duration,
                          duration: checked,
                        });
                        props.handleGroupingsChange(
                          'booking-duration',
                          checked
                        );
                      }}
                    />
                  }
                />
              </MenuItem>
              <MenuItem value={'roomType'}>
                <FormControlLabel
                  label="Room Type"
                  control={
                    <Checkbox
                      checked={props.groupingsList.roomType}
                      onChange={({ target: { checked } }) => {
                        props.setGroupingsList({
                          ...props.groupingsList,
                          allDetailed: false,
                          transactionId: false,
                          allSummary: checked && props.groupingsList.roomType,
                          roomType: checked,
                        });
                        props.handleGroupingsChange('room-type', checked);
                      }}
                    />
                  }
                />
              </MenuItem>
            </Box>
          ) : (
            <MenuItem value={'roomType'} disabled>
              Please Select a Version
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel variant="filled">Booking Status</InputLabel>
        <Select
          variant="filled"
          id={'booking-status-select-id'}
          multiple
          value={props.bookingStatusFilter}
          onChange={props.handleChangeBookingStatusFilter}
          renderValue={(selected) => selected.join(',')}
          sx={{
            width: '150px',
            '.MuiSelect-select': {
              border: '1px solid #148978',
            },
            '.MuiSelect-icon': {
              fill: 'white',
            },
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300,
              },
            },
          }}
        >
          {bookingStatuses.map((status, index) => (
            <MenuItem key={index} value={status}>
              <Checkbox
                checked={props.bookingStatusFilter.indexOf(status) > -1}
              />
              <ListItemText primary={status} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel variant="filled">Booking Source</InputLabel>
        <Select
          variant="filled"
          id={'booking-source-select-id'}
          multiple
          value={props.bookingSourceFilter}
          onChange={props.handleChangeBookingSourceFilter}
          renderValue={(selected) => selected.join(',')}
          sx={{
            width: '150px',
            '.MuiSelect-select': {
              border: '1px solid #148978',
            },
            '.MuiSelect-icon': {
              fill: 'white',
            },
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 400,
              },
            },
          }}
        >
          {allPlatforms.map((platform, index) => (
            <MenuItem key={index} value={platform.platform}>
              <Checkbox
                checked={
                  props.bookingSourceFilter.indexOf(platform.platform) > -1
                }
              />
              <ListItemText primary={platform.platform} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel variant="filled">Payment Type</InputLabel>
        <Select
          variant="filled"
          id={'payment-type-select-id'}
          multiple
          value={props.paymentTypeFilter}
          onChange={props.handleChangePaymentTypeFilter}
          renderValue={(selected) => selected.join(',')}
          sx={{
            width: '150px',
            '.MuiSelect-select': {
              border: '1px solid #148978',
            },
            '.MuiSelect-icon': {
              fill: 'white',
            },
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 400,
              },
            },
          }}
        >
          {paymentTypes.map((paymentType, index) => (
            <MenuItem key={index} value={paymentType.paymentType}>
              <Checkbox
                checked={
                  props.paymentTypeFilter.indexOf(paymentType.paymentType) > -1
                }
              />
              <ListItemText primary={paymentType.paymentType} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

const parsePlatformData = (fetchedPlatform: any[]): IPlatform[] => {
  if (fetchedPlatform) {
    return fetchedPlatform.map((item: any) => ({
      platformId: item.platform_id.toString(),
      platform: item.platform,
    }));
  } else {
    return [];
  }
};

function formatNumber(number: string) {
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}
